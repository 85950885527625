import styled from 'styled-components';

import type { HtmlDescription as HtmlDescriptionType } from '@xing-com/crate-common-graphql-types';

import { ShowMore } from '../../show-more';
import { DESCRIPTION_PREVIEW_HEIGHT } from '../constants';

type Props = {
  description: HtmlDescriptionType;
  skipShowMore: boolean;
};

const DescriptionContainer = styled.div`
  line-height: normal;
  word-break: break-word;
`;

export const HtmlDescription: React.FC<Props> = ({
  description,
  skipShowMore,
}) => {
  return skipShowMore ? (
    <DescriptionContainer
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      dangerouslySetInnerHTML={{ __html: description.content as TrustedHTML }}
    />
  ) : (
    <ShowMore
      previewHeight={DESCRIPTION_PREVIEW_HEIGHT}
      expandLabelKey="JOBS_JOB_DETAILS_DESCRIPTION_SHOW_MORE"
      collapseLabelKey="JOBS_JOB_DETAILS_DESCRIPTION_SHOW_LESS"
      hideOnDesktop
      centered
    >
      <DescriptionContainer
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        dangerouslySetInnerHTML={{ __html: description.content as TrustedHTML }}
      />
    </ShowMore>
  );
};
