import { useNavigate } from '@reach/router';
import { useCallback, useMemo } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { useRedirectToLoginWithJobDestUrl } from './use-redirect-to-login-with-job-dest-url';
import { useTrackApplyIntention } from './use-track-apply-intention';

type Props = { contactUserId?: string; jobId: string };

export const useApplyButtonMessageApplyProps = ({
  contactUserId,
  jobId,
}: Props): { onClick: () => void } => {
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();
  const trackApplyIntention = useTrackApplyIntention({
    applicationType: 'MessageApplication',
    jobId,
  });
  const redirectToLogin = useRedirectToLoginWithJobDestUrl();

  const onClick = useCallback(() => {
    trackApplyIntention();
    if (isLoggedIn) {
      navigate(
        `/chats/new/${contactUserId}?contextId=jobs_apply&entryPoint=jobs_job_apply_message`
      );
    } else {
      redirectToLogin();
    }
  }, [
    contactUserId,
    isLoggedIn,
    navigate,
    redirectToLogin,
    trackApplyIntention,
  ]);

  const messageProps = useMemo(
    () => ({
      to: undefined,
      onClick,
    }),
    [onClick]
  );

  return messageProps;
};
