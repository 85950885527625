import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { useWindowSize } from '@xing-com/crate-jobs-hooks';

import { Container, Header, ProfileImage, Title } from './sticky-header.styles';
import { StickyMainActions } from './sticky-main-actions';

type Props = Required<PropsWithChildren> & {
  show: boolean;
  job: JobDetails;
  headerRef?: HTMLElement | null;
};

export const StickyHeader: React.FC<Props> = ({
  children,
  job,
  show,
  headerRef,
}) => {
  const { isLoggedIn } = useLoginState();
  const windowSize = useWindowSize();
  const [stickyHeaderWidth, setStickyHeaderWidth] = useState(0);
  const { application, bookmarkState, company, title } = job;

  useEffect(() => {
    setStickyHeaderWidth((headerRef?.offsetWidth ?? 0) + 16); // +16 is to have an extra space so the bottom border exceeds the content
  }, [headerRef, windowSize.width]);

  return (
    <Container
      $isSticky={show}
      $isLoggedIn={isLoggedIn}
      $width={stickyHeaderWidth}
      data-testid="job-details-sticky-header"
    >
      {children}
      <Header>
        <ProfileImage
          type="company"
          size="small"
          sizeConfined="medium"
          src={company?.logo}
        />
        <Title noMargin size="large">
          {title}
        </Title>
        <StickyMainActions
          application={application}
          job={job}
          bookmarkState={bookmarkState}
        />
      </Header>
    </Container>
  );
};
