import styled from 'styled-components';

import {
  TOP_BAR_HEIGHT_DESKTOP,
  TOP_BAR_HEIGHT_DESKTOP_LO,
  TOP_BAR_HEIGHT_MOBILE,
} from '@xing-com/crate-jobs-constants';
import {
  zIndexSticky,
  mediaConfined,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { ProfileImage as ProfileImageComponent } from '@xing-com/profile-image';
import {
  xdlColorBackground,
  xdlColorBorderSoft,
  spaceL,
  spaceXL,
  spaceS,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

type HeaderStyleProps = {
  $isSticky: boolean;
  $isLoggedIn: boolean;
  $width: number;
};

export const Container = styled.div<HeaderStyleProps>`
  background-color: ${xdlColorBackground};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  display: ${({ $isSticky }) => ($isSticky ? 'flex' : 'none')};
  flex-direction: column;
  left: 0;
  right: 0;
  position: fixed;
  top: ${TOP_BAR_HEIGHT_MOBILE}px;
  z-index: ${zIndexSticky};

  @media ${mediaConfined} {
    left: unset;
    margin: 0 auto 0 -${spaceS};
    padding: 0 ${spaceS} ${spaceXL} ${spaceS};
    right: unset;
    width: ${({ $width }) => `${$width}px`};
  }

  @media ${mediaWideNavless} {
    padding-top: ${spaceL};
    top: ${({ $isLoggedIn }) =>
      $isLoggedIn
        ? `${TOP_BAR_HEIGHT_DESKTOP}`
        : `${TOP_BAR_HEIGHT_DESKTOP_LO}`}px;
  }

  @media print {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${spaceL};

  @media ${mediaConfined} {
    align-items: center;
    gap: ${spaceL};
    padding: 0;
  }

  @media ${mediaWideNavless} {
    align-items: center;
    gap: ${spaceL};
  }
`;

export const ProfileImage = styled(ProfileImageComponent)`
  display: none;

  @media ${mediaConfined} {
    display: block;
  }
`;

export const Title = styled(Headline)`
  flex: 1;
  display: none;

  @media ${mediaConfined} {
    display: block;
  }
`;
