import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import { useJobBookmarkToggle } from '@xing-com/crate-jobs-hooks';
import { IconBookmark, IconBookmarkFilled } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';

const StyledButton = styled(Button)<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  white-space: nowrap;
  padding: 0;

  @media ${mediaConfined} {
    max-width: 50%;
  }
`;

type Props = {
  bookmarkState?: JobBookmarkState;
  jobId: string;
  iconOnly?: boolean;
  size?: 'small' | 'medium';
  sizeConfined?: 'small' | 'medium';
};

export const BookmarkButton: React.FC<Props> = ({
  bookmarkState,
  jobId,
  iconOnly = false,
  size = 'medium',
  sizeConfined,
}) => {
  const { formatMessage } = useIntl();

  const isBookmarked =
    !!bookmarkState && bookmarkState !== JobBookmarkState.NotBookmarked;

  const onBookmark = useJobBookmarkToggle({
    jobId,
    isBookmarked,
    nwtTrackingOptions: {
      eventSchema: 'basic',
      page: 'jobs/detail',
    },
  });

  const handleOnBookmarkClick = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    onBookmark();
  };

  const buttonCopyId = isBookmarked
    ? 'JOBS_JOB_TEASER_SAVED'
    : 'JOBS_JOB_TEASER_SAVE';

  return (
    <StyledButton
      size={size}
      sizeConfined={sizeConfined}
      variant="secondary"
      data-testid={
        isBookmarked
          ? 'job-details-unbookmark-button'
          : 'job-details-bookmark-button'
      }
      icon={isBookmarked ? IconBookmarkFilled : IconBookmark}
      // @ts-expect-error FIXME: SC6
      onClick={handleOnBookmarkClick}
      $fullWidth={!iconOnly}
      aria-label={formatMessage({ id: buttonCopyId })}
    >
      {!iconOnly ? <FormattedMessage id={buttonCopyId} /> : null}
    </StyledButton>
  );
};
