import type React from 'react';
import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { TrackingLink } from '@xing-com/crate-jobs-domain-job-details-components-tracking-link';
import type { CompanyData } from '@xing-com/crate-jobs-helpers';
import { mediaConfined } from '@xing-com/layout-tokens';
import { ProfileImage } from '@xing-com/profile-image';
import { ProfileInfo } from '@xing-com/profile-info';
import { spaceXL, xdlColorText } from '@xing-com/tokens';
import { BodyCopy, Headline, Meta } from '@xing-com/typography';

import { EmployerActions } from './employer-actions';

type CompanyCardProps = {
  company: CompanyData;
  isAlwaysVertical: boolean;
  className?: string;
};

const StyledCard = styled(Card)`
  padding: ${spaceXL};
`;

export const StyledLink = styled(TrackingLink)`
  color: ${xdlColorText};

  &:hover {
    text-decoration-thickness: from-font;
  }
`;

// Set the alignment via CSS and not via useBreakpoints to work in SSR
const StyledProfileInfo = styled(ProfileInfo)<{
  isAlwaysVertical: boolean;
}>`
  flex-direction: column;

  @media ${mediaConfined} {
    flex-direction: ${({ isAlwaysVertical }) =>
      isAlwaysVertical ? 'column' : 'row'};
  }
`;

const trackingParam = 'sc_o=jobs_header_company_logo_name_click';

export const CompanyCard: React.FC<CompanyCardProps> = ({
  company: {
    id,
    link,
    name,
    logo,
    companySize,
    city,
    country,
    industry,
    isFollowing,
  },
  isAlwaysVertical = false,
  className,
}) => (
  <StyledCard
    className={className}
    data-testid="company-card"
    to={link}
    onClick={(event: React.MouseEvent): void => {
      event.preventDefault();
      window.location.assign(`${link}?${trackingParam}`);
    }}
  >
    <StyledProfileInfo
      noSpacing
      isAlwaysVertical={isAlwaysVertical}
      profileImage={
        <StyledLink link={link} trackingParams={trackingParam}>
          <ProfileImage
            alt="company logo"
            profileName={name}
            size="medium"
            src={logo}
            type="company"
          />
        </StyledLink>
      }
      actions={
        <EmployerActions companyId={id} isFollowing={isFollowing} link={link} />
      }
    >
      <Headline size="large">
        <StyledLink link={link} trackingParams={trackingParam}>
          {name}
        </StyledLink>
      </Headline>
      {industry ? <BodyCopy size="medium">{industry}</BodyCopy> : null}
      {companySize ? <BodyCopy size="medium">{companySize}</BodyCopy> : null}
      <Meta size="small">
        {city}
        {country ? `, ${country}` : null}
      </Meta>
    </StyledProfileInfo>
  </StyledCard>
);
