import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useLocalStorage } from '@xing-com/crate-hooks-use-web-storage';
import {
  LO_APPLICATION_SUBMITTED_STORAGE_PREFIX,
  STORAGE_JOBS_NAMESPACE,
} from '@xing-com/crate-jobs-constants';
import type { ApplicationData } from '@xing-com/crate-jobs-helpers';

type UseApplicationStatusHelpersResult = {
  appliedAt: string | undefined;
};

export const useApplicationStatusHelpers = (
  liApplication: ApplicationData
): UseApplicationStatusHelpersResult => {
  const { isLoggedIn } = useLoginState();
  const { slug } = liApplication;
  const [loApplication] = useLocalStorage<{ submittedAt: string } | undefined>(
    `${STORAGE_JOBS_NAMESPACE}.${LO_APPLICATION_SUBMITTED_STORAGE_PREFIX}:${slug}`,
    undefined
  );

  return {
    appliedAt: isLoggedIn
      ? liApplication.submittedAt
      : loApplication?.submittedAt,
  };
};
