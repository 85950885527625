import styled from 'styled-components';

import type { EmployerData } from '@xing-com/crate-jobs-helpers';
import { spaceS, spaceXXL, xdlColorBackgroundTertiary } from '@xing-com/tokens';

import { ModuleTitle } from '../module-title';
import { Benefits } from './benefits';
import { Culture, CultureAssessment } from './culture';
import { Kununu } from './kununu';

const ModuleWrapper = styled.div`
  background-color: ${xdlColorBackgroundTertiary};
  border-radius: ${spaceS};
  padding: ${spaceXXL};
`;

type Props = {
  employerData?: EmployerData;
};

export const EmployerModule: React.FC<Props> = ({ employerData }) => {
  if (!employerData) return null;

  const { culture, kununu, benefits } = employerData;

  return kununu ? (
    <>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_KUNUNU_TITLE" size="large" />
      <ModuleWrapper data-testid="employer-review-module">
        <Kununu kununuData={kununu} />
        <Benefits benefits={benefits} />
        {culture ? (
          <Culture culture={culture} ratingCount={kununu.ratingCount} />
        ) : null}
      </ModuleWrapper>
      {culture ? <CultureAssessment /> : null}
    </>
  ) : null;
};
