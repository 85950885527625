import styled from 'styled-components';

import {
  scale080,
  scale100,
  scale110,
  spaceL,
  spaceM,
  spaceXXL,
  xdlColorBorderSoft,
  xdlColorText,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const SectionWrapper = styled.section`
  margin-bottom: ${spaceXXL};
`;

export const StyledHeadline = styled(Headline).attrs({
  size: 'large',
})<{ $customColor?: string }>`
  color: ${({ $customColor }) => $customColor || 'inherit'};

  @media (prefers-color-scheme: dark) {
    color: ${xdlColorText};
  }
`;

export const StyledContent = styled.div`
  // This styles content that is not wrapped in a p tag. It sometimes happens
  font-size: ${scale080};
  line-height: ${scale110};

  // The style has to be repeated here to override the global styles
  p,
  ul,
  ol,
  li {
    font-size: ${scale080};
    line-height: ${scale110};
    margin: 1em 0;
  }

  ul,
  ol {
    padding-left: 1em;
  }

  // Headings to match types in our typography package
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25;
    margin: 0;
    padding: 0;
  }

  h2,
  h3 {
    font-weight: 700;
    font-size: ${scale100};
    margin-bottom: ${spaceL};
    margin-top: ${spaceXXL};
  }

  h4,
  h5,
  h6 {
    font-weight: normal;
    font-size: ${scale080};
    margin-bottom: ${spaceM};
    margin-top: ${spaceM};
  }

  hr {
    margin: 1.5em 0;
    border: 1px ${xdlColorBorderSoft};
    border-top-style: solid;
  }
`;
