import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import type { JobSalary } from '@xing-com/crate-common-graphql-types';
import { formatSalary, type JobFactsData } from '@xing-com/crate-jobs-helpers';
import { IconSalary, IconLocationPin, IconClock } from '@xing-com/icons';
import { SingleSizeIllustrationExtVerified } from '@xing-com/illustrations';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceS, spaceXL } from '@xing-com/tokens';

import { JobFact } from './job-fact';

const Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  gap: ${spaceS};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: ${spaceXL};
  }
`;

type Props = {
  jobFacts?: JobFactsData;
  salary?: JobSalary;
};

export const JobFacts: React.FC<Props> = ({ jobFacts, salary }) => {
  const intl = useIntl();

  if (!jobFacts) return null;

  const { employmentType, location, isVerifiedByXing } = jobFacts;
  return (
    <Container>
      {isVerifiedByXing ? (
        <JobFact
          Icon={SingleSizeIllustrationExtVerified}
          data-testid="job-fact-xing-verified"
        >
          <FormattedMessage id="JOBS_JOB_DETAILS_XING_VERIFIED" />
        </JobFact>
      ) : null}
      {location ? (
        <JobFact Icon={IconLocationPin} data-testid="job-fact-location">
          {location}
        </JobFact>
      ) : null}
      {employmentType ? (
        <JobFact Icon={IconClock} data-testid="job-fact-employment-type">
          {employmentType.name}
        </JobFact>
      ) : null}
      {salary ? (
        <JobFact Icon={IconSalary} data-testid="job-fact-salary">
          {formatSalary(intl, salary)}
        </JobFact>
      ) : null}
    </Container>
  );
};
