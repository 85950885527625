import type { SocialUrlsData } from '@xing-com/crate-jobs-helpers';
import {
  IconButterflyMono,
  IconCompaniesFilled,
  IconFacebook,
  IconInstagram,
  IconKununu,
  IconPinterest,
  IconTiktok,
  IconTwitter,
  IconYoutube,
} from '@xing-com/icons';

import { SocialLinkButton, SocialLinksWrapper } from './social-links.styles';

type Service = keyof SocialUrlsData;

type SocialLinkProps = {
  url: string;
  service: Service;
};

type SocialLinksProps = {
  socialUrls: SocialUrlsData;
};

const SERVICE_ICON: Record<Service, React.FC> = {
  website: IconCompaniesFilled,
  facebook: IconFacebook,
  twitter: IconTwitter,
  youtube: IconYoutube,
  xing: IconButterflyMono,
  instagram: IconInstagram,
  pinterest: IconPinterest,
  tiktok: IconTiktok,
  kununu: IconKununu,
};

const isSupportedService = (service: string): service is Service => {
  return Object.keys(SERVICE_ICON).includes(service);
};

const isValidLink = (
  entry: [string, string | null]
): entry is [Service, string] => isSupportedService(entry[0]) && !!entry[1];

const SocialLink: React.FC<SocialLinkProps> = ({ url, service }) => (
  <SocialLinkButton
    icon={SERVICE_ICON[service]}
    href={url}
    // @ts-expect-error FIXME: SC6
    target="_blank"
    rel="nofollow noopener"
    aria-label={service}
  />
);

export const SocialLinks: React.FC<SocialLinksProps> = ({ socialUrls }) => {
  const validLinks = Object.entries(socialUrls).filter(isValidLink);

  if (!validLinks.length) return null;

  return (
    <SocialLinksWrapper>
      {validLinks.map(([service, url]) => {
        return <SocialLink key={service} service={service} url={url} />;
      })}
    </SocialLinksWrapper>
  );
};
