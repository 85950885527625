import styled from 'styled-components';

import { ContentBanner } from '@xing-com/banner';
import { Button } from '@xing-com/button';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { PopOver } from '@xing-com/pop-over';
import { scale070, spaceXXS } from '@xing-com/tokens';

export const CompassLegendButton = styled(Button)`
  display: flex;
  font-size: ${scale070};
  padding: 0;
`;

export const Legend = styled.div`
  text-align: left;
  font-weight: 400;
  margin-right: ${spaceXXS};
`;

export const StyledPopOver = styled(PopOver)`
  text-align: left;
  display: none;
  @media ${mediaConfined} {
    display: block;
  }
`;

export const CompassWrapper = styled.div`
  flex-basis: 100%;

  @media ${mediaWide} {
    flex-basis: 60%;
  }
`;

export const StyledContentBanner = styled(ContentBanner)`
  @media ${mediaConfined} {
    display: none;
  }
`;
