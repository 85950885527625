import { TrackingLink } from '@xing-com/crate-jobs-domain-job-details-components-tracking-link';
import { ProfileImage } from '@xing-com/profile-image';
import { ProfileInfo } from '@xing-com/profile-info';
import { BodyCopy } from '@xing-com/typography';

type Props = {
  className?: string;
  children?: React.ReactNode;
  link?: string;
  logo?: string;
  name?: string;
  alignment?: 'horizontal' | 'vertical';
  trackingParams?: string;
  'data-testid'?: string;
};

export const CompanyInfo: React.FC<Props> = ({
  className,
  children,
  link,
  logo,
  name,
  trackingParams,
  alignment,
  'data-testid': dataTestId,
}) => {
  const companyName = name ? (
    <TrackingLink link={link} trackingParams={trackingParams}>
      <BodyCopy
        noMargin
        size="medium"
        sizeConfined="xlarge"
        data-testid="job-details-company-info-name"
      >
        {name}
      </BodyCopy>
    </TrackingLink>
  ) : null;

  return (
    <ProfileInfo
      className={className}
      alignment={alignment}
      data-testid={dataTestId}
      profileImage={
        <TrackingLink link={link} trackingParams={trackingParams}>
          <ProfileImage
            type="company"
            size="small"
            sizeConfined="medium"
            src={logo}
          />
        </TrackingLink>
      }
    >
      {companyName}
      {children}
    </ProfileInfo>
  );
};
