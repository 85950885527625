/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const SingleSizeIllustrationExtVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="SingleSizeIllustrationExtVerified"
    role="presentation"
    {...props}
  >
    <path
      fill="#00D296"
      d="M9.48 1.4c1.162-1.867 3.878-1.867 5.04 0a2.97 2.97 0 0 0 3.193 1.322c2.142-.498 4.063 1.423 3.565 3.565A2.97 2.97 0 0 0 22.6 9.48c1.867 1.162 1.867 3.878 0 5.04a2.97 2.97 0 0 0-1.322 3.193c.498 2.142-1.423 4.063-3.565 3.565A2.97 2.97 0 0 0 14.52 22.6c-1.162 1.867-3.878 1.867-5.04 0a2.97 2.97 0 0 0-3.193-1.322c-2.142.498-4.063-1.423-3.565-3.565A2.97 2.97 0 0 0 1.4 14.52c-1.867-1.162-1.867-3.878 0-5.04a2.97 2.97 0 0 0 1.322-3.193c-.498-2.142 1.423-4.063 3.565-3.565A2.97 2.97 0 0 0 9.48 1.4"
    />
    <path
      fill="#005357"
      d="m18.423 8.613-1.177-1.176-7 7-3.5-3.5-1.177 1.176 4.677 4.677z"
    />
  </svg>
);
export default SingleSizeIllustrationExtVerified;
