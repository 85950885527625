import { OptimisticPublicationDate } from '@xing-com/crate-jobs-components-optimistic-publication-date';
import { Meta } from '@xing-com/typography';

type Props = {
  date?: string;
};

export const PublishedDate: React.FC<Props> = ({ date }) => {
  if (!date) return null;
  return (
    <Meta size="small" data-testid="job-details-published-date">
      <OptimisticPublicationDate date={date} />
    </Meta>
  );
};
