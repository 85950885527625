import type { WindowLocation } from '@reach/router';
import { useLocation } from '@reach/router';

import type {
  JDP_JOB_SLUGS,
  JDP_SA_DEFAULT_QUERY,
  JDP_SA_SEARCH_QUERY,
  JDP_BACK_PARAMS,
} from '@xing-com/crate-jobs-constants';
import type { SearchAlertFilters } from '@xing-com/crate-jobs-helpers';

export type NavigationState = {
  [JDP_JOB_SLUGS]?: {
    list: string[];
    type: 'similarJobs' | 'recos' | 'search';
    index: number;
  };
  [JDP_SA_SEARCH_QUERY]?: SearchAlertFilters;
  [JDP_SA_DEFAULT_QUERY]?: SearchAlertFilters;
  [JDP_BACK_PARAMS]?: string;
};

export const useNavigationState = (): NavigationState | undefined => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const { state } = useLocation() as WindowLocation<NavigationState>;
  return state ?? undefined;
};
