import { useIntl } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { BannerContextProvider } from '@xing-com/crate-jobs-components-banner';
import { JobNotAvailable } from '@xing-com/crate-jobs-domain-job-details-components-error';
import type {
  DescriptionFragment,
  JobPostingSkillFragment,
} from '@xing-com/crate-jobs-graphql';
import type {
  JobDetails,
  UserJobPreferencesData,
} from '@xing-com/crate-jobs-helpers';
import { useSimilarJobs, useJobContacts } from '@xing-com/crate-jobs-hooks';
import {
  JobSummary,
  SearchAlertsErrorBanner,
  SearchAlertsJobDetailsModal,
} from '@xing-com/crate-jobs-xinglets';
import { JobDetailsPushNotification as PushNotificationDialog } from '@xing-com/crate-notifications-xinglets';
import { XingletLoader } from '@xing-com/crate-xinglet';
import { GridContainer, GridItem } from '@xing-com/grid';

import { SIDEBAR_SIMILAR_JOBS_LIMIT, SIMILAR_JOBS_LIMIT } from '.';
import { CompanyContactsModule } from '../company-contacts-module';
import { CompanyModule } from '../company-module';
import { CompanyVideoModule } from '../company-video-module';
import { DescriptionModule } from '../description-module';
import { EmployerModule } from '../employer-module';
import { Header } from '../header';
import { JobMetadata } from '../job-metadata';
import { SalaryModule } from '../salary-module';
import { SearchAlertModule } from '../search-alert-module';
import { SimilarJobsModule, SimilarJobsSidebar } from '../similar-jobs-module';
import { useJobDetailsTracking } from '../tracking';
import { getModulesVisibility } from './get-modules-visibility';
import { JobDetailModule } from './job-detail-module';
import {
  Container,
  JobDetailsGridContainer,
  SimilarJobsSidebarGridItem,
  StyledObserver,
  TopBar,
} from './job-details-content.styles';

const isValidDescription = (description: DescriptionFragment): boolean => {
  return description.__typename !== 'HtmlDescription' || !!description.content;
};

type Props = {
  jobDetails: JobDetails;
  userJobPreferences?: UserJobPreferencesData;
  matchedSkills?: JobPostingSkillFragment[];
  notMatchedSkills?: JobPostingSkillFragment[];
};

export const JobDetailsContent: React.FC<Props> = ({
  jobDetails,
  userJobPreferences,
  matchedSkills,
  notMatchedSkills,
}) => {
  const { formatMessage } = useIntl();
  const { isLoggedIn } = useLoginState();
  const {
    id: jobId,
    slug,
    company,
    paid,
    employerData,
    searchAlertFilters,
    salary,
    banner,
    description,
    jobCode,
    videoUrl,
    globalId,
  } = jobDetails;
  const showSimilarJobsSidebar = !paid;
  const { loading: similarJobsLoading, similarJobs } = useSimilarJobs(
    showSimilarJobsSidebar ? SIDEBAR_SIMILAR_JOBS_LIMIT : SIMILAR_JOBS_LIMIT,
    jobId
  );
  const {
    loading: jobContactsLoading,
    error: jobContactsError,
    contacts: jobContacts,
    hasMoreContacts: hasMoreJobContacts,
  } = useJobContacts({
    jobId,
    consumer: 'loggedin.web.jobs.posting.contacts.module',
    sourceEntityId: company?.id,
  });
  const hasJobContacts =
    !jobContactsLoading && !jobContactsError && jobContacts.length > 0;
  const hasSimilarJobsSidebar =
    showSimilarJobsSidebar && (similarJobsLoading || similarJobs.length > 0);
  const isValidJob = isValidDescription(description);

  useJobDetailsTracking({
    job: jobDetails,
    userJobPreferences,
    matchedSkills,
    skip: !isValidJob,
  });

  if (!isValidJob) {
    return <JobNotAvailable />;
  }

  const { visibleModules } = getModulesVisibility(jobDetails, isLoggedIn);
  const title = formatMessage({ id: 'JOBS_JOB_DETAILS_TOPBAR_TITLE' });
  const similarJobSlugs = [slug, ...similarJobs.map((job) => job.job.slug)];

  return (
    <BannerContextProvider>
      <StyledObserver>
        <PushNotificationDialog jobId={jobId} />
        <JobMetadata job={jobDetails} />
        {isLoggedIn ? <TopBar type="subheader" title={title} /> : null}
        <Container>
          <Header job={jobDetails} similarJobSlugs={similarJobSlugs} />
          <JobDetailsGridContainer>
            <GridItem span={12}>
              <GridContainer variant="FullSize">
                <JobDetailModule visible={visibleModules.MatchingBanner}>
                  <XingletLoader
                    name="@xing-com/crate-jobs-domain-job-details-lo-matching-banner"
                    globalId={globalId}
                  />
                </JobDetailModule>
                <JobDetailModule visible={visibleModules.Summary}>
                  <JobSummary
                    job={jobDetails}
                    userJobPreferences={userJobPreferences}
                    matchedSkills={matchedSkills}
                    notMatchedSkills={notMatchedSkills}
                  />
                </JobDetailModule>
                <JobDetailModule
                  spanLarge={hasSimilarJobsSidebar ? 8 : 12}
                  visible={visibleModules.Description}
                >
                  <DescriptionModule
                    description={description}
                    banner={banner}
                    jobCode={jobCode}
                  />
                </JobDetailModule>
                {hasSimilarJobsSidebar ? (
                  <SimilarJobsSidebarGridItem>
                    <SimilarJobsSidebar
                      similarJobs={similarJobs.slice(0, 4)}
                      isLoading={similarJobsLoading}
                    />
                  </SimilarJobsSidebarGridItem>
                ) : null}
              </GridContainer>
            </GridItem>
            <JobDetailModule visible={visibleModules.Salary}>
              <SalaryModule salary={salary} />
            </JobDetailModule>
            <JobDetailModule visible={visibleModules.Company}>
              <CompanyModule jobSlug={slug} company={company} />
            </JobDetailModule>
            <JobDetailModule visible={visibleModules.Culture}>
              <EmployerModule employerData={employerData} />
            </JobDetailModule>
            <JobDetailModule
              visible={visibleModules.Contacts && hasJobContacts}
            >
              <CompanyContactsModule
                jobSlug={slug}
                contacts={jobContacts}
                hasMoreContacts={hasMoreJobContacts}
                company={company}
              />
            </JobDetailModule>
            <JobDetailModule visible={visibleModules.SearchAlerts}>
              <SearchAlertModule searchAlertFilters={searchAlertFilters} />
            </JobDetailModule>
            <JobDetailModule visible={visibleModules.Video}>
              <CompanyVideoModule jobId={jobId} videoUrl={videoUrl} />
            </JobDetailModule>
            <JobDetailModule visible={visibleModules.SimilarJobs}>
              <SimilarJobsModule
                isLoading={similarJobsLoading}
                similarJobs={similarJobs}
                hasSidebarJobs={showSimilarJobsSidebar}
              />
            </JobDetailModule>
          </JobDetailsGridContainer>
        </Container>
      </StyledObserver>
      <SearchAlertsJobDetailsModal />
      <SearchAlertsErrorBanner />
    </BannerContextProvider>
  );
};
