import { FormattedMessage } from 'react-intl';

import { Hyperlink } from '@xing-com/hyperlink';

import { NoConsentMessage } from '../../no-consent-message';

type Props = {
  url: string;
};

export const ExternalUrlConsentPlaceholder: React.FC<Props> = ({ url }) => (
  <NoConsentMessage
    headline={
      <FormattedMessage id="JOBS_JOB_DETAILS_EXTERNAL_URL_DESCRIPTION_NO_CONSENT_HEADLINE" />
    }
  >
    <FormattedMessage
      id="JOBS_JOB_DETAILS_EXTERNAL_URL_DESCRIPTION_NO_CONSENT_LINK"
      values={{
        link: (
          <FormattedMessage id="JOBS_JOB_DETAILS_EXTERNAL_URL_DESCRIPTION_NO_CONSENT_LINK_CONTENT">
            {(text) => (
              <Hyperlink
                external
                // @ts-expect-error FIXME: SC6
                target="_blank"
                rel="noopener"
                href={url}
              >
                {text}
              </Hyperlink>
            )}
          </FormattedMessage>
        ),
      }}
    />
  </NoConsentMessage>
);
