import type React from 'react';
import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceS } from '@xing-com/tokens';

import { Highlight } from './highlight';

const StyledHighlight = styled(Highlight)`
  width: fit-content;
  margin-bottom: ${spaceS};

  @media ${mediaConfined} {
    margin-bottom: ${spaceL};
  }
`;

type Props = {
  label?: string;
};

export const EarnUpToHighlight: React.FC<Props> = ({ label }) => {
  return label ? (
    <StyledHighlight variant="highlight">{label}</StyledHighlight>
  ) : null;
};
