import { useState } from 'react';

type Modal<T> = {
  activeModal?: T;
  toggleModal: (action?: T) => void;
};

export const useModal = <T,>(): Modal<T> => {
  const [activeModal, setActiveModal] = useState<T | undefined>(undefined);
  return {
    activeModal,
    toggleModal: (modal?: T): void => setActiveModal(modal),
  };
};
