import type { PropsWithChildren } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { ErrorBanner } from './error-banner';
import { SuccessBanner } from './success-banner';

type BannerOptions = {
  bannerAction?: () => void;
  bannerActionMessage?: string;
};

type BannerContextValue = {
  showSuccessBanner: (message: string, options?: BannerOptions) => void;
  showErrorBanner: (message: string) => void;
};

export type BannerState = {
  message: string;
  visible: boolean;
};

export type ActionBannerState = BannerState & {
  action?: () => void;
  actionMessage?: string;
};

const BannerContext = React.createContext<BannerContextValue | undefined>(
  undefined
);

export const BannerContextProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [successState, setSuccessState] = useState<ActionBannerState>({
    message: '',
    visible: false,
  });
  const [errorState, setErrorState] = useState<BannerState>({
    message: '',
    visible: false,
  });

  const hideSuccessBanner = (): void => {
    setSuccessState({ message: '', visible: false });
  };

  const showSuccessBanner = useCallback(
    (message: string, options: BannerOptions | undefined) => {
      const {
        bannerAction,
        bannerActionMessage = 'JOBS_MYJOBS_JOB_BANNER_MESSAGE_UNDO',
      } = options ?? {};
      hideSuccessBanner(); // Hide previous banner, if any, to restart timer
      setSuccessState({
        message,
        visible: true,
        action: bannerAction,
        actionMessage: bannerActionMessage,
      });
    },
    [setSuccessState]
  );
  const hideErrorBanner = (): void => {
    setErrorState({ message: '', visible: false });
  };

  const showErrorBanner = useCallback((message: string) => {
    hideErrorBanner(); // Hide previous banner, if any
    setErrorState({ message, visible: true });
  }, []);

  const value = useMemo<BannerContextValue>(
    () => ({
      showSuccessBanner,
      showErrorBanner,
    }),
    [showErrorBanner, showSuccessBanner]
  );

  return (
    <BannerContext.Provider value={value}>
      {children}
      <SuccessBanner
        successState={successState}
        handleOnClose={hideSuccessBanner}
      />
      <ErrorBanner errorState={errorState} handleOnClose={hideErrorBanner} />
    </BannerContext.Provider>
  );
};

export const useBannerContext = (): BannerContextValue => {
  const context = React.useContext(BannerContext);
  if (!context) {
    throw new Error(
      'useBannerContext must be used within a BannerContextProvider'
    );
  }
  return context;
};
