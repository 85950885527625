import { useQuery } from '@apollo/client';

import type { JobPostingSkillFragment } from '@xing-com/crate-jobs-graphql';
import { GetJobMatchingSkillsDocument } from '@xing-com/crate-jobs-graphql';

export type UseJobMatchingSkillsResponse = {
  loading: boolean;
  matchedSkills?: JobPostingSkillFragment[];
  notMatchedSkills?: JobPostingSkillFragment[];
};
type Props = {
  slug: string;
  skip?: boolean;
};

export const useJobMatchingSkills = ({
  slug,
  skip = false,
}: Props): UseJobMatchingSkillsResponse => {
  const { loading, data } = useQuery(GetJobMatchingSkillsDocument, {
    variables: { slug },
    skip,
  });

  const skills = (data?.job?.__typename === 'VisibleJob' &&
    data.job.userProfileMatches?.skills) || {
    matchedSkills: [],
    notMatchedSkills: [],
  };

  return {
    loading,
    matchedSkills: skills.matchedSkills?.filter((skill) => !!skill),
    notMatchedSkills: skills.notMatchedSkills?.filter((skill) => !!skill),
  };
};
