/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetHrContactQueryVariables = Types.Exact<{
  jobSlug: Types.Scalars['String']['input'];
  companyId: Types.Scalars['SlugOrID']['input'];
}>;

export type GetHrContactQuery = {
  __typename?: 'Query';
  job?:
    | { __typename?: 'UnauthorizedJob' }
    | {
        __typename?: 'VisibleJob';
        id: string;
        hiringContact?: {
          __typename?: 'JobHiringContact';
          user?: {
            __typename?: 'XingId';
            id: string;
            displayName: string;
            firstName: string;
            lastName: string;
            pageName: string;
            gender?: Types.Gender | null;
            clickReasonProfileUrlFromEntity?: {
              __typename?: 'ClickReason';
              code: string;
            } | null;
            profileImage?: Array<{
              __typename?: 'ProfileImage';
              url: string;
            } | null> | null;
            occupations?: Array<{
              __typename?: 'XingIdOccupation';
              headline: string;
              subline: string;
            } | null> | null;
            userFlags?: {
              __typename?: 'UserFlags';
              displayFlag?: Types.UserFlagsDisplayFlag | null;
            } | null;
          } | null;
        } | null;
      }
    | null;
};

export const GetHrContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHRContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobSlug' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobSlug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VisibleJob' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiringContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'HiringContact',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'clickReasonProfileUrlFromEntity',
                                    },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'clickReasonId',
                                        },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'CR_WEB_JOBS_COMPANY_CONTACTS',
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'sourceEntityId',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyId',
                                          },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'code' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HiringContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'XingId' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pageName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'EnumValue', value: 'SQUARE_96' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'occupations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subline' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayFlag' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHrContactQuery, GetHrContactQueryVariables>;
