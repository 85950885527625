import { CompanyMembership } from '@xing-com/crate-common-graphql-types';

import type { ExtendedContact } from './contact';

const appendQueryParams = (
  url: string,
  params: Record<string, string>
): string => {
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    urlParams.append(key, value);
  });
  return `${url}?${urlParams.toString()}`;
};

export const addContactTracking =
  (origin: string) =>
  (contact: ExtendedContact): ExtendedContact => {
    if (contact?.user?.connectionDegree === 1) {
      const contactType =
        contact.companyMembership === CompanyMembership.Current
          ? 'jobs_current_employee'
          : 'jobs_former_employee';

      return {
        ...contact,
        user: {
          ...contact.user,
          url: appendQueryParams(
            `/profile/${contact?.user?.slug}${
              contact?.user?.clickReasonProfileUrlFromEntity?.code
                ? `/${contact.user?.clickReasonProfileUrlFromEntity?.code}`
                : ''
            }`,
            {
              sc_o: origin,
              sc_o_PropActionOrigin: contactType,
            }
          ),
        },
      };
    }

    return {
      ...contact,
      connectedBy: contact?.connectedBy?.map((edge) => {
        return {
          ...edge,
          user: {
            ...edge.user,
            url: appendQueryParams(
              `/profile/${edge?.user?.slug}${
                edge.user?.clickReasonProfileUrlFromEntity?.code
                  ? `/${edge.user?.clickReasonProfileUrlFromEntity?.code}`
                  : ''
              }`,
              {
                sc_o: origin,
                sc_o_PropActionOrigin: 'jobs_1st_degree_contact',
              }
            ),
          },
        };
      }),
      user: {
        ...contact.user,
        url: appendQueryParams(
          `/profile/${contact?.user?.slug}${
            contact?.user?.clickReasonProfileUrlFromEntity?.code
              ? `/${contact.user.clickReasonProfileUrlFromEntity.code}`
              : ''
          }`,
          {
            sc_o: origin,
            sc_o_PropActionOrigin: 'jobs_2nd_degree_contact',
          }
        ),
      },
    };
  };
