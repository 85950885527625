import styled from 'styled-components';

import type { SimilarJobData } from '@xing-com/crate-jobs-helpers';
import { GridContainer, GridItem } from '@xing-com/grid';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceL, spaceXL, spaceXXL } from '@xing-com/tokens';

import { ModuleTitle } from '../module-title';
import { SimilarJob } from '../similar-job';
import { SimilarJobsLoading } from './similar-jobs-loading';

type SimilarJobsModuleProps = {
  isLoading: boolean;
  similarJobs: SimilarJobData[];
  hasSidebarJobs: boolean;
};

const StyledGridContainer = styled(GridContainer)`
  column-gap: ${spaceL};
  row-gap: ${spaceXXL};

  @media ${mediaWideNavless} {
    row-gap: ${spaceXL};
  }
`;

const SimilarJobWrapper = styled(GridItem)<{ hasSidebarJobs: boolean }>`
  // ensure that we don't display more than 9 elements
  &:nth-child(n + 10) {
    display: none;
  }

  @media ${mediaWideNavless} {
    // on large screen if we display 4 elements in the sidebar we need to hide
    // the first 4 elements on the similar jobs module
    &:nth-child(-n + 4) {
      display: ${({ hasSidebarJobs }) => (hasSidebarJobs ? 'none' : 'block')};
    }

    // on large screen if we display 4 elements in the sidebar we need to show
    // elements up to the 13th in the similar jobs module
    &:nth-child(n + 10) {
      display: ${({ hasSidebarJobs }) => (hasSidebarJobs ? 'block' : 'none')};
    }
  }
`;

export const SimilarJobsModule: React.FC<SimilarJobsModuleProps> = ({
  isLoading = false,
  similarJobs,
  hasSidebarJobs,
}: SimilarJobsModuleProps) =>
  similarJobs.length > 0 ? (
    <div>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_SIMILAR_JOBS_HEADLINE" />
      <StyledGridContainer variant="FullSize">
        {isLoading ? (
          <SimilarJobsLoading count={3} />
        ) : (
          <>
            {similarJobs.map((similarJob) => (
              <SimilarJobWrapper
                span={12}
                spanLarge={4}
                hasSidebarJobs={hasSidebarJobs}
                key={similarJob.job.id}
              >
                <SimilarJob similarJob={similarJob} ijtParam="jb_20" />
              </SimilarJobWrapper>
            ))}
          </>
        )}
      </StyledGridContainer>
    </div>
  ) : null;
