import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import { mediaSmallOnly } from '@xing-com/layout-tokens';

import { DESCRIPTION_PREVIEW_HEIGHT } from '../constants';

const HEIGHT_THRESHOLD = 100;
const MIN_HEIGHT = DESCRIPTION_PREVIEW_HEIGHT;
const EXTRA_HEIGHT = 60;

const IFrameWrapper = styled.div`
  // Fix for Safari Mobile where the non-responsive iframes are not properly handled.
  // https://davidwalsh.name/scroll-iframes-ios
  @media ${mediaSmallOnly} {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

const IFrame = styled.iframe`
  display: block;
  width: 100%;
  border: 0;
`;

const IFrameLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 66vh;
`;

type Props = {
  proxyUrl: string;
};

const IFrameLoading: React.FC = () => (
  <IFrameLoadingWrapper>
    <DotLoadingIndicator />
  </IFrameLoadingWrapper>
);

export const ExternalURLDescriptionIframe: React.FC<Props> = ({ proxyUrl }) => {
  const [height, setHeight] = useState(0);
  const [isLoading, setLoadingState] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleResize = useCallback(
    (iframe: React.RefObject<HTMLIFrameElement>) => {
      const calculatedHeight = Math.max(
        iframe.current?.contentWindow?.document.body.scrollHeight || MIN_HEIGHT,
        iframe.current?.contentWindow?.document.documentElement.scrollHeight ||
          MIN_HEIGHT
      );

      if (calculatedHeight < HEIGHT_THRESHOLD) {
        setHeight(MIN_HEIGHT);
      } else {
        setHeight(calculatedHeight + EXTRA_HEIGHT);
      }
    },
    []
  );

  useEffect(() => {
    handleResize(iframeRef);
  }, [handleResize, iframeRef]);

  return (
    <IFrameWrapper>
      {isLoading && <IFrameLoading />}
      <IFrame
        height={height}
        ref={iframeRef}
        data-testid="job-description-iframe"
        src={proxyUrl}
        onLoad={() => {
          setLoadingState(false);
          handleResize(iframeRef);
        }}
      />
    </IFrameWrapper>
  );
};
