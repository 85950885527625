import styled from 'styled-components';

import type { EmployerData } from '@xing-com/crate-jobs-helpers';
import { mediaConfined } from '@xing-com/layout-tokens';
import { StarRating } from '@xing-com/star-rating';

import { ModuleTitle } from '../../module-title';
import { KununuLogo } from './kununu-logo';
import { KununuReviews } from './kununu-reviews';

type Props = {
  kununuData: EmployerData['kununu'];
};
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column-reverse;

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

export const Kununu: React.FC<Props> = ({ kununuData }) => {
  const { ratingAverage, ratingCount, kununuLink } = kununuData;

  return ratingAverage && ratingCount && kununuLink ? (
    <Content>
      <div>
        <ModuleTitle
          titleKey="JOBS_JOB_DETAILS_EMPLOYEE_REVIEWS_TITLE"
          size="large"
        />
        <StarRating rating={ratingAverage} />
        <KununuReviews ratingCount={ratingCount} kununuLink={kununuLink} />
      </div>
      <KununuLogo />
    </Content>
  ) : null;
};
