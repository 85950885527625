import VimeoPlayer from 'react-player/vimeo';
import YoutubePlayer from 'react-player/youtube';
import styled, { css } from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXL } from '@xing-com/tokens';

type Props = {
  type: 'vimeo' | 'youtube';
  url: string;
};

const VideoWrapper = styled.section`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  width: 100%;
  margin: ${spaceXL} 0;

  @media ${mediaConfined} {
    margin: calc(${spaceXL} * 2) 0;
  }
`;

const videoStyles = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
`;

const StyledVimeoPlayer = styled(VimeoPlayer)`
  ${videoStyles}
`;

const StyledYoutubePlayer = styled(YoutubePlayer)`
  ${videoStyles}
`;

export const Video: React.FC<Props> = ({ type, url }) => {
  const videoProps = {
    url,
    controls: true,
    width: '100%',
    height: '100%',
    'data-testid': `${type}-video`,
    title: `${type}-player`,
  };

  return (
    <VideoWrapper>
      {type === 'vimeo' ? (
        <StyledVimeoPlayer {...videoProps} />
      ) : type === 'youtube' ? (
        <StyledYoutubePlayer {...videoProps} />
      ) : null}
    </VideoWrapper>
  );
};
