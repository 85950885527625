import styled from 'styled-components';

import { SkeletonBody, SkeletonHeadline } from '@xing-com/skeleton';
import { spaceXXL } from '@xing-com/tokens';

const StyledSkeleton = styled(SkeletonBody)`
  margin-bottom: ${spaceXXL};
`;

export const LoadingPlaceholder: React.FC = () => {
  return (
    <div data-testid="third-party-consent-loading">
      <StyledSkeleton amountRows={2} size="small" as={SkeletonHeadline} />
      <StyledSkeleton amountRows={10} />
      <StyledSkeleton amountRows={10} />
      <StyledSkeleton amountRows={10} />
    </div>
  );
};
