import {
  SectionWrapper,
  StyledContent,
  StyledHeadline,
} from './section.styles';

type SectionProps = {
  title?: string;
  content: string;
  titleColor?: string;
};

export const Section: React.FC<SectionProps> = ({
  title,
  content,
  titleColor,
}) => {
  return (
    <SectionWrapper>
      {title ? (
        // @ts-expect-error FIXME: SC6
        <StyledHeadline
          $customColor={titleColor}
          dangerouslySetInnerHTML={{ __html: title }}
        ></StyledHeadline>
      ) : null}
      <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
    </SectionWrapper>
  );
};
