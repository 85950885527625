import styled from 'styled-components';

import { IconButton } from '@xing-com/button';
import {
  spaceL,
  spaceS,
  xdlColorBackgroundTertiary,
  xdlColorHoverTertiary,
} from '@xing-com/tokens';

export const SocialLinksWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${spaceS};
  margin-top: ${spaceL};
`;

export const SocialLinkButton = styled(IconButton)`
  &&::after {
    background-color: ${xdlColorBackgroundTertiary};
  }

  &&:hover::after {
    background-color: ${xdlColorHoverTertiary};
  }
`;
