import * as S from './culture-compass.styles';

export type Props = {
  industryAngle: number;
  profileAngle: number;
  leftLegend?: string | JSX.Element;
  rightLegend?: string | JSX.Element;
};

type ProfileHandProps = {
  profileAngle: number;
};

const ProfileHand: React.FC<ProfileHandProps> = ({ profileAngle }) => (
  <S.ProfileHandOuterWrapper $profileAngle={profileAngle}>
    <S.ProfileHandInnerWrapper>
      <S.ProfileHand />
    </S.ProfileHandInnerWrapper>
  </S.ProfileHandOuterWrapper>
);

type IndustryHandProps = {
  industryAngle: number;
};

const IndustryHand: React.FC<IndustryHandProps> = ({ industryAngle }) => (
  <S.IndustryHandOuterWrapper $industryAngle={industryAngle}>
    <S.IndustryHandInnerWrapper>
      <S.IndustryHand />
    </S.IndustryHandInnerWrapper>
  </S.IndustryHandOuterWrapper>
);

export const CultureCompass: React.FC<Props> = ({
  industryAngle,
  profileAngle,
  leftLegend,
  rightLegend,
}) => {
  return (
    <S.Container>
      <S.CompassWrapper>
        <S.Background />

        <IndustryHand industryAngle={industryAngle} />

        <ProfileHand profileAngle={profileAngle} />

        <S.OriginWrapper>
          <S.Origin />
        </S.OriginWrapper>
      </S.CompassWrapper>

      {leftLegend && rightLegend ? (
        <S.LegendContainer>
          <S.LeftLegend size="small" noMargin>
            {leftLegend}
          </S.LeftLegend>
          <S.RightLegend size="small" noMargin>
            {rightLegend}
          </S.RightLegend>
        </S.LegendContainer>
      ) : null}
    </S.Container>
  );
};
