/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationEmptyStateSuitcase = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationEmptyStateSuitcase"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#DBE2E6"
        fillRule="evenodd"
        d="M61.59 63.141H7.212L6.81 23.232h54.378z"
        clipRule="evenodd"
      />
      <path
        fill="#BCC9D1"
        fillRule="evenodd"
        d="m44.441 45.047-20.463.199-.101-10.46 20.462-.198z"
        clipRule="evenodd"
      />
      <path
        fill="#5E737D"
        fillRule="evenodd"
        d="M62.188 23.224c.094 9.735-5.672 16.968-14.575 17.224l-.323.006-26.25.255c-8.983.087-14.99-6.886-15.223-16.558l-.006-.35v-.568h2v.558c.084 8.694 5.174 14.827 12.892 14.918h.318l26.25-.255c7.781-.075 12.864-6.218 12.918-14.855v-.356zM33.818 4.859c7.867-.077 14.306 6.24 14.383 14.106l.043 4.46h-4.735l-.042-4.334a9.51 9.51 0 0 0-9.602-9.417 9.51 9.51 0 0 0-9.417 9.602l.032 4.148h-4.734l-.033-4.183c-.076-7.867 6.239-14.306 14.106-14.382"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IllustrationEmptyStateSuitcase;
