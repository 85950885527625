import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  spaceS,
  spaceXL,
  spaceXS,
  xdlColorBorder,
  xdlColorText,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Legend = styled(BodyCopy)<{ $type: 'profile' | 'industry' }>(
  ({ $type }) => css`
    flex: 1 1 100%;
    display: inline-flex;
    align-items: center;
    margin-bottom: ${spaceXS};

    ${$type === 'profile' &&
    css`
      margin-right: ${spaceXL};
    `}

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border: 2px solid ${$type === 'profile' ? xdlColorText : xdlColorBorder};
      border-radius: 50%;
      background-color: ${$type === 'profile' ? xdlColorText : 'none'};
      margin-right: ${spaceS};
    }

    @media ${mediaConfined} {
      flex: 0 1 100%;
    }

    @media ${mediaWide} {
      flex: 0 1 auto;
    }
  `
);

type Props = {
  companyName?: string;
};

export const CultureLegend: React.FC<Props> = ({ companyName }) => {
  return (
    <LegendWrapper>
      <Legend $type="profile" size="small">
        {companyName}
      </Legend>
      <Legend $type="industry" size="small">
        <FormattedMessage id="JOBS_JOB_DETAILS_COMPANY_CULTURE_LEGEND_INDUSTRY" />
      </Legend>
    </LegendWrapper>
  );
};
