/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconKununu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconKununu"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.125 3.786a.44.44 0 0 1 .448.435v7.647a5.5 5.5 0 0 1 1.781-.297h.907v-.01c.974 0 1.763-.771 1.763-1.716l.057-.882a.444.444 0 0 1 .44-.362h2.677c.222 0 .404.159.44.362h.012v.882a5.15 5.15 0 0 1-1.347 3.464 5.16 5.16 0 0 1 1.385 3.511v.878l-.044 2.722a.44.44 0 0 1-.446.436H10.52a.443.443 0 0 1-.446-.436v-2.722h-.01v-.878c0-.947-.792-1.718-1.764-1.718h-.946c-.973 0-1.763.77-1.763 1.718h-.018v3.6a.44.44 0 0 1-.448.436H2.446A.44.44 0 0 1 2 20.42V4.222a.44.44 0 0 1 .446-.436zm10.97 4.08L16.23 8c.144.14.156.375.011.517l-1.076 1.048a.443.443 0 0 0 0 .642l.177.173a.48.48 0 0 0 .661.005l1.077-1.05a.384.384 0 0 1 .529.005l.135.135a.35.35 0 0 1 .006.509l-1.079 1.05a1.444 1.444 0 0 1-2.008.006l-.177-.171a1.354 1.354 0 0 1 .008-1.956l1.005-.982.067-.065a.38.38 0 0 1 .527 0m4.397-.01 1.079 1.051c.556.54.561 1.415.006 1.958l-.175.17a1.443 1.443 0 0 1-2.008-.006l-1.008-.982-.066-.064a.357.357 0 0 1 0-.513l.136-.134a.387.387 0 0 1 .531-.01l1.077 1.05a.476.476 0 0 0 .66 0l.176-.172a.45.45 0 0 0 .003-.647l-1.076-1.049a.36.36 0 0 1 .007-.515l.137-.132a.375.375 0 0 1 .521-.005m.917-3.731.177.171a1.36 1.36 0 0 1-.005 1.959l-1.008.98-.066.065a.38.38 0 0 1-.526 0l-.139-.134a.364.364 0 0 1-.01-.517l1.078-1.05a.447.447 0 0 0 0-.64l-.178-.175a.48.48 0 0 0-.661 0L18.994 5.83a.38.38 0 0 1-.529-.006l-.136-.133a.353.353 0 0 1-.006-.508l1.079-1.052a1.447 1.447 0 0 1 2.007-.006m-4.732 0 1.008.98.066.065a.36.36 0 0 1 0 .513l-.136.133a.387.387 0 0 1-.532.01l-1.074-1.05a.48.48 0 0 0-.661 0l-.177.172a.45.45 0 0 0-.003.647l1.075 1.048a.356.356 0 0 1-.006.514l-.135.134a.37.37 0 0 1-.522.004L14.5 6.247a1.36 1.36 0 0 1-.006-1.958l.178-.173a1.44 1.44 0 0 1 2.005.009"
      clipRule="evenodd"
    />
  </svg>
);
export default IconKununu;
