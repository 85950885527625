import { IconTick } from '@xing-com/icons';
import { Marker, type MarkerVariant } from '@xing-com/marker';

type HighlightProps = {
  variant?: MarkerVariant;
  className?: string;
  children?: React.ReactNode;
};

export const Highlight: React.FC<HighlightProps> = ({
  variant,
  children,
  className,
}) => (
  <Marker
    variant={variant}
    icon={variant === 'match' ? IconTick : undefined}
    className={className}
  >
    {children}
  </Marker>
);
