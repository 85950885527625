import type { TemplateData } from '@xing-com/crate-common-graphql-types';

import { VideoConsentPlaceholder } from '../../no-consent-message';
import { ShowMore } from '../../show-more';
import { DESCRIPTION_PREVIEW_HEIGHT } from '../constants';
import { Image } from './image';
import { Section } from './section';
import { SocialLinks } from './social-links';
import { Video } from './video';

type Props = {
  description: TemplateData;
  externalContentConsent: boolean;
  skipShowMore: boolean;
};

type TemplateDataContentProps = {
  description: TemplateData;
  externalContentConsent: boolean;
};

const TemplateDataContent: React.FC<TemplateDataContentProps> = ({
  description,
  externalContentConsent,
}) => {
  const {
    sectionTitleColor,
    headerImage,
    genericDescription,
    companyDescriptionTitle,
    companyDescriptionContent,
    responsibilityTitle,
    responsibilityContent,
    skillsTitle,
    skillsContent,
    weOfferTitle,
    weOfferContent,
    contactInfoTitle,
    contactInfoContent,
    footerImage,
    videoUrls,
    socialUrls,
  } = description;

  return (
    <>
      {headerImage ? (
        <Image src={headerImage} data-testid="header-image" />
      ) : null}
      {genericDescription ? <Section content={genericDescription} /> : null}
      {companyDescriptionContent ? (
        <Section
          title={companyDescriptionTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={companyDescriptionContent}
        />
      ) : null}
      {responsibilityContent ? (
        <Section
          title={responsibilityTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={responsibilityContent}
        />
      ) : null}
      {skillsContent ? (
        <Section
          title={skillsTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={skillsContent}
        />
      ) : null}
      {weOfferContent ? (
        <Section
          title={weOfferTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={weOfferContent}
        />
      ) : null}
      {contactInfoContent ? (
        <Section
          title={contactInfoTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={contactInfoContent}
        />
      ) : null}
      {footerImage ? (
        <Image src={footerImage} data-testid="footer-image" />
      ) : null}
      {videoUrls?.vimeo ? (
        externalContentConsent ? (
          <Video type="vimeo" url={videoUrls.vimeo} />
        ) : (
          <VideoConsentPlaceholder url={videoUrls.vimeo} />
        )
      ) : null}
      {videoUrls?.youtube ? (
        externalContentConsent ? (
          <Video type="youtube" url={videoUrls.youtube} />
        ) : (
          <VideoConsentPlaceholder url={videoUrls.youtube} />
        )
      ) : null}
      {socialUrls ? <SocialLinks socialUrls={socialUrls} /> : null}
    </>
  );
};

export const TemplateDataDescription: React.FC<Props> = ({
  description,
  externalContentConsent,
  skipShowMore,
}) => {
  return skipShowMore ? (
    <TemplateDataContent
      description={description}
      externalContentConsent={externalContentConsent}
    />
  ) : (
    <ShowMore
      previewHeight={DESCRIPTION_PREVIEW_HEIGHT}
      expandLabelKey={'JOBS_JOB_DETAILS_DESCRIPTION_SHOW_MORE'}
      collapseLabelKey={'JOBS_JOB_DETAILS_DESCRIPTION_SHOW_LESS'}
      hideOnDesktop
      centered
    >
      <TemplateDataContent
        description={description}
        externalContentConsent={externalContentConsent}
      />
    </ShowMore>
  );
};
