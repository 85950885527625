import { getTimeDifferenceInDays } from './job-age';
import type { JobAgeKey } from './job-details-types';

const BE_AN_EARLY_APPLICANT_THRESHOLD = 3;
const LAST_CHANCE_THRESHOLD = 7;
const ACTIVELY_RECRUITING_MIN_THRESHOLD = 15;
const ACTIVELY_RECRUITING_MAX_THRESHOLD = 30;

export const getMatchingJobAgeKey = (
  postingDate?: string | null,
  expireDate?: string | null
): JobAgeKey | undefined => {
  if (!postingDate) return;

  const daysSincePostingDate = getTimeDifferenceInDays(postingDate);

  if (daysSincePostingDate <= BE_AN_EARLY_APPLICANT_THRESHOLD) {
    return 'JOBS_HIGHLIGHT_EARLY_APPLICANT';
  }

  if (expireDate) {
    const daysToExpire = getTimeDifferenceInDays(expireDate);

    if (daysToExpire <= LAST_CHANCE_THRESHOLD) {
      return 'JOBS_HIGHLIGHT_LAST_CHANCE_TO_APPLY';
    }
  }

  if (
    daysSincePostingDate >= ACTIVELY_RECRUITING_MIN_THRESHOLD &&
    daysSincePostingDate <= ACTIVELY_RECRUITING_MAX_THRESHOLD
  ) {
    return 'JOBS_HIGHLIGHT_ACTIVELY_RECRUITING';
  }

  return undefined;
};
