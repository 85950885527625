import type * as React from 'react';

import { useViewerData } from '@xing-com/hub';
import { ProfileImage } from '@xing-com/profile-image';
import { convertUserGender } from '@xing-com/social-utils';
import { BodyCopy, Headline } from '@xing-com/typography';

import { Styled } from './logged-in-user-info.styles';

export type LoggedInUserInfoType = {
  className?: string;
  subText: string;
};

export const LoggedInUserInfo: React.FC<LoggedInUserInfoType> = ({
  className,
  subText,
}) => {
  const viewerData = useViewerData();
  const user = viewerData?.data?.user;

  return (
    <Styled.Wrapper {...{ className }}>
      <ProfileImage
        {...{
          profileName: user?.displayName || '',
          size: 'small',
          src: user?.profileImage?.[0]?.url,
          type: convertUserGender(user?.gender),
        }}
      />
      <Styled.Description>
        <Headline noMargin ellipsis>
          {user?.displayName || ''}
        </Headline>
        <BodyCopy noMargin size="small" ellipsis>
          {subText}
        </BodyCopy>
      </Styled.Description>
    </Styled.Wrapper>
  );
};
