import type { PropsWithChildren } from 'react';

import type { SpanSizes } from '@xing-com/grid';
import { GridItem } from '@xing-com/grid';

type Props = {
  visible: boolean;
  spanLarge?: SpanSizes;
};

export const JobDetailModule: React.FC<PropsWithChildren<Props>> = ({
  visible,
  spanLarge = 12,
  children,
}) => {
  if (!visible) return null;

  return <GridItem spanLarge={spanLarge}>{children}</GridItem>;
};
