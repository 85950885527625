import { Helmet } from 'react-helmet-async';
import serialize from 'serialize-javascript';

import type { TemplateData } from '@xing-com/crate-common-graphql-types';
import { JobRemoteOptionValue } from '@xing-com/crate-common-graphql-types';
import type { DescriptionFragment } from '@xing-com/crate-jobs-graphql';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { useExperiment } from '@xing-com/hub';

type Props = {
  job: JobDetails;
};

const concatenateTemplateData = (description: TemplateData): string => `
    ${description.genericDescription}
    ${description.companyDescriptionTitle}
    ${description.companyDescriptionContent}
    ${description.responsibilityTitle}
    ${description.responsibilityContent}
    ${description.skillsTitle}
    ${description.skillsContent}
    ${description.weOfferTitle}
    ${description.weOfferContent}
    ${description.contactInfoTitle}
    ${description.contactInfoContent}
  `;

const getJobDescription = (description: DescriptionFragment): string => {
  switch (description.__typename) {
    case 'HtmlDescription':
      return description.content ?? '';
    case 'TemplateData':
      return concatenateTemplateData(description);
    case 'ExternalUrlDescription':
      return description.htmlContent;
    default:
      return '';
  }
};

export const StructuredData: React.FC<Props> = ({ job }) => {
  const isRefreshExperimentActive = useExperiment('ABACUS-469') === 'B';
  const {
    activatedAt,
    activeUntil,
    application,
    company,
    description,
    employerData,
    facts: { employmentType },
    highlights,
    industry,
    location,
    paid,
    refreshedAt,
    serviceOfferingGroup,
    title,
  } = job;

  const showRating =
    employerData?.kununu.ratingAverage && employerData?.kununu.ratingCount;
  const showRefreshedAt =
    paid && serviceOfferingGroup > 0 && isRefreshExperimentActive; //serviceOfferingGroup > 0 means it is a top paid job

  const aggregateRating = {
    '@type': 'AggregateRating',
    ratingValue: employerData?.kununu.ratingAverage,
    ratingCount: employerData?.kununu.ratingCount,
    worstRating: 1,
    bestRating: 5,
  };

  const hiringOrganization = {
    '@type': 'Organization',
    name: company?.name,
    url: company?.link,
    logo: company?.logo,
    ...(showRating && { aggregateRating }),
  };

  const jobLocation = {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      streetAddress: location.street,
      addressLocality: location.city,
      addressRegion: location.region,
      postalCode: location.zipCode,
      addressCountry: location.countryCode,
    },
  };

  // Using https://developers.google.com/search/docs/data-types/job-posting#structured-data-type-definitions
  const jobPostingSchema = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title,
    description: getJobDescription(description),
    datePosted: showRefreshedAt ? refreshedAt : activatedAt,
    directApply: application.type === 'JobXingApplication',
    validThrough: activeUntil,
    industry: industry?.name,
    employmentType: employmentType?.name,
    hiringOrganization,
    jobLocation,
    ...(highlights.remoteOption === JobRemoteOptionValue.FullRemote
      ? {
          jobLocationType: 'TELECOMMUTE',
          applicantLocationRequirements: {
            '@type': 'Country',
            name: location.country,
          },
        }
      : { jobLocationType: undefined }),
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {serialize(jobPostingSchema, { isJSON: true })}
      </script>
    </Helmet>
  );
};
