/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotBell = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotBell"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m54.138 44.769-2.142-21.401C51.08 14.229 43.39 7.27 34.206 7.27c-9.185 0-16.876 6.959-17.79 16.098l-2.143 21.401c-4.357.905-7.632 4.765-7.632 9.391H61.77c0-4.626-3.275-8.486-7.632-9.391"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M36.76 7.373H31.24V4.7a2.497 2.497 0 0 1 2.497-2.497h.524A2.497 2.497 0 0 1 36.76 4.7zM12.563 3.74l5.18 5.181-1.414 1.414-5.18-5.18zm-5.47 9.131 7.239 1.138-.31 1.976-7.239-1.138z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="M46.067 53.732c0 6.663-5.402 12.066-12.066 12.066S21.934 60.395 21.934 53.73z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="M34 6.27c9.698 0 17.818 7.348 18.786 16.998l2.072 20.699.093.026a10.6 10.6 0 0 1 7.61 9.882l.004.285v1H5.435v-1c0-4.757 3.16-8.866 7.614-10.167l.093-.026 2.074-20.698c.953-9.537 8.896-16.826 18.443-16.996zm0 2c-8.67 0-15.932 6.57-16.794 15.198l-2.017 20.131h25.876a1 1 0 0 1 .117 1.994l-.117.007-26.081-.001-.713.15a8.6 8.6 0 0 0-6.734 7.088l-.037.27-.006.053h53.012l-.044-.33a8.6 8.6 0 0 0-6.477-7.024l-.256-.058-.718-.15-2.216-22.13C49.932 14.84 42.671 8.27 34.001 8.27"
      />
    </svg>
  );
};
export default IllustrationSpotBell;
