import { useViewerData } from '@xing-com/hub';

type ThirdPartyContentConsentResult = {
  loading: boolean;
  hasConsent?: boolean;
};

export const useExternalContentConsent = (): ThirdPartyContentConsentResult => {
  const viewerData = useViewerData();

  if (viewerData.loading) return { loading: true };

  const isLoggedOut = viewerData.data?.loginState === 'LOGGED_OUT';
  const hasConsent =
    isLoggedOut || viewerData.data?.hasThirdPartyTrackingConsent;

  return {
    loading: false,
    hasConsent,
  };
};
