import { useInView } from 'react-intersection-observer';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import {
  TOP_BAR_HEIGHT_DESKTOP,
  TOP_BAR_HEIGHT_MOBILE,
} from '@xing-com/crate-jobs-constants';
import { mediaTopBarHidden } from '@xing-com/layout-tokens';

type UseIsJobDetailHeaderInViewResult = {
  ref: (node?: Element | null) => void;
  refElement?: HTMLElement | null;
  isInView: boolean;
  isTopbarHidden: boolean;
};

export const useIsJobDetailHeaderInView = (
  withTopBar = true
): UseIsJobDetailHeaderInViewResult => {
  const isTopbarHidden = useMatchMedia(mediaTopBarHidden);
  const { entry, ref, inView } = useInView({
    initialInView: true,
    rootMargin: withTopBar
      ? `-${isTopbarHidden ? TOP_BAR_HEIGHT_DESKTOP : TOP_BAR_HEIGHT_MOBILE}px`
      : undefined,
  });

  return {
    ref,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    refElement: entry?.target as HTMLElement,
    isInView: inView,
    isTopbarHidden,
  };
};
