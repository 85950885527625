import {
  JobTeaserCardSkeleton,
  JobTeaserListItemSkeleton,
} from '@xing-com/crate-jobs-xinglets';
import { GridItem } from '@xing-com/grid';
import { NotOnSmall, OnlyOnSmall } from '@xing-com/layout-primitives';

export const SimilarJobsLoading: React.FC<{ count: number }> = ({ count }) => {
  return Array.from({ length: count }).map((_, index) => (
    <GridItem key={index} span={12} spanLarge={4}>
      <OnlyOnSmall>
        <JobTeaserListItemSkeleton />
      </OnlyOnSmall>
      <NotOnSmall>
        <JobTeaserCardSkeleton />
      </NotOnSmall>
    </GridItem>
  ));
};
