import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useToggleFollowCompany } from '@xing-com/crate-jobs-hooks';
import { IconArrowRight } from '@xing-com/icons';
import { spaceM } from '@xing-com/tokens';

type EmployerActionsProps = {
  companyId: string;
  isFollowing: boolean;
  link?: string;
};

type FollowEvent = {
  event: 'EventFollow';
  EventFollow: 1;
  PropInteractionType: 'jobs_jdp_company_insights_follow';
};

type UnfollowEvent = {
  event: 'EventUnfollow';
  EventUnfollow: 1;
  PropInteractionType: 'jobs_jdp_company_insights_unfollow';
};

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${spaceM};
  align-items: flex-start;
`;

export const EmployerActions: React.FC<EmployerActionsProps> = ({
  companyId,
  link,
  isFollowing,
}) => {
  const { isLoggedOut } = useLoginState();
  const { onToggleFollow } = useToggleFollowCompany(companyId, isFollowing);
  const { track } = useTracking<FollowEvent | UnfollowEvent>();

  const handleToggleFollow = (event: MouseEvent): void => {
    event.stopPropagation();

    track({
      type: 'interaction',
      ...(isFollowing
        ? {
            event: 'EventUnfollow',
            PropInteractionType: 'jobs_jdp_company_insights_unfollow',
            EventUnfollow: 1,
          }
        : {
            event: 'EventFollow',
            PropInteractionType: 'jobs_jdp_company_insights_follow',
            EventFollow: 1,
          }),
    });
    onToggleFollow();
  };

  return (
    <ActionsWrapper>
      {!isLoggedOut ? (
        <Button
          variant="secondary"
          size="small"
          sizeWide="small"
          // @ts-expect-error FIXME: SC6
          onClick={handleToggleFollow}
        >
          <FormattedMessage
            id={
              isFollowing
                ? 'JOBS_JOB_DETAILS_UNFOLLOW_COMPANY'
                : 'JOBS_JOB_DETAILS_FOLLOW_COMPANY'
            }
          />
        </Button>
      ) : null}
      {link ? (
        <TextButton
          size="small"
          icon={IconArrowRight}
          onClick={(e) => {
            e.stopPropagation();
            window.location.assign(
              `${link}/jobs?sc_o=jobs_more_vacancies_link_click`
            );
          }}
          iconAfter
        >
          <FormattedMessage id="JOBS_JOB_DETAILS_COMPANY_ALL_JOBS" />
        </TextButton>
      ) : null}
    </ActionsWrapper>
  );
};
