import * as React from 'react';
import { useIntl } from 'react-intl';

import { IconCross } from '@xing-com/icons';

import * as Styled from './pop-up-window.styles';
import type { PopUpWindowPrimitiveProps } from './pop-up-window.types';

export const PopUpWindowPrimitive = React.forwardRef<
  HTMLDivElement,
  PopUpWindowPrimitiveProps
>(
  (
    { children, closeButtonProps, noPadding, hide, ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();

    return (
      <Styled.PopUpWindow
        $noPadding={noPadding}
        data-xds="PopUpWindow"
        ref={forwardedRef}
        {...props}
      >
        {children}
        {hide && (
          <Styled.CloseButton
            aria-label={intl.formatMessage({
              id: 'XDS_CLOSE_BUTTON_A11Y',
              defaultMessage: 'Close',
            })}
            data-dismiss="modal"
            icon={IconCross}
            onClick={hide}
            size="large"
            type="button"
            {...closeButtonProps}
          />
        )}
      </Styled.PopUpWindow>
    );
  }
);

PopUpWindowPrimitive.displayName = 'PopUpWindow (Primitive)';
