import { XingletWrapper } from './xinglet-helpers';

export type JobDetailsPushNotificationProps = {
  jobId: string;
};

export const JobDetailsPushNotification =
  XingletWrapper<JobDetailsPushNotificationProps>(
    '@xing-com/crate-notifications-job-details-push-notification'
  );
