import { useEffect, useState } from 'react';

import { useLocalStorage } from '@xing-com/crate-hooks-use-web-storage';
import { EXTERNAL_APPLY_FLOW_LOCALSTORAGE_KEY } from '@xing-com/crate-jobs-constants';

export function useIsExternalApplicationFlowEnabled(isPaidJob: boolean): {
  isExternalApplicationFlowEnabled: boolean;
  externalApplicationFlowTrackingProps: {
    PropContextAddition?: string;
  };
} {
  const [isRegisterFlowCompleted, setIsRegisterFlowCompleted] = useState(false);

  const [externalApplicationFlag] = useLocalStorage<{ completed: boolean }>(
    EXTERNAL_APPLY_FLOW_LOCALSTORAGE_KEY,
    { completed: false }
  );

  const isExternalApplicationFlowEnabled =
    !isPaidJob && !isRegisterFlowCompleted;

  const PropContextAddition = isExternalApplicationFlowEnabled
    ? 'jobs_registration_modal_show'
    : isPaidJob
      ? 'jobs_registration_modal_skip_paid'
      : 'jobs_registration_modal_skip_reg_ok';

  const externalApplicationFlowTrackingProps = { PropContextAddition };

  useEffect(() => {
    // the flag is stored on browser side so we update it after render
    setIsRegisterFlowCompleted(externalApplicationFlag.completed);
  }, [externalApplicationFlag.completed]);

  return {
    isExternalApplicationFlowEnabled,
    externalApplicationFlowTrackingProps,
  };
}
