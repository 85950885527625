import { useLazyQuery } from '@apollo/client';

import { GetJobApplicationTypeDocument } from '@xing-com/crate-jobs-graphql';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetJobApplicationType = () => {
  const [getJobApplicationType, { data, loading, called, error }] =
    useLazyQuery(GetJobApplicationTypeDocument, {
      fetchPolicy: 'no-cache',
    });

  const isVisibleJob = data?.job?.__typename === 'VisibleJob';
  const applicationType =
    data?.job?.__typename === 'VisibleJob'
      ? data?.job?.application?.__typename
      : undefined;

  const isLoApplyEnabled =
    data?.job?.__typename === 'VisibleJob' &&
    data?.job?.application?.__typename === 'JobXingApplication' &&
    data?.job?.application?.applyServiceSettings?.loggedoutApplicationsEnabled;

  const isPrioritized =
    data?.job?.__typename === 'VisibleJob' && data?.job?.prioritized;

  return {
    getJobApplicationType,
    data: { isVisibleJob, applicationType, isLoApplyEnabled, isPrioritized },
    isLoading: loading,
    isCalled: called,
    error,
  };
};
