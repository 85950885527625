/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconFacebook"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.322 8.434c-.252 0-.533.345-.533.808v1.604h2.661v2.287h-2.66V20h-2.512v-6.867H8v-2.287h2.278V9.5c0-1.93 1.283-3.5 3.044-3.5h2.128v2.434z"
    />
  </svg>
);
export default IconFacebook;
