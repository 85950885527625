import { NoSSR } from '@xing-com/crate-utils-no-ssr';

import { ExternalUrlConsentPlaceholder } from './external-url-consent-placeholder';
import { ExternalURLDescriptionIframe } from './external-url-description-iframe';

type Props = {
  url: string;
  proxyUrl: string;
  externalContentConsent: boolean;
};

export const ExternalUrlDescription: React.FC<Props> = ({
  url,
  proxyUrl,
  externalContentConsent,
}) =>
  externalContentConsent ? (
    <NoSSR>
      <ExternalURLDescriptionIframe proxyUrl={proxyUrl} />
    </NoSSR>
  ) : (
    <ExternalUrlConsentPlaceholder url={url} />
  );
