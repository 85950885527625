import type { CompanyMappedBenefitType } from '@xing-com/crate-common-graphql-types';
import type { JobDetailsVisibleJobFragment } from '@xing-com/crate-jobs-graphql';

import type { EmployerData } from './job-details-types';

export const getEmployerData = (
  company: JobDetailsVisibleJobFragment['companyInfo']['company'],
  companyName: string
): EmployerData | undefined => {
  const kununuData = company?.kununuData;
  return kununuData?.mappedBenefits ||
    kununuData?.culture ||
    (kununuData?.ratingCount ?? 0) > 0
    ? {
        kununu: {
          ratingAverage: kununuData?.ratingAverage ?? undefined,
          ratingCount: kununuData?.ratingCount ?? undefined,
          kununuLink: kununuData?.companyProfileUrl ?? undefined,
        },
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        benefits: (kununuData?.mappedBenefits?.map(
          (benefit) => benefit?.type
        ) ?? []) as CompanyMappedBenefitType[],
        culture: {
          compass: kununuData?.culture?.completedSubmissionsCount
            ? {
                profile: kununuData?.culture?.cultureCompass?.profile ?? 0,
                industry: kununuData?.culture?.cultureCompass?.industry ?? 0,
              }
            : undefined,
          link: kununuData?.culture?.link ?? undefined,
          companyName,
        },
      }
    : undefined;
};
