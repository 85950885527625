import { useMemo } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useLoginAppUrl } from '@xing-com/crate-xinglet';

export const useApplyButtonDefaultProps = (
  slug?: string
): Record<string, string | boolean> => {
  const { isLoggedIn } = useLoginState();
  const loginAppUrl = useLoginAppUrl();

  const loginRedirect = useMemo(
    () => `${loginAppUrl}?dest_url=${encodeURIComponent(`/jobs/${slug}`)}`,
    [loginAppUrl, slug]
  );

  return {
    'data-testid': 'apply-button',
    ...(!isLoggedIn && { to: loginRedirect }),
  };
};
