import styled, { css } from 'styled-components';

import { zIndexContentLayer2 } from '@xing-com/layout-tokens';
import {
  scale010,
  scale110,
  spaceM,
  xdlColorBackground,
  xdlColorBorder,
  xdlColorDisabledSoft,
  xdlColorSelected,
  xdlColorError,
  motionTimeM,
  motionEasingLeave,
} from '@xing-com/tokens';

type RadioButtonTransientProps = {
  $error?: boolean;
};

const checkedErrorStyle = css`
  background-color: ${xdlColorError};
  border-color: ${xdlColorError};
  border-style: solid;
  border-width: ${scale010};
`;

const unCheckedErrorStyle = css`
  border-color: ${xdlColorError};
  border-style: solid;
  border-width: ${scale010};
`;

// *** Components ***
export const RadioButton = styled.input<RadioButtonTransientProps>`
  appearance: none;
  background-color: ${xdlColorBackground};
  border-color: ${xdlColorBorder};
  border-radius: 50%;
  border-style: solid;
  border-width: ${scale010};
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: ${scale110};
  margin: 0;
  position: relative;
  width: ${scale110};

  ${(props) => css`
    ${props.$error && unCheckedErrorStyle};
  `};

  &:checked {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorSelected};

    ${(props) => css`
      ${props.$error && unCheckedErrorStyle};
    `};
  }

  &::before {
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 0;
    z-index: ${zIndexContentLayer2};
  }

  &:checked::before {
    background-color: ${xdlColorSelected};
    height: ${spaceM};
    margin-left: calc(${spaceM} / 2 * -1);
    margin-top: calc(${spaceM} / 2 * -1);
    transition-duration: ${motionTimeM};
    transition-property: margin, height, width;
    transition-timing-function: ${motionEasingLeave};
    width: ${spaceM};

    ${(props) => css`
      ${props.$error && checkedErrorStyle};
    `};
  }

  &[disabled] {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorDisabledSoft};
    cursor: default;
  }

  &[disabled]::before {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorDisabledSoft};
  }

  &[disabled]:checked {
    background-color: ${xdlColorBackground};
    border-color: ${xdlColorDisabledSoft};
  }

  &[disabled]:checked::before {
    background-color: ${xdlColorDisabledSoft};
  }
`;
