import type { ApolloCache } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {
  FollowCompanyDocument,
  UnfollowCompanyDocument,
} from '@xing-com/crate-jobs-graphql';

type UseToggleFollowCompanyResult = {
  readonly onToggleFollow: () => void;
};

const updateIsFollowing = (
  cache: ApolloCache<unknown>,
  companyId: string,
  isFollowing: boolean
): void => {
  cache.modify({
    id: cache.identify({ __typename: 'Company', id: companyId }),
    fields: {
      userContext(cachedUserContext) {
        return {
          ...cachedUserContext,
          followState: {
            isFollowing,
          },
        };
      },
    },
  });
};

export const useToggleFollowCompany = (
  companyId: string,
  currentIsFollowing: boolean
): UseToggleFollowCompanyResult => {
  const [onFollowCompany] = useMutation(FollowCompanyDocument, {
    variables: { input: { followedId: companyId } },
    optimisticResponse: {
      __typename: 'Mutation',
      companyFollow: { error: null, __typename: 'FollowersMutationResult' },
    },
    update: (cache) => updateIsFollowing(cache, companyId, true),
  });

  const [onUnfollowCompany] = useMutation(UnfollowCompanyDocument, {
    variables: { input: { followedId: companyId } },
    optimisticResponse: {
      __typename: 'Mutation',
      companyUnfollow: { error: null, __typename: 'FollowersMutationResult' },
    },
    update: (cache) => updateIsFollowing(cache, companyId, false),
  });

  const onToggleFollow = currentIsFollowing
    ? onUnfollowCompany
    : onFollowCompany;

  return { onToggleFollow };
};
