/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetJobContactsQueryVariables = Types.Exact<{
  jobId: Types.Scalars['ID']['input'];
  first: Types.Scalars['Int']['input'];
  consumer: Types.Scalars['String']['input'];
  sourceEntityId: Types.Scalars['SlugOrID']['input'];
}>;

export type GetJobContactsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    firstDegreeCurrentContacts?: {
      __typename?: 'JobDetailsConnection';
      total: number;
      edges: Array<{
        __typename?: 'JobDetailsEdge';
        node?: {
          __typename?: 'JobDetailsContact';
          position: number;
          user?: {
            __typename: 'XingId';
            id: string;
            firstName: string;
            lastName: string;
            gender?: Types.Gender | null;
            slug: string;
            profileImage?: Array<{
              __typename: 'ProfileImage';
              url: string;
            } | null> | null;
            occupations?: Array<{
              __typename: 'XingIdOccupation';
              headline: string;
              subline: string;
            } | null> | null;
            userFlags?: {
              __typename: 'UserFlags';
              displayFlag?: Types.UserFlagsDisplayFlag | null;
            } | null;
            clickReasonProfileUrlFromEntity?: {
              __typename?: 'ClickReason';
              code: string;
            } | null;
          } | null;
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
      };
    } | null;
    firstDegreeFormerContacts?: {
      __typename?: 'JobDetailsConnection';
      total: number;
      edges: Array<{
        __typename?: 'JobDetailsEdge';
        node?: {
          __typename?: 'JobDetailsContact';
          position: number;
          user?: {
            __typename: 'XingId';
            id: string;
            firstName: string;
            lastName: string;
            gender?: Types.Gender | null;
            slug: string;
            profileImage?: Array<{
              __typename: 'ProfileImage';
              url: string;
            } | null> | null;
            occupations?: Array<{
              __typename: 'XingIdOccupation';
              headline: string;
              subline: string;
            } | null> | null;
            userFlags?: {
              __typename: 'UserFlags';
              displayFlag?: Types.UserFlagsDisplayFlag | null;
            } | null;
            clickReasonProfileUrlFromEntity?: {
              __typename?: 'ClickReason';
              code: string;
            } | null;
          } | null;
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
      };
    } | null;
    secondDegreeContacts?: {
      __typename?: 'JobDetailsSecondDegreeConnection';
      total: number;
      edges: Array<{
        __typename?: 'JobDetailsSecondDegreeEdge';
        node?: {
          __typename?: 'JobDetailsSecondDegreeContact';
          position: number;
          connectedBy: Array<{
            __typename?: 'JobDetailsContact';
            user?: {
              __typename: 'XingId';
              id: string;
              firstName: string;
              lastName: string;
              gender?: Types.Gender | null;
              slug: string;
              profileImage?: Array<{
                __typename: 'ProfileImage';
                url: string;
              } | null> | null;
              occupations?: Array<{
                __typename: 'XingIdOccupation';
                headline: string;
                subline: string;
              } | null> | null;
              userFlags?: {
                __typename: 'UserFlags';
                displayFlag?: Types.UserFlagsDisplayFlag | null;
              } | null;
              clickReasonProfileUrlFromEntity?: {
                __typename?: 'ClickReason';
                code: string;
              } | null;
            } | null;
          }>;
          user?: {
            __typename: 'XingId';
            id: string;
            firstName: string;
            lastName: string;
            gender?: Types.Gender | null;
            slug: string;
            clickReasonProfileUrlFromEntity?: {
              __typename?: 'ClickReason';
              code: string;
            } | null;
            profileImage?: Array<{
              __typename: 'ProfileImage';
              url: string;
            } | null> | null;
            occupations?: Array<{
              __typename: 'XingIdOccupation';
              headline: string;
              subline: string;
            } | null> | null;
            userFlags?: {
              __typename: 'UserFlags';
              displayFlag?: Types.UserFlagsDisplayFlag | null;
            } | null;
          } | null;
        } | null;
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
      };
    } | null;
  } | null;
};

export const GetJobContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consumer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sourceEntityId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'firstDegreeCurrentContacts' },
                  name: {
                    kind: 'Name',
                    value: 'jobDetailsFirstDegreeContacts',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'postingId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'consumer' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'companyMembership' },
                      value: { kind: 'EnumValue', value: 'CURRENT' },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'connection' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'key' },
                          value: {
                            kind: 'StringValue',
                            value: 'firstDegreeCurrentContacts',
                            block: false,
                          },
                        },
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'filter' },
                          value: {
                            kind: 'ListValue',
                            values: [
                              {
                                kind: 'StringValue',
                                value: 'postingId',
                                block: false,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'FirstDegreeConnectionWithClickReason',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'firstDegreeFormerContacts' },
                  name: {
                    kind: 'Name',
                    value: 'jobDetailsFirstDegreeContacts',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'postingId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'consumer' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'companyMembership' },
                      value: { kind: 'EnumValue', value: 'PREVIOUS' },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'connection' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'key' },
                          value: {
                            kind: 'StringValue',
                            value: 'firstDegreeFormerContacts',
                            block: false,
                          },
                        },
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'filter' },
                          value: {
                            kind: 'ListValue',
                            values: [
                              {
                                kind: 'StringValue',
                                value: 'postingId',
                                block: false,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'FirstDegreeConnectionWithClickReason',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'secondDegreeContacts' },
                  name: {
                    kind: 'Name',
                    value: 'jobDetailsSecondDegreeContacts',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'postingId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'consumer' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'first' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'connection' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'key' },
                          value: {
                            kind: 'StringValue',
                            value: 'secondDegreeContacts',
                            block: false,
                          },
                        },
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'filter' },
                          value: {
                            kind: 'ListValue',
                            values: [
                              {
                                kind: 'StringValue',
                                value: 'postingId',
                                block: false,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SecondDegreeConnectionWithClickReason',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'XingId' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'slug' },
            name: { kind: 'Name', value: 'pageName' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'EnumValue', value: 'SQUARE_96' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'occupations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subline' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayFlag' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClickReasonContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'XingId' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clickReasonProfileUrlFromEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clickReasonId' },
                value: {
                  kind: 'EnumValue',
                  value: 'CR_WEB_JOBS_COMPANY_CONTACTS_CONTACT',
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceEntityId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sourceEntityId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FirstDegreeConnectionWithClickReason' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetailsConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'position' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Contact' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ClickReasonContact',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SecondDegreeConnectionWithClickReason' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetailsSecondDegreeConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'position' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'connectedBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Contact' },
                                  },
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ClickReasonContact',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'Contact' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'clickReasonProfileUrlFromEntity',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'clickReasonId',
                                  },
                                  value: {
                                    kind: 'EnumValue',
                                    value:
                                      'CR_WEB_JOBS_COMPANY_CONTACTS_COMMON_CONTACT',
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'sourceEntityId',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'sourceEntityId',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetJobContactsQuery, GetJobContactsQueryVariables>;
