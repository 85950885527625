/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconYoutube"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m21.558 7.238.025.155a2.46 2.46 0 0 0-1.724-1.657l-.017-.004c-1.558-.398-7.833-.398-7.833-.398s-6.258-.009-7.833.398c-.845.23-1.499.853-1.736 1.644l-.004.017c-.583 2.863-.587 6.304.026 9.37l-.025-.157a2.46 2.46 0 0 0 1.722 1.657l.018.004c1.558.4 7.833.4 7.833.4s6.258 0 7.833-.4c.846-.23 1.499-.853 1.736-1.644l.005-.017c.265-1.331.416-2.864.416-4.429v-.353c0-1.567-.151-3.099-.442-4.586m-11.55 7.625v-5.72l5.22 2.864z"
    />
  </svg>
);
export default IconYoutube;
