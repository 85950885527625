import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXL, xdlColorBackground } from '@xing-com/tokens';

import { Confirmation } from './confirmation';

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${xdlColorBackground};
  margin: 0 auto ${spaceXL} auto;
`;

// Define the order of the Apply Banner in the header.
export const StyledConfirmation = styled(Confirmation)`
  order: -1;

  @media ${mediaConfined} {
    order: 0;
    margin-top: 0;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
