export type UserJobPreferencesData = {
  minCareerLevelId?: number;
  maxCareerLevelId?: number;
  minSalary?: number;
  partTime: boolean;
  fullTime: boolean;
  preferredCityIds: Array<number>;
  preferredIndustryIds: Array<number>;
  preferredDisciplineIds: Array<number>;
  preferredCompanyIds: Array<number>;
};

export enum Preference {
  CAREER_LEVEL,
  SALARY,
  LOCATION,
  WORKING_HOURS,
  INDUSTRY,
  DISCIPLINE,
  PREFERRED_EMPLOYER,
}
