import { useParams } from '@reach/router';

import { UnauthorizedJobReason } from '@xing-com/crate-common-graphql-types';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { JobNotAvailable } from '@xing-com/crate-jobs-domain-job-details-components-error';
import { selectJobDetails } from '@xing-com/crate-jobs-helpers';
import {
  useJobDetails,
  useJobMatchingSkills,
  useUserJobPreferences,
} from '@xing-com/crate-jobs-hooks';
import { useHost } from '@xing-com/crate-xinglet';

import { JobDetailsContent } from './job-details-content';
import { JobDetailsLoading } from './job-details-loading';

export const JobDetails: React.FC = () => {
  const host = useHost();
  const { slug } = useParams<{ slug: string }>();
  const { job, error, isLoading: jobLoading } = useJobDetails(slug);
  const { isLoggedIn } = useLoginState();

  const displaySkillsAndPreferences = isLoggedIn && job?.paid;
  const { loading: userJobPreferencesLoading, data: userJobPreferences } =
    useUserJobPreferences({
      skip: !displaySkillsAndPreferences,
    });
  const {
    loading: matchingSkillsLoading,
    matchedSkills,
    notMatchedSkills,
  } = useJobMatchingSkills({ slug, skip: !displaySkillsAndPreferences });

  // Show the loading state while job, user preferences and skills are loading
  if (jobLoading || userJobPreferencesLoading || matchingSkillsLoading) {
    return <JobDetailsLoading />;
  }

  if (!job) {
    if (error === UnauthorizedJobReason.Private && !isLoggedIn) {
      return host.redirectToLogin();
    } else {
      return <JobNotAvailable />;
    }
  }

  const jobDetails = selectJobDetails(job);

  return (
    <JobDetailsContent
      jobDetails={jobDetails}
      userJobPreferences={userJobPreferences}
      matchedSkills={matchedSkills}
      notMatchedSkills={notMatchedSkills}
    />
  );
};
