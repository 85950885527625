import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { spaceL, spaceXL } from '@xing-com/tokens';
import type { HeadlineProps } from '@xing-com/typography';
import { Headline } from '@xing-com/typography';

type Props = {
  titleKey: string;
  size?: HeadlineProps['size'];
  noMargin?: boolean;
  className?: string;
};

const StyledHeadline = styled(Headline)<{
  noMargin: boolean;
}>`
  margin-bottom: ${({ noMargin, size }) =>
    noMargin ? 0 : size === 'xxlarge' ? spaceXL : spaceL};
`;

export const ModuleTitle: React.FC<Props> = ({
  titleKey,
  size = 'xxlarge',
  noMargin,
  className,
}) => (
  // @ts-expect-error FIXME: SC6
  <StyledHeadline size={size} noMargin={noMargin} className={className ?? ''}>
    <FormattedMessage id={titleKey} />
  </StyledHeadline>
);
