import type React from 'react';

import type { CompanyData } from '@xing-com/crate-jobs-helpers';
import { useHRContact } from '@xing-com/crate-jobs-hooks';
import { cornerRadiusL } from '@xing-com/tokens';

import { ModuleTitle } from '../module-title';
import {
  CompanyModuleWrapper,
  StyledCompanyCard,
  StyledCompanyCardSkeleton,
  StyledHRContactCard,
  StyledHRContactCardSkeleton,
} from './company-module.styles';

const moduleHeight = '271px';

type CompanyModuleProps = {
  jobSlug: string;
  company?: CompanyData;
};

export const CompanyModule: React.FC<CompanyModuleProps> = ({
  jobSlug,
  company,
}) => {
  const { isLoading, hrContact } = useHRContact(jobSlug, company?.id);
  if (!company) return null;

  return (
    <>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_ABOUT_COMPANY_HEADER" />
      <CompanyModuleWrapper>
        {isLoading ? (
          <StyledCompanyCardSkeleton
            height={moduleHeight}
            cornerRadius={cornerRadiusL}
          />
        ) : (
          <StyledCompanyCard company={company} isAlwaysVertical={!!hrContact} />
        )}
        {isLoading ? (
          <StyledHRContactCardSkeleton
            height={moduleHeight}
            cornerRadius={cornerRadiusL}
          />
        ) : hrContact ? (
          <StyledHRContactCard hiringContact={hrContact} />
        ) : null}
      </CompanyModuleWrapper>
    </>
  );
};
