/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconPinterest"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.397 2C6.915 2 4 5.512 4 9.342c0 1.777.992 3.992 2.582 4.695.241.108.372.062.425-.161.048-.17.257-.99.359-1.375a.35.35 0 0 0-.086-.348c-.528-.61-.946-1.721-.946-2.764 0-2.672 2.123-5.265 5.738-5.265 3.125 0 5.311 2.03 5.311 4.933 0 3.282-1.736 5.552-3.992 5.552-1.25 0-2.18-.98-1.884-2.192.355-1.445 1.055-2.997 1.055-4.038 0-.933-.528-1.707-1.605-1.707-1.272 0-2.303 1.259-2.303 2.95 0 1.073.38 1.799.38 1.799S7.775 16.5 7.542 17.449c-.395 1.607.054 4.208.092 4.432.024.124.163.162.24.061.124-.162 1.644-2.331 2.07-3.9.155-.57.792-2.886.792-2.886.418.756 1.627 1.39 2.915 1.39 3.83 0 6.597-3.367 6.597-7.544C20.236 4.997 16.808 2 12.397 2"
    />
  </svg>
);
export default IconPinterest;
