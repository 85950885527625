import { FormattedMessage } from 'react-intl';

import type { BannerState } from './banner-context';
import * as S from './banner.styles';
import { BANNER_TIMEOUT } from './constants';

type ErrorBannerProps = {
  errorState: BannerState;
  handleOnClose?: () => void;
};

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  errorState,
  handleOnClose,
}) => {
  const { visible, message } = errorState;
  return message ? (
    <S.StatusBannerAutoHide
      display="fixed"
      show={visible}
      variant="error"
      timeout={BANNER_TIMEOUT}
      handleOnClose={handleOnClose}
    >
      <FormattedMessage
        id={message}
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
        }}
        tagName="span"
      />
    </S.StatusBannerAutoHide>
  ) : null;
};
