import { useCallback } from 'react';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { ApplicationType } from '@xing-com/crate-jobs-helpers';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

import { applicationTypeNwtContext } from '../../../tracking';

const trackActionByType: Record<ApplicationType, string> = {
  EmailApplication: 'jobs_click_application_form_email',
  JobXingApplication: 'jobs_apply_start',
  MessageApplication: 'jobs_click_application_form_xing_message',
  UrlApplication: 'jobs_click_application_form_url',
  EasyApplication: '',
};

type UseTrackApplyIntentionArgs = {
  applicationType: ApplicationType;
  jobId: string;
};
type TrackApplyIntentionCallback = (
  adobeProps?: Record<string, string>
) => void;

type AdobeTrackingProps = {
  PropTrackAction: string;
  PropJobsApplyId: string;
};

export const useTrackApplyIntention = ({
  applicationType,
  jobId,
}: UseTrackApplyIntentionArgs): TrackApplyIntentionCallback => {
  const trackNwt = useJobsNwtTracking();
  const { track: trackAdobeEvent } =
    useTracking<InteractionTrackingEvent<AdobeTrackingProps>>();

  return useCallback(
    (adobeProps = {}) => {
      if (applicationTypeNwtContext[applicationType]) {
        trackNwt({
          eventSchema: 'basic',
          event: 'intended',
          intention: 'apply',
          page: 'jobs/detail',
          itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
          element: 'apply_button',
          context: applicationTypeNwtContext[applicationType],
        });
      }

      if (trackActionByType[applicationType]) {
        trackAdobeEvent({
          type: 'interaction',
          event: 'PropTrackAction',
          PropTrackAction: trackActionByType[applicationType],
          PropJobsApplyId: crypto.randomUUID(),
          ...adobeProps,
        });
      }
    },
    [applicationType, jobId, trackAdobeEvent, trackNwt]
  );
};
