import { useQuery } from '@apollo/client';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { GetJobManagerUrlDocument } from '@xing-com/crate-jobs-graphql';

type JobDetailsResponse = {
  isLoading: boolean;
  jobManagerUrl?: string;
};

export const useJobManagerUrl = (jobId: number): JobDetailsResponse => {
  const { isLoggedOut } = useLoginState();
  const { loading, data } = useQuery(GetJobManagerUrlDocument, {
    variables: { jobId },
    errorPolicy: 'all',
    skip: isLoggedOut,
  });

  const jobManagerUrl = data?.viewer?.xjmJob?.jobManagerUrl ?? undefined;

  return { isLoading: loading, jobManagerUrl };
};
