import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { mediaConfined, mediaXWide } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import {
  scale020,
  scale110,
  scale400,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXXL,
  xdlPaletteGrayPlain04,
  xdlPaletteMint07,
} from '@xing-com/tokens';
import { BodyCopy, Meta } from '@xing-com/typography';

export const InfoButton = styled(TextButton)<{ $hasMedian: boolean }>`
  margin-top: ${spaceL};
  margin-bottom: ${({ $hasMedian }) => ($hasMedian ? spaceL : spaceXXL)};
  height: auto;
  justify-content: flex-start;
`;

export const InfoCopy = styled(BodyCopy)`
  margin-bottom: 0;
`;

export const InfoPopoverCopy = styled(BodyCopy)`
  margin-bottom: 0;
  padding: ${spaceXL};

  @media ${mediaConfined} {
    padding: 0;
  }
`;

export const InfoPopover = styled(Menu)`
  @media ${mediaConfined} {
    width: ${scale400};
  }
`;

export const BarColorLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${spaceL};
  gap: ${spaceM};

  @media ${mediaXWide} {
    flex-direction: row;
  }
`;

export const BarColorLegend = styled.div`
  @media ${mediaXWide} {
    &:first-child {
      margin-right: ${spaceXL};
    }
  }
`;

export const BarColorLegendSample = styled.div<{ $isAverage?: boolean }>`
  display: inline-block;
  width: ${scale110};
  padding-bottom: ${spaceS};
  margin-right: ${spaceS};
  border-radius: ${scale020};
  background-color: ${({ $isAverage }) =>
    $isAverage ? xdlPaletteMint07 : xdlPaletteGrayPlain04};
`;

export const BarColorLegendText = styled(Meta)`
  display: inline;
`;
