import type { ApplicationType } from '@xing-com/crate-jobs-helpers';

type UseApplicationTypeResult = {
  isXingApplication: boolean;
  isUrlApplication: boolean;
  isEmailApplication: boolean;
  isMessageApplication: boolean;
};

export const useApplicationTypeHelpers = (
  applicationType?: ApplicationType
): UseApplicationTypeResult => {
  const isXingApplication = applicationType === 'JobXingApplication';
  const isUrlApplication = applicationType === 'UrlApplication';
  const isEmailApplication = applicationType === 'EmailApplication';
  const isMessageApplication = applicationType === 'MessageApplication';

  return {
    isXingApplication,
    isUrlApplication,
    isEmailApplication,
    isMessageApplication,
  };
};
