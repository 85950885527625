import type { JobSalary } from '@xing-com/crate-common-graphql-types';

import type {
  CareerLevelData,
  CompanyData,
  DisciplineData,
  EmploymentTypeData,
  IndustryData,
  JobDetails,
  JobLocationData,
} from './job-details-types';
import {
  isValidSalary,
  isValidSalaryEstimate,
  isValidSalaryRange,
} from './salary';
import type { UserJobPreferencesData } from './user-job-preferences-types';
import { Preference } from './user-job-preferences-types';

const matchCareerLevel = (
  careerLevel: CareerLevelData,
  userJobPreferences: UserJobPreferencesData
): boolean => {
  const { minCareerLevelId, maxCareerLevelId } = userJobPreferences;

  if (!minCareerLevelId && !maxCareerLevelId) return false;

  const isAboveMin = minCareerLevelId
    ? careerLevel.id >= minCareerLevelId
    : true;
  const isBelowMax = maxCareerLevelId
    ? careerLevel.id <= maxCareerLevelId
    : true;

  return isAboveMin && isBelowMax;
};

const matchSalary = (
  salary: JobSalary,
  userJobPreferences: UserJobPreferencesData
): number | null => {
  if (!userJobPreferences.minSalary) return null;

  if (isValidSalary(salary) && salary.amount >= userJobPreferences.minSalary)
    return salary.amount;
  if (
    (isValidSalaryRange(salary) || isValidSalaryEstimate(salary)) &&
    userJobPreferences.minSalary < salary.maximum
  )
    return salary.minimum;

  return null;
};

const matchLocation = (
  location: JobLocationData,
  userJobPreferences: UserJobPreferencesData
): boolean =>
  !!location.cityId &&
  userJobPreferences.preferredCityIds.some(
    (cityId) => location.cityId === cityId
  );

const matchWorkingHours = (
  employmentType: EmploymentTypeData,
  userJobPreferences: UserJobPreferencesData
): boolean =>
  (employmentType.id.startsWith('FULL_TIME') && userJobPreferences.fullTime) ||
  (employmentType.id.startsWith('PART_TIME') && userJobPreferences.partTime);

const matchIndustry = (
  industry: IndustryData,
  userJobPreferences: UserJobPreferencesData
): boolean => userJobPreferences.preferredIndustryIds.includes(industry.id);

const matchDiscipline = (
  discipline: DisciplineData,
  userJobPreferences: UserJobPreferencesData
): boolean => userJobPreferences.preferredDisciplineIds.includes(discipline.id);

const matchPreferredEmployer = (
  company: CompanyData,
  userJobPreferences: UserJobPreferencesData
): boolean =>
  userJobPreferences.preferredCompanyIds.includes(Number(company.id));

export const matchJobWithPreferences = (
  job: JobDetails,
  userJobPreferences: UserJobPreferencesData
): Preference[] => {
  const matchingPreferences: Preference[] = [];

  // 1. Match career level
  if (
    !!job.careerLevel &&
    matchCareerLevel(job.careerLevel, userJobPreferences)
  )
    matchingPreferences.push(Preference.CAREER_LEVEL);

  // 2. Match salary
  if (job.salary) {
    const matchingSalary = matchSalary(job.salary, userJobPreferences);
    if (matchingSalary) {
      matchingPreferences.push(Preference.SALARY);
    }
  }

  // 3. Match location
  if (job.location.city && matchLocation(job.location, userJobPreferences)) {
    matchingPreferences.push(Preference.LOCATION);
  }

  // 4. Match working hours
  if (
    job.facts.employmentType &&
    matchWorkingHours(job.facts.employmentType, userJobPreferences)
  ) {
    matchingPreferences.push(Preference.WORKING_HOURS);
  }

  // 5. Match industry
  if (job.industry && matchIndustry(job.industry, userJobPreferences)) {
    matchingPreferences.push(Preference.INDUSTRY);
  }

  // 6. Match discipline
  if (job.discipline && matchDiscipline(job.discipline, userJobPreferences)) {
    matchingPreferences.push(Preference.DISCIPLINE);
  }

  // 7. Match preferred employer
  if (job.company && matchPreferredEmployer(job.company, userJobPreferences)) {
    matchingPreferences.push(Preference.PREFERRED_EMPLOYER);
  }

  return matchingPreferences.slice(0, 5);
};
