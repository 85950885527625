import type { JobSalary } from '@xing-com/crate-common-graphql-types';

enum SalaryTypeContextDimension {
  Salary = 'jobs_salary',
  SalaryRange = 'jobs_salary_range',
  SalaryEstimate = 'jobs_salary_estimate',
  NoSalary = 'jobs_no_salary',
  SalaryUnauthorized = '',
}

export const getSalaryTypeContextDimension = (
  salary?: JobSalary | null
): SalaryTypeContextDimension => {
  const salaryTypeName = salary?.__typename;

  if (
    salaryTypeName === 'SalaryUnauthorized' ||
    !salaryTypeName ||
    SalaryTypeContextDimension[salaryTypeName] === undefined
  )
    return SalaryTypeContextDimension.NoSalary;

  return SalaryTypeContextDimension[salaryTypeName];
};
