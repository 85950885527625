import React, { useContext } from 'react';

import { ShareTabOmView } from '@xing-com/crate-social-share';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';

type Props = {
  globalId: string;
  url: string;
};

type ShareContextValue = {
  triggerShare: () => void;
};

const ShareContext = React.createContext<ShareContextValue>({
  triggerShare: () => ({}),
});

export const ShareJobProvider: React.FC<React.PropsWithChildren<Props>> = ({
  globalId,
  url,
  children,
}) => {
  return (
    <NoSSR fallback={children}>
      <ShareTabOmView
        trigger={(triggerShare) => (
          <ShareContext.Provider value={{ triggerShare }}>
            {children}
          </ShareContext.Provider>
        )}
        url={url}
        propChannel="wbm/propTracking"
        entryPoint="jobs_share_xingstatus"
        interactionTargetUrn={globalId}
        verb="SHARE"
      />
    </NoSSR>
  );
};

export const useShareJob = (): ShareContextValue => {
  const context = useContext(ShareContext);
  if (!context.triggerShare) {
    throw new Error('useShareJob must be used within a ShareJobProvider');
  }
  return context;
};
