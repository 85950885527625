import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { xdlColorSuccess } from '@xing-com/tokens';

import {
  BANNER_TIMEOUT,
  SHARE_FEEDBACK_URL_TRACKING,
} from '../../../constants';
import { getSuccessFeedbackConfig } from '../../../utils';
import { Styled } from './share-success-banner.styles';

export type ShareSuccessBannerType = {
  redirectUrl: string;
  show: boolean;
  targetType: string;
  setBannerProps: (newBannerProps: {
    show: boolean;
    redirectUrl: string;
    targetType: string;
  }) => void;
};

export const ShareSuccessBanner: React.FC<ShareSuccessBannerType> = ({
  redirectUrl,
  show,
  targetType,
  setBannerProps,
}) => {
  if (!show) return null;

  const { message, button } = getSuccessFeedbackConfig({
    targetType,
  });

  return (
    // @ts-expect-error FIXME: SC6
    <Styled.SuccessBannerAutoHide
      {...{ timeout: BANNER_TIMEOUT, show, handleOnClose: setBannerProps }}
    >
      <Styled.MessageWrapper>
        <div>
          <Styled.TickIcon height={24} width={24} color={xdlColorSuccess} />
          <Styled.Message size="small" noMargin>
            <FormattedMessage
              values={{
                item: <FormattedMessage id="SHARE_FEEDBACK_POST_LABEL" />,
              }}
              id={message}
            />
          </Styled.Message>
        </div>
        <Styled.ViewPostButton
          to={redirectUrl + SHARE_FEEDBACK_URL_TRACKING}
          size="small"
          variant="secondary"
        >
          <FormattedMessage id={button} />
        </Styled.ViewPostButton>
      </Styled.MessageWrapper>
    </Styled.SuccessBannerAutoHide>
  );
};
