import type React from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { EmptyStateLayout } from '@xing-com/crate-components-empty-state';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { useGoneStatusCode } from '@xing-com/crate-xinglet';
import { IllustrationEmptyStateSuitcase } from '@xing-com/illustrations';

export const JobNotAvailable: React.FC = () => {
  const { formatMessage } = useIntl();
  const { track } = useTracking();
  const trackNwt = useJobsNwtTracking();

  // Set the status code to 410
  useGoneStatusCode();

  useEffect(() => {
    track({
      type: 'pageview',
      page: 'jobs/empty_state_job_expired',
    });

    trackNwt({
      eventSchema: 'basic',
      event: 'viewed_screen',
      page: 'jobs/empty_state_job_expired',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmptyStateLayout
      headline={formatMessage({
        id: 'JOBS_JOB_NOT_AVAILABLE_HEADLINE',
      })}
      subline={formatMessage({ id: 'JOBS_JOB_NOT_AVAILABLE_SUBLINE' })}
      image={<IllustrationEmptyStateSuitcase />}
      button={
        <Button variant="tertiary" size="medium" to={ROUTES.search}>
          <FormattedMessage id="JOBS_JOB_NOT_AVAILABLE_CTA" />
        </Button>
      }
    />
  );
};
