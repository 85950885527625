import { formatStoryType } from '@xing-com/social-utils';

export type GetPropInteractionTypeType = (
  channel: string,
  urn: string,
  sourceType?: string
) => string;

export const getPropInteractionType: GetPropInteractionTypeType = (
  channel,
  urn,
  sourceType = ''
) => {
  const displaySourceType = sourceType ? `|${sourceType}` : '';
  return `social_share|${channel}|${formatStoryType(urn) + displaySourceType}`;
};
