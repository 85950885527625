import styled, { css } from 'styled-components';

import { Observer } from '@xing-com/crate-communication-in-view';
import { TopBar as TobBarComponent } from '@xing-com/crate-layout-top-bar';
import { GridContainer, GridItem } from '@xing-com/grid';
import {
  mediaConfinedNavlessOnly,
  mediaNavExtended,
  mediaSmallOrTiny,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { space3XL, space6XL, spaceXXL } from '@xing-com/tokens';

export const StyledObserver = styled(Observer)`
  width: 100%;
`;

const DEFAULT_SCROLL_MOBILE_HEIGHT = '800px'; // Min height to let the user scroll the full JDP header, description headline and a small part of the description on small devices

export const Container = styled.div<{ $isScrollFixed?: boolean }>`
  max-width: 944px;
  margin: 0 auto;

  ${({ $isScrollFixed }) =>
    $isScrollFixed &&
    css`
      overflow: clip;
      height: ${DEFAULT_SCROLL_MOBILE_HEIGHT};
      position: relative;

      @media ${mediaSmallOrTiny} {
        min-height: 100vh;
      }

      @media ${mediaConfinedNavlessOnly} {
        // bottom menu + mobile nav top bar + footer
        min-height: calc(100vh - 114px);
      }

      @media ${mediaWideNavless} {
        // side thin menu + footer
        height: 950px;
      }

      @media ${mediaNavExtended} {
        // side menu + footer
        height: 850px;
      }
    `};
`;

export const TopBar = styled(TobBarComponent)`
  @media print {
    display: none;
  }
`;

export const JobDetailsGridContainer = styled(GridContainer).attrs({
  variant: 'FullSize',
})`
  row-gap: ${space3XL};
  padding: 0;
  margin-bottom: ${space6XL};

  @media print {
    margin-bottom: 0;
  }
`;

export const SimilarJobsSidebarGridItem = styled(GridItem).attrs({
  spanLarge: 4,
})`
  display: none;
  margin-left: ${spaceXXL};

  @media ${mediaWideNavless} {
    display: block;
  }
`;
