import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceS } from '@xing-com/tokens';

export const ShowMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
`;

export const ExpandableContent = styled.div<{
  $isExpanded: boolean;
  $previewHeight: number;
  $hideOnDesktop?: boolean;
}>`
  width: 100%;
  height: ${({ $isExpanded, $previewHeight }) =>
    $isExpanded ? 'auto' : $previewHeight + 'px'};
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'initial' : 'hidden')};

  @media print {
    height: auto;
    overflow: initial;
  }

  ${({ $hideOnDesktop }) =>
    $hideOnDesktop &&
    css`
      @media ${mediaConfined} {
        height: auto;
        overflow: initial;
      }
    `}
`;

export const ToggleButton = styled(TextButton)<{
  $hideOnDesktop?: boolean;
  $centered?: boolean;
  $size?: 'small' | 'medium';
}>`
  margin-top: ${spaceL};

  @media print {
    display: none;
  }

  ${({ $size }) =>
    $size === 'small' &&
    css`
      margin-top: ${spaceS};
    `}

  ${({ $centered }) =>
    $centered &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

  ${({ $hideOnDesktop }) =>
    $hideOnDesktop &&
    css`
      @media ${mediaConfined} {
        display: none;
      }
    `}
`;
