import styled from 'styled-components';

import type { CompanyData } from '@xing-com/crate-jobs-helpers';
import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { xdlColorTextSecondary } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import { CompanyInfo } from '../../company-info';

const Industry = styled(BodyCopy)`
  ${lineClamp(1)}
  color: ${xdlColorTextSecondary};
`;

type Props = {
  company?: CompanyData;
  industry?: string;
};

const trackingParam = 'sc_o=jobs_header_company_logo_name_click';

export const JobIntro: React.FC<Props> = ({ company, industry }) => {
  if (!company) {
    return null;
  }

  return (
    <CompanyInfo
      data-testid="job-details-job-intro"
      trackingParams={trackingParam}
      {...company}
    >
      <Industry noMargin>{industry}</Industry>
    </CompanyInfo>
  );
};
