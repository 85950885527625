import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { GetJobContactsDocument } from '@xing-com/crate-jobs-graphql';
import type {
  Connections,
  ConnectionsEdges,
} from '@xing-com/crate-jobs-helpers';
import {
  FIRST_DEGREE_CURRENT,
  FIRST_DEGREE_FORMER,
  SECOND_DEGREE,
  decorateContact,
  type ConnectionType,
  type Contact,
  type ExtendedContact,
} from '@xing-com/crate-jobs-helpers';
import { useLoginState } from '@xing-com/hub';

const CONTACTS_PER_TYPE = 3;
const MAX_CONTACTS = 3;
const PRIORITIES: ConnectionType[] = [
  FIRST_DEGREE_CURRENT,
  FIRST_DEGREE_CURRENT,
  FIRST_DEGREE_FORMER,
  FIRST_DEGREE_CURRENT,
  FIRST_DEGREE_FORMER,
  FIRST_DEGREE_FORMER,
  SECOND_DEGREE,
];

type JobContactsOptions = {
  jobId: string;
  consumer: string;
  sourceEntityId?: string;
};

export type JobContactsResponse = {
  loading: boolean;
  error?: ApolloError;
  contacts: Array<ExtendedContact>;
  hasMoreContacts: boolean;
};

const normalizeConnections = (
  connections: Connections
): ConnectionsEdges | undefined =>
  connections && Object.keys(connections).length > 0
    ? {
        firstDegreeCurrentContacts: [
          ...(connections.firstDegreeCurrentContacts?.edges ?? []),
        ],
        firstDegreeFormerContacts: [
          ...(connections.firstDegreeFormerContacts?.edges ?? []),
        ],
        secondDegreeContacts: [
          ...(connections.secondDegreeContacts?.edges ?? []),
        ],
      }
    : undefined;

// Checks if there's enough space to render the contact. This basically checks
// if the contact is a second degree contact, in which case it can only appear
// in first or second position because it takes up two slots (one for the 2nd
// degree contact and one for the 1st degree contact through which the user is
// connected).
const hasEnoughSpaceForContact = (
  selectedContacts: ExtendedContact[],
  criteria: ConnectionType
): boolean =>
  selectedContacts.length < MAX_CONTACTS - 1 || criteria !== SECOND_DEGREE;

const filterContacts = (connections: Connections): ExtendedContact[] => {
  const availableConnections = normalizeConnections(connections);
  const selectedContacts: ExtendedContact[] = [];

  PRIORITIES.forEach((criteria) => {
    if (selectedContacts.length < MAX_CONTACTS) {
      if (availableConnections && availableConnections[criteria].length > 0) {
        const connection = availableConnections?.[criteria]?.shift();
        if (
          hasEnoughSpaceForContact(selectedContacts, criteria) &&
          connection?.node?.user
        ) {
          selectedContacts.push(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            decorateContact(criteria, connection.node as Contact)
          );
        }
      }
    }
  });

  return selectedContacts;
};

const totalContacts = (contacts: Connections): number =>
  Object.values(contacts).reduce(
    (acc, connection) => acc + (connection?.total ?? 0),
    0
  );

export const useJobContacts = ({
  jobId,
  consumer,
  sourceEntityId,
}: JobContactsOptions): JobContactsResponse => {
  const loginState = useLoginState();
  const { loading, data, error } = useQuery(GetJobContactsDocument, {
    variables: {
      jobId,
      first: CONTACTS_PER_TYPE,
      consumer,
      sourceEntityId: sourceEntityId || '', // can be an empty string because the query is skipped if undefined
    },
    errorPolicy: 'all',
    skip: !sourceEntityId || loginState === 'LOGGED_OUT',
  });

  if (error)
    return {
      loading: false,
      error,
      contacts: [],
      hasMoreContacts: false,
    };
  if (loading)
    return {
      loading: true,
      error,
      contacts: [],
      hasMoreContacts: false,
    };

  const contacts = data?.viewer ?? {};
  const filteredContacts = filterContacts(contacts);

  return {
    loading,
    error,
    contacts: filteredContacts,
    hasMoreContacts: totalContacts(contacts) > filteredContacts.length,
  };
};
