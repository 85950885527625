import type React from 'react';
import styled, { css } from 'styled-components';

import {
  LogoKununuLogoBlack,
  LogoKununuLogoWhite,
} from '@xing-com/illustrations';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXXL, spaceS } from '@xing-com/tokens';

// as defined by the design team
const LOGO_SIZE_MOBILE = 69.62;
const LOGO_SIZE_DESKTOP = 78.82;

const logoStyle = css`
  aspect-ratio: 35 / 8; /* to prevent layout shift */
  margin-bottom: ${spaceXXL};
  width: ${LOGO_SIZE_MOBILE}px;

  @media ${mediaConfined} {
    width: ${LOGO_SIZE_DESKTOP}px;
    margin-bottom: ${spaceS};
  }
`;

const LogoLight = styled(LogoKununuLogoBlack)`
  ${logoStyle};

  @media (prefers-color-scheme: dark) {
    display: none;
  }
`;

const LogoDark = styled(LogoKununuLogoWhite)`
  ${logoStyle};

  @media (prefers-color-scheme: light) {
    display: none;
  }
`;

export const KununuLogo: React.FC = () => (
  <>
    <LogoDark />
    <LogoLight />
  </>
);
