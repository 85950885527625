import type React from 'react';

import {
  BarHeadline,
  ForecastBar,
  ForecastContainer,
  LegendContainer,
  LegendCopy,
  RangeBar,
} from './salary-forecast-bar.styles';

type Props = {
  headlineCopy?: string;
  isFullRange?: boolean;
  legend?: [left: string, right: string];
  barPosition?: number;
};

export const SalaryForecastBar: React.FC<Props> = ({
  headlineCopy,
  isFullRange,
  legend,
  barPosition,
}) => {
  const hasRangePosition = typeof barPosition === 'number';

  return (
    <ForecastContainer $hasRangePosition={hasRangePosition}>
      {headlineCopy ? (
        <BarHeadline size="medium" $position={barPosition}>
          {headlineCopy}
        </BarHeadline>
      ) : null}
      <ForecastBar
        $isFullRange={isFullRange}
        $hasRangePosition={hasRangePosition}
      >
        {!isFullRange ? <RangeBar $position={barPosition} /> : null}
      </ForecastBar>
      {legend ? (
        <LegendContainer>
          <LegendCopy>{legend[0]}</LegendCopy>
          <LegendCopy>{legend[1]}</LegendCopy>
        </LegendContainer>
      ) : null}
    </ForecastContainer>
  );
};
