import { useQuery } from '@apollo/client';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { GetHrContactDocument } from '@xing-com/crate-jobs-graphql';
import type { HRContactData } from '@xing-com/crate-jobs-helpers';

type HRContactResponse = {
  isLoading: boolean;
  hrContact?: HRContactData;
  clickReasonCode?: string;
};

export const useHRContact = (
  jobSlug: string,
  companyId?: string
): HRContactResponse => {
  const { isLoggedIn } = useLoginState();
  const { loading, data } = useQuery(GetHrContactDocument, {
    variables: { jobSlug, companyId: companyId || '' },
    skip: !companyId || !isLoggedIn,
  });

  if (loading) return { isLoading: true };
  if (data?.job?.__typename !== 'VisibleJob' || !data.job.hiringContact?.user)
    return { isLoading: false };

  const hrContactUser = data.job.hiringContact.user;
  const clickReasonCode = hrContactUser.clickReasonProfileUrlFromEntity?.code;

  const hrContact = hrContactUser
    ? {
        profileImage: hrContactUser.profileImage,
        gender: hrContactUser.gender,
        name:
          hrContactUser.displayName ||
          `${hrContactUser.firstName} ${hrContactUser.lastName}`,
        occupation: hrContactUser.occupations?.[0] ?? undefined,
        userFlag: hrContactUser.userFlags?.displayFlag ?? undefined,
        hrProfileUrl: `/profile/${hrContactUser.pageName}${
          clickReasonCode ? `/${clickReasonCode}` : ''
        }`,
        chatUrl: `/chats/new/${hrContactUser.pageName}`,
      }
    : undefined;

  return {
    isLoading: false,
    hrContact,
  };
};
