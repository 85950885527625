import { useParams } from '@reach/router';

import { XingletLoader } from '@xing-com/crate-xinglet';
import type { Xinglet } from '@xing-com/crate-xinglet';

import { JobDetails } from './components';

export default class JobDetailsXinglet implements Xinglet {
  public getComponent() {
    return () => {
      const { slug } = useParams<{ slug: string }>();

      const jobDetailsSlugRegex = /^[^/]+-\d+$/;

      if (jobDetailsSlugRegex.test(slug)) {
        return <JobDetails />;
      }

      return <XingletLoader name="@xing-com/crate-jobs-pages-search" />;
    };
  }
}
