import type { JobPostingSkillFragment } from '@xing-com/crate-jobs-graphql';

import { Preference } from './user-job-preferences-types';

// returns true when a user has four or more matching skills and at least one matching preference in salary, location, or discipline
export const hasMatchingSignifier = (
  matchingPreferences: Preference[] = [],
  matchedSkills: JobPostingSkillFragment[] = []
): boolean => {
  const hasMatchedSkills = matchedSkills.length >= 4;
  const hasPreferences = hasSignifierPreferences(matchingPreferences);
  return hasMatchedSkills && hasPreferences;
};

// returns true when matching preferences include salary, location or discipline
const hasSignifierPreferences = (
  matchingPreferences: Preference[] = []
): boolean => {
  const signifierPreferences = [
    Preference.SALARY,
    Preference.LOCATION,
    Preference.DISCIPLINE,
  ];
  return matchingPreferences?.some((preference) =>
    signifierPreferences.includes(preference)
  );
};
