// this svg has been extracted manually from ./compass.svg
export function IndustryHandSvg(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg viewBox="0 0 164 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="var(--xdlColorBorder)"
        strokeWidth={2}
        transform="translate(1 1)"
      >
        <path d="M16 8h148" />
        <circle
          stroke="var(--xdlColorBorder)"
          strokeWidth="2"
          fill="#FFF"
          fillRule="nonzero"
          cx={8}
          cy={8}
          r={8}
        />
      </g>
    </svg>
  );
}
