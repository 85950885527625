import { navigate } from '@reach/router';
import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { Card } from '@xing-com/card';
import {
  Gender,
  UserFlagsDisplayFlag,
} from '@xing-com/crate-common-graphql-types';
import { FLAG } from '@xing-com/crate-jobs-constants';
import { TrackingLink } from '@xing-com/crate-jobs-domain-job-details-components-tracking-link';
import type { HRContactData } from '@xing-com/crate-jobs-helpers';
import { Flag } from '@xing-com/flag';
import { IconMessages } from '@xing-com/icons';
import { ProfileImage } from '@xing-com/profile-image';
import { ProfileInfo } from '@xing-com/profile-info';
import { spaceXL, xdlColorText } from '@xing-com/tokens';
import { BodyCopy, Headline, Meta } from '@xing-com/typography';

type HRContactCardProps = {
  hiringContact: HRContactData;
  className?: string;
};

const StyledCard = styled(Card)`
  padding: ${spaceXL};
`;

export const StyledLink = styled(TrackingLink)`
  color: ${xdlColorText};

  &:hover {
    text-decoration-thickness: from-font;
  }
`;

const trackingParams =
  'sc_o=jobs_profile_other_visit_contact_module&sc_o_PropActionOrigin=jobs_hr_contact';
const chatTrackingParams =
  'contextId=jobs_contact_hr&entryPoint=jobs_job_detail_recruiter';

export const HRContactCard: React.FC<HRContactCardProps> = ({
  hiringContact: {
    profileImage,
    gender,
    name,
    occupation,
    chatUrl,
    hrProfileUrl,
    userFlag,
  },
  className,
}) => (
  <StyledCard
    className={className}
    to={hrProfileUrl}
    data-testid="hr-contact-card"
    onClick={(e: React.MouseEvent) => {
      e.preventDefault();
      window.location.assign(`${hrProfileUrl}?${trackingParams}`);
    }}
  >
    <ProfileInfo
      alignment="vertical"
      profileImage={
        <StyledLink link={hrProfileUrl} trackingParams={trackingParams}>
          <ProfileImage
            profileName={name}
            size="medium"
            src={profileImage?.[0]?.url}
            type={
              gender === Gender.Male
                ? 'male-user'
                : Gender.Female
                  ? 'female-user'
                  : 'neutral-user'
            }
          />
        </StyledLink>
      }
      actions={
        <Button
          size="small"
          variant="secondary"
          icon={IconMessages}
          // @ts-expect-error FIXME: SC6
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            navigate(`${chatUrl}?${chatTrackingParams}`);
          }}
        >
          <FormattedMessage id="JOBS_JOB_DETAILS_MESSAGE_HR_CONTACT" />
        </Button>
      }
    >
      <Headline size="large">
        <StyledLink link={hrProfileUrl} trackingParams={trackingParams}>
          {name}
        </StyledLink>
      </Headline>
      {userFlag === UserFlagsDisplayFlag.Premium ||
      userFlag === UserFlagsDisplayFlag.Insider ? (
        <Flag size="medium" variant={FLAG[userFlag]} />
      ) : null}
      {occupation?.subline ? (
        <BodyCopy size="medium">{occupation.subline}</BodyCopy>
      ) : null}
      {occupation?.headline ? (
        <Meta size="small">{occupation.headline}</Meta>
      ) : null}
    </ProfileInfo>
  </StyledCard>
);
