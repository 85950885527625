import { useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import { useEffect, useMemo } from 'react';

import type { PageViewTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { JDP_SUMMARY_MAX_SKILLS } from '@xing-com/crate-jobs-constants';
import type { JobPostingSkillFragment } from '@xing-com/crate-jobs-graphql';
import { RegisterVisitDocument } from '@xing-com/crate-jobs-graphql';
import {
  getRevenueTypeContextDimension,
  getSalaryTypeContextDimension,
  getHighlightsTrackingData,
  type JobDetails,
  matchJobWithPreferences,
  hasMatchingSignifier,
} from '@xing-com/crate-jobs-helpers';
import type { UserJobPreferencesData } from '@xing-com/crate-jobs-helpers';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { useExperimentTrackingData, useViewerData } from '@xing-com/hub';

import { getModulesVisibility } from '../job-details/get-modules-visibility';

type Props = {
  job: JobDetails;
  userJobPreferences?: UserJobPreferencesData;
  matchedSkills?: JobPostingSkillFragment[];
  skip: boolean;
};

const getUserJobPreferencesTrackingData = (
  job: JobDetails,
  userJobPreferences?: UserJobPreferencesData
): string => {
  const matchingPreferencesCount = userJobPreferences
    ? matchJobWithPreferences(job, userJobPreferences).length
    : 0;

  return `matching_preferences_${
    matchingPreferencesCount > 0 ? matchingPreferencesCount : 'no_visible'
  }`;
};

const getMatchingSkillsTrackingData = (
  matchingSkills?: JobPostingSkillFragment[]
): string => {
  const matchingSkillsCount = Math.min(
    (matchingSkills ?? []).length,
    JDP_SUMMARY_MAX_SKILLS
  );

  return `matching_skills_${
    matchingSkillsCount > 0 ? matchingSkillsCount : 'no_visible'
  }`;
};

const getMatchingSignifierTrackingData = (
  job: JobDetails,
  userJobPreferences?: UserJobPreferencesData,
  matchedSkills?: JobPostingSkillFragment[]
): string | undefined => {
  const matchingPreferences = userJobPreferences
    ? matchJobWithPreferences(job, userJobPreferences)
    : [];
  return hasMatchingSignifier(matchingPreferences, matchedSkills)
    ? 'matching_signifier'
    : undefined;
};

export const useJobDetailsTracking = ({
  job,
  userJobPreferences,
  matchedSkills,
  skip,
}: Props): void => {
  const { isLoggedIn } = useLoginState();
  const location = useLocation();
  const viewerData = useViewerData();
  const { getSearchParameter } = useSearchParameter();
  const { track: trackView } = useTracking<PageViewTrackingEvent>();
  const trackNwt = useJobsNwtTracking();
  const [registerVisitMutation] = useMutation(RegisterVisitDocument);
  const experimentTrackingData = useExperimentTrackingData();

  const highlightsTrackingData = getHighlightsTrackingData(
    job.highlights,
    !!job.facts.isVerifiedByXing
  );
  const userJobPreferencesTrackingData = getUserJobPreferencesTrackingData(
    job,
    userJobPreferences
  );
  const matchingSkillsTrackingData =
    getMatchingSkillsTrackingData(matchedSkills);
  const matchingSignifierTrackingData = getMatchingSignifierTrackingData(
    job,
    userJobPreferences,
    matchedSkills
  );

  const { modulesList } = getModulesVisibility(job, isLoggedIn);

  const adobeViewTrackingBaseProps = useMemo(() => {
    const tttSpParam = getSearchParameter('ttt_sp');

    return {
      channel: 'wbm/Stellenmarkt',
      page: 'wbm/Stellenmarkt/detail',
      PropJobsId: job.id,
      PropContextDimension2: getRevenueTypeContextDimension(job),
      PropContextDimension3: getSalaryTypeContextDimension(job.salary),
      PropContextDimension5: `jobs_neffi_score_${viewerData.data?.networkEfficiencyIndicator?.score}`,
      ...(job.discipline?.id && { PropJobsDiscipline: job.discipline.id }),
      PropModules: modulesList,
      ...(tttSpParam && { PropSearchPosition: tttSpParam }),
      ...experimentTrackingData,
    };
  }, [
    experimentTrackingData,
    getSearchParameter,
    job,
    modulesList,
    viewerData.data?.networkEfficiencyIndicator?.score,
  ]);

  const adobeViewTrackingContextProps = useMemo(() => {
    const scOPropActionOriginParam = getSearchParameter(
      'sc_o_PropActionOrigin'
    );

    return {
      EventJobsDetailView: 1,
      PropContextAddition: [
        highlightsTrackingData,
        userJobPreferencesTrackingData,
        matchingSkillsTrackingData,
        matchingSignifierTrackingData,
      ]
        .filter((item) => !!item)
        .join(','),
      ...(scOPropActionOriginParam && {
        PropActionOrigin: scOPropActionOriginParam,
      }),
    };
  }, [
    getSearchParameter,
    highlightsTrackingData,
    matchingSignifierTrackingData,
    matchingSkillsTrackingData,
    userJobPreferencesTrackingData,
  ]);

  useEffect(() => {
    if (!skip) {
      trackNwt({
        eventSchema: 'basic',
        event: 'viewed_screen',
        page: 'jobs/detail',
        itemUrn: `surn:x-xing:jobs:posting:${job.id}`,
      });

      trackNwt({
        eventSchema: 'basic',
        event: 'job_detail_viewed',
        page: 'jobs/detail',
        itemUrn: `surn:x-xing:jobs:posting:${job.id}`,
      });

      trackView({
        type: 'pageview',
        ...adobeViewTrackingBaseProps,
        contextProps: { ...adobeViewTrackingContextProps },
      });

      registerVisitMutation({
        variables: {
          jobId: job.id,
          originalUrl: location.href,
          referrer: document.referrer,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.id]);
};
