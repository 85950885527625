import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';

const trackingIdParam = 'tracking_id';
const trackingOriginParam = 'tracking_origin';

export const useGetApplyUrlWithTrackingParams = (
  applyUrl?: string
): string | undefined => {
  const { getSearchParameter } = useSearchParameter();

  if (!applyUrl) return;

  // get tracking params from current url
  const trackingId = getSearchParameter(trackingIdParam);
  const trackingOrigin = getSearchParameter(trackingOriginParam);

  // only append tracking params when both are present
  if (trackingId && trackingOrigin) {
    const url = new URL(applyUrl);

    url.searchParams.set(trackingIdParam, trackingId);
    url.searchParams.set(trackingOriginParam, trackingOrigin);

    return url.toString();
  }

  return applyUrl;
};
