import styled, { css } from 'styled-components';

import {
  scale010,
  scale030,
  scale120,
  scale180,
  spaceS,
  spaceXS,
  xdlPaletteGrayPlain04,
  xdlPaletteMint07,
  xdlPaletteWhite,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

const RANGE_BAR_WIDTH = scale180;

export const ForecastContainer = styled.div<{ $hasRangePosition: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $hasRangePosition }) =>
    $hasRangePosition &&
    css`
      position: relative;
      padding-top: ${scale120}; /* Add absolute-positioned headline's height as padding to place the headline on top */
    `}
`;

export const BarHeadline = styled(Headline)<{ $position?: number }>`
  margin-bottom: ${spaceS};
  text-align: center;
  ${({ $position }) =>
    typeof $position === 'number' &&
    css`
      position: absolute;
      top: 0;
      left: ${$position}%;
      transform: translateX(-${$position}%);
    `}
`;

export const ForecastBar = styled.div<{
  $isFullRange?: boolean;
  $hasRangePosition: boolean;
}>`
  width: 100%;
  background-color: ${({ $isFullRange }) =>
    $isFullRange ? xdlPaletteMint07 : xdlPaletteGrayPlain04};
  border-radius: ${spaceXS};
  display: flex;
  justify-content: center;
  padding-bottom: ${({ $isFullRange }) => ($isFullRange ? spaceS : 0)};
  ${({ $hasRangePosition }) =>
    $hasRangePosition &&
    css`
      position: relative;
      padding-bottom: ${spaceS};
    `}
`;

export const RangeBar = styled.div<{ $position?: number }>`
  width: ${RANGE_BAR_WIDTH};
  background-color: ${xdlPaletteMint07};
  border-radius: ${scale030};
  padding-bottom: ${spaceS};
  border: solid ${scale010} ${xdlPaletteWhite};
  margin: -${scale010} 0;
  ${({ $position }) =>
    typeof $position === 'number' &&
    css`
      position: absolute;
      left: ${$position}%;
      transform: translateX(-${$position}%);
    `}
`;

export const LegendContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-between;
  padding: ${spaceS} 0 0;
`;

export const LegendCopy = styled(BodyCopy)`
  margin-bottom: 0;
`;
