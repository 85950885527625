import styled from 'styled-components';

import { StatusBannerAutoHide as StatusBannerAutoHideComponent } from '@xing-com/banner';
import { scale080, scale110, spaceXXS, xdlColorText } from '@xing-com/tokens';

export const StatusBannerAutoHide = styled(StatusBannerAutoHideComponent)`
  z-index: 399;

  p {
    color: ${xdlColorText};
    line-height: ${scale110};
    font-size: ${scale080};
  }
`;

export const BannerAction = styled.span`
  cursor: pointer;
  font-weight: 700;
  padding-left: ${spaceXXS};
`;
