import { StyledHeadline, StyledHero, TitleContainer } from './title.styles';

type Props = {
  title?: string | null;
};

export const Title: React.FC<Props> = ({ title }) => {
  if (!title) {
    return null;
  }

  return (
    <TitleContainer data-testid="job-details-title">
      <StyledHero noMargin size="small">
        {title}
      </StyledHero>
      <StyledHeadline noMargin size="xxlarge">
        {title}
      </StyledHeadline>
    </TitleContainer>
  );
};
