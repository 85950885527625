import type React from 'react';
import styled from 'styled-components';

import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vw;
  width: 100%;
`;

export const JobDetailsLoading: React.FC = () => (
  <Container data-testid="job-details-loading">
    <DotLoadingIndicator />
  </Container>
);
