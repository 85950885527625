import {
  JobReportableField,
  JobReportReason,
} from '@xing-com/crate-common-graphql-types';

export const reportJobOptions: JobReportReason[] = [
  JobReportReason.IllegalContent,
  JobReportReason.NoJobAd,
  JobReportReason.WrongInformation,
];

export const reportJobReasons: JobReportableField[] = [
  JobReportableField.CareerLevel,
  JobReportableField.CompanyName,
  JobReportableField.EmploymentType,
  JobReportableField.Location,
  JobReportableField.Other,
];
