import { makeVar, useReactiveVar } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { useCallback, useEffect, useMemo } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { useBannerContext } from '@xing-com/crate-jobs-components-banner';
import {
  useGetJobApplicationType,
  useGetLazyNeffiScore,
} from '@xing-com/crate-jobs-hooks';
import { IconButterflyMono } from '@xing-com/icons';

import { useRedirectToLoginWithJobDestUrl } from './use-redirect-to-login-with-job-dest-url';
import { useTrackApplyIntention } from './use-track-apply-intention';

const QUICK_APPLY_NEFFI_SCORE_THRESHOLD = 80;

type XingApplyProps =
  | Record<string, string | boolean>
  | Record<'icon', React.FC<React.SVGProps<SVGSVGElement>>>
  | Record<'onClick', () => void>;

// Reactive variables are stored within Apollo's cache which allows us to simplify
// the state of this component. In this case, the hook is used by a button is
// instantiated multiple times, and the state needs to be shared across all instances.
// We are also exporting the variables so that in the test we can reset the state
// before each test. This variables shouldn't be used/modified outside this scope
export const isLoadingCachedVar = makeVar(false);
export const isLoadingNeffiCachedVar = makeVar(false);
export const isRedirectingCachedVar = makeVar(false);

export const useApplyButtonXingApplyProps = (
  slug: string,
  jobId: string
): XingApplyProps => {
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();
  const { getJobApplicationType, isCalled, data, error } =
    useGetJobApplicationType();
  const { getLazyNeffiScore, normalizedNeffiScore } = useGetLazyNeffiScore();
  const { showErrorBanner } = useBannerContext();
  const redirectToLogin = useRedirectToLoginWithJobDestUrl();
  const { getSearchParameter } = useSearchParameter();
  const isLoading = useReactiveVar(isLoadingCachedVar);
  const isLoadingNeffi = useReactiveVar(isLoadingNeffiCachedVar);
  const isRedirecting = useReactiveVar(isRedirectingCachedVar);
  const trackApplyIntention = useTrackApplyIntention({
    applicationType: 'JobXingApplication',
    jobId,
  });

  useEffect(() => {
    if (!isCalled || isLoading) {
      return;
    }

    if (error) {
      showErrorBanner('JOBS_ERROR_SOMETHING_WENT_WRONG');
      isLoadingNeffiCachedVar(false);
      return;
    }
    const { isVisibleJob, applicationType, isLoApplyEnabled } = data;

    if (!isVisibleJob) {
      showErrorBanner('JOBS_JOB_DETAILS_XING_APPLY_DEACTIVATED_ERROR');
      isLoadingNeffiCachedVar(false);
      return;
    }
    if (applicationType !== 'JobXingApplication') {
      showErrorBanner('JOBS_JOB_DETAILS_XING_APPLY_TYPE_ERROR');
      isLoadingNeffiCachedVar(false);
      return;
    }

    if (isLoadingNeffi) {
      return;
    }

    if (isRedirecting) {
      return;
    }

    isRedirectingCachedVar(true);

    const fixedParams = new URLSearchParams({ sc_o: 'jobs_apply_start' });
    const ijt = getSearchParameter('ijt');
    if (ijt) {
      fixedParams.set('ijt', ijt);
    }
    const applyPath = `/jobs/apply/${slug}?${fixedParams.toString()}`;

    if (isLoggedIn) {
      if (normalizedNeffiScore >= QUICK_APPLY_NEFFI_SCORE_THRESHOLD) {
        navigate(`/jobs/quick-apply/${slug}?${fixedParams.toString()}`);
      } else {
        navigate(applyPath);
      }
    } else if (isLoApplyEnabled) {
      navigate(applyPath);
    } else {
      redirectToLogin();
    }
  }, [
    data,
    error,
    isCalled,
    isLoggedIn,
    isLoading,
    isRedirecting,
    navigate,
    getSearchParameter,
    showErrorBanner,
    slug,
    isLoadingNeffi,
    normalizedNeffiScore,
    redirectToLogin,
  ]);

  useEffect(() => {
    // Manually reset the cached state in case the user comes back to the page
    isLoadingCachedVar(false);
    isLoadingNeffiCachedVar(false);
    isRedirectingCachedVar(false);
  }, []);

  const handleXingApply = useCallback(() => {
    trackApplyIntention();

    getJobApplicationType({
      variables: { slug },
      onCompleted: () => {
        isLoadingCachedVar(false);
      },
      onError: () => {
        isLoadingCachedVar(false);
      },
    });
    isLoadingCachedVar(true);

    if (isLoggedIn) {
      getLazyNeffiScore({
        onCompleted: () => {
          isLoadingNeffiCachedVar(false);
        },
        onError: () => {
          isLoadingNeffiCachedVar(false);
        },
      });

      isLoadingNeffiCachedVar(true);
    }
  }, [
    getJobApplicationType,
    getLazyNeffiScore,
    isLoggedIn,
    slug,
    trackApplyIntention,
  ]);

  const action = useMemo(
    () => ({ to: undefined, onClick: handleXingApply }),
    [handleXingApply]
  );

  return {
    icon: IconButterflyMono,
    ...action,
    loading: isLoading || isRedirecting || isLoadingNeffi,
  };
};
