import { useQuery } from '@apollo/client';

import type { UnauthorizedJobReason } from '@xing-com/crate-common-graphql-types';
import type { JobDetailsVisibleJobFragment } from '@xing-com/crate-jobs-graphql';
import { GetJobDetailsDocument } from '@xing-com/crate-jobs-graphql';

import { useVisibleJobTypePolicies } from './use-visible-job-type-policies';

type JobDetailsResponse = {
  isLoading: boolean;
  job?: JobDetailsVisibleJobFragment;
  error?: UnauthorizedJobReason;
};

export const useJobDetails = (slug: string): JobDetailsResponse => {
  useVisibleJobTypePolicies();
  const { loading, data } = useQuery(GetJobDetailsDocument, {
    variables: { slug },
    errorPolicy: 'all',
  });

  if (data?.job?.__typename === 'UnauthorizedJob')
    return { isLoading: false, error: data.job.reason };

  const job = data?.job?.__typename === 'VisibleJob' ? data.job : undefined;

  return { isLoading: loading, job };
};
