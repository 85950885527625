export function BackgroundSvg(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg viewBox="0 0 346 182" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <radialGradient
          cx="0%"
          cy="100%"
          fx="0%"
          fy="100%"
          r="199.72%"
          gradientTransform="scale(.5007 1)"
          id="Compass_Outline_AllIn_opt_svg__b"
        >
          <stop stopColor="#5691EA" offset="0%" />
          <stop stopColor="#01D297" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="0%"
          cy="100%"
          fx="0%"
          fy="100%"
          r="204.205%"
          gradientTransform="scale(.5007 1)"
          id="Compass_Outline_AllIn_opt_svg__a"
        >
          <stop stopColor="#5691EA" offset="0%" />
          <stop stopColor="#01D297" offset="100%" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M29 173.202C29 118.258 72.287 29 173.356 29 274.425 29 317 117.064 317 173.202"
          stroke="url(#Compass_Outline_AllIn_opt_svg__a)"
          strokeWidth={8}
          fillOpacity={0.25}
          fill="url(#Compass_Outline_AllIn_opt_svg__b)"
        />
        <line
          x1="346"
          y1="173"
          x2="0"
          y2="173"
          stroke="#DBE2E6"
          strokeWidth="2"
          fill="#DBE2E6"
          strokeLinecap="square"
        ></line>
      </g>
    </svg>
  );
}
