import styled, { css } from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceS,
  spaceXS,
  xdlColorControlFillQuaternary,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import { BackgroundSvg, IndustryHandSvg, ProfileHandSvg } from './assets';
import type { Props } from './culture-compass';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${mediaConfined} {
    padding: 0 ${spaceS};
  }
`;

export const CompassWrapper = styled.div`
  position: relative;
  padding-bottom: calc(182 / 346 * 100%); /* Original svg ratio */
  width: 100%;
`;

export const Background = styled(BackgroundSvg)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ProfileHand = styled(ProfileHandSvg)`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }
`;

export const ProfileHandInnerWrapper = styled.div`
  position: relative;
  padding-bottom: calc(18 / 172 * 100%); /* Original svg ratio */
  width: 100%;
`;

export const ProfileHandOuterWrapper = styled.div<{
  $profileAngle: Props['profileAngle'];
}>(
  ({ $profileAngle }) => css`
    position: absolute;
    bottom: 0;
    right: 50%;
    width: 50%;
    transform-origin: center right;
    transform: rotate(${$profileAngle}deg);
  `
);

export const IndustryHand = styled(IndustryHandSvg)`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;

  @media (prefers-color-scheme: dark) {
    circle {
      filter: invert(1);
    }
  }
`;

export const IndustryHandInnerWrapper = styled.div`
  position: relative;
  padding-bottom: calc(18 / 164 * 100%); /* Original svg ratio */
  width: 100%;
`;

export const IndustryHandOuterWrapper = styled.div<{
  $industryAngle: Props['industryAngle'];
}>(
  ({ $industryAngle }) => css`
    position: absolute;
    bottom: 0;
    right: 50%;
    width: 48%;
    transform-origin: center right;
    transform: rotate(${$industryAngle}deg);
  `
);

export const OriginWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3.5%;
  width: 1.75%;
`;

export const Origin = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background-color: ${xdlColorControlFillQuaternary};
  border-radius: 50%;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-between;
  padding: ${spaceXS} 0 0;
`;

export const LeftLegend = styled(BodyCopy)`
  text-align: left;
`;

export const RightLegend = styled(BodyCopy)`
  text-align: right;
`;
