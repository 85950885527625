import * as React from 'react';

export function createWebStorageHook(storage?: Storage) {
  return <T>(key: string, initialValue: T): [T, (s: T) => void] => {
    if (!storage) return [initialValue, () => ({})];

    const [storedValue, setStoredValue] = React.useState<T>(() => {
      try {
        const item = storage.getItem(key);
        if (item) {
          return JSON.parse(item);
        } else if (initialValue !== undefined) {
          storage.setItem(key, JSON.stringify(initialValue));
        }

        return initialValue;
      } catch (error) {
        console.log(error);
        return initialValue;
      }
    });

    const setValue = React.useCallback(
      (value: T): void => {
        try {
          setStoredValue(value);
          if (value === undefined) {
            storage.removeItem(key);
          } else {
            storage.setItem(key, JSON.stringify(value));
          }
        } catch (error) {
          console.log(error);
        }
      },
      [key, setStoredValue]
    );

    return [storedValue, setValue];
  };
}
