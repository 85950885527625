import { useNavigate } from '@reach/router';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import { ActionButtons } from '@xing-com/crate-jobs-components-job-teaser-options';
import type { SimilarJobData } from '@xing-com/crate-jobs-helpers';
import { getTimeDifferenceInDays } from '@xing-com/crate-jobs-helpers';
import { useJobBookmarkToggle } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import {
  InViewTracking,
  useJobsNwtTracking,
} from '@xing-com/crate-jobs-tracking';
import {
  JobTeaserCard,
  JobTeaserListItem,
} from '@xing-com/crate-jobs-xinglets';
import { unscrambleId } from '@xing-com/crate-utils-x1-data';
import { useExperiment } from '@xing-com/hub';
import { NotOnLarge, OnlyOnLarge } from '@xing-com/layout-primitives';

const LargeScreen = styled(OnlyOnLarge)`
  height: 100%;
`;

type SimilarJobProps = {
  similarJob: SimilarJobData;
  isInSidebar?: boolean;
  ijtParam: string;
};

export const SimilarJob: React.FC<SimilarJobProps> = ({
  similarJob: { job, position, trackingToken },
  isInSidebar = false,
  ijtParam,
}) => {
  const navigate = useNavigate();
  const nwtTrack = useJobsNwtTracking();
  const bookmarkState = job.userInteractions?.bookmark?.state;
  const isBookmarked =
    !!bookmarkState && bookmarkState !== JobBookmarkState.NotBookmarked;

  const nwtTrackingOptions = {
    eventSchema: 'searchresult',
    itemUrn: job.globalId,
    page: 'jobs/detail',
    position,
    trackingToken,
  } as const;

  const bookmarkToggle = useJobBookmarkToggle({
    jobId: job.id,
    isBookmarked,
    nwtTrackingOptions,
    adobeTrackingOptions: { PropJobsId: unscrambleId(job.id) },
  });

  const jobRefreshExperimentVariant = useExperiment('ABACUS-469');

  const url = generatePath(ROUTES.jobDetails, { slug: job.slug });
  const urlWithParams = `${url}?ijt=${ijtParam}`;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    nwtTrack({ ...nwtTrackingOptions, event: 'opened' });

    if (!e.metaKey && !e.ctrlKey) {
      navigate(urlWithParams);
    }
  };

  const addTrackingParams = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    e.currentTarget.href = urlWithParams;
  };

  const removeTrackingParams = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();
    e.currentTarget.href = url;
  };

  const matchingHighlights =
    isInSidebar && job.matchingHighlights
      ? {
          ...job.matchingHighlights,
          matchingFacts: [],
        }
      : job.matchingHighlights;

  const jobTeaserComponentProps = {
    job,
    url,
    matchingHighlights,
    isNew: !!job.activatedAt && getTimeDifferenceInDays(job.activatedAt) <= 2,
    showRefreshedDate: jobRefreshExperimentVariant === 'B',
    onClick,
    onMouseDown: addTrackingParams,
    onMouseLeave: removeTrackingParams,
  };
  const actionButtonsProps = {
    onBookmark: bookmarkToggle,
    isBookmarked,
    noMargin: true,
    size: 'small',
  };

  return (
    <InViewTracking
      nwtTrackingOptions={{ ...nwtTrackingOptions, event: 'entered_viewport' }}
    >
      <NotOnLarge>
        <JobTeaserListItem
          {...jobTeaserComponentProps}
          actions={
            <ActionButtons {...actionButtonsProps} bottom={false} iconsOnly />
          }
        />
      </NotOnLarge>
      <LargeScreen>
        <JobTeaserCard
          {...jobTeaserComponentProps}
          data-testid={`similar-job-large-screen-${job.id}`}
          actions={
            <ActionButtons {...actionButtonsProps} iconsOnly={false} bottom />
          }
        />
      </LargeScreen>
    </InViewTracking>
  );
};
