import type { ApplicationData, JobDetails } from '@xing-com/crate-jobs-helpers';
import { useLoginState } from '@xing-com/hub';

import {
  useApplicationStatusHelpers,
  useApplicationTypeHelpers,
} from '../../../hooks';

type UseMainActionsResult = {
  showApplyButton: boolean;
  showBookmarkButton: boolean;
};

type Props = { application: ApplicationData; job: JobDetails };

export const useMainActions = ({
  application,
  job,
}: Props): UseMainActionsResult => {
  const loginState = useLoginState();
  const { appliedAt } = useApplicationStatusHelpers(application);
  const { isUrlApplication, isEmailApplication, isMessageApplication } =
    useApplicationTypeHelpers(application.type);

  const isApplyUrlMissing = isUrlApplication && !application.applyUrl;
  const isApplyEmailMissing =
    isEmailApplication &&
    loginState !== 'LOGGED_OUT' &&
    !application.applyEmail; // applyEmail is restricted to LI and SLI users on x1
  const isContactUserIdMissing =
    isMessageApplication &&
    loginState !== 'LOGGED_OUT' &&
    !application.contactUserId; // contactUserId is restricted to LI and SLI users on x1

  const isValidApplyData = !(
    isApplyUrlMissing ||
    isApplyEmailMissing ||
    isContactUserIdMissing
  );

  const hasUserApplied = !!appliedAt;
  const showApplyButton =
    !hasUserApplied && isValidApplyData && !job.isThirdParty;
  const showBookmarkButton = !hasUserApplied;

  return {
    showApplyButton,
    showBookmarkButton,
  };
};
