import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ContentBanner } from '@xing-com/banner';
import type { ApplicationData } from '@xing-com/crate-jobs-helpers';
import { IconTick } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceM, spaceS, spaceXXL } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import { useApplicationStatusHelpers } from '../../hooks';

const Banner = styled(ContentBanner)`
  padding: ${spaceM};
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spaceS} ${spaceXXL};

  @media ${mediaConfined} {
    padding: ${spaceL};
    margin-bottom: ${spaceL};
  }
`;

const ConfirmationItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceM};
`;

type Props = {
  application: ApplicationData;
  className?: string;
};

export const Confirmation: React.FC<Props> = ({
  application,
  className, // this is important to receive styles from outside (see header.tsx)
}) => {
  const { appliedAt } = useApplicationStatusHelpers(application);
  const hasApplyConfirmation = !!appliedAt;

  if (!hasApplyConfirmation) return null;

  return (
    <Banner variant="default" display="inline" className={className} noPadding>
      {hasApplyConfirmation ? (
        <ConfirmationItem>
          <IconTick width={18} height={18} />
          <BodyCopy noMargin>
            <FormattedMessage
              id="JOBS_JOB_DETAILS_PREVIOUSLY_APPLIED_CONFIRMATION"
              values={{
                date: (
                  <FormattedDate
                    value={appliedAt}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                ),
              }}
            />
          </BodyCopy>
        </ConfirmationItem>
      ) : null}
    </Banner>
  );
};
