/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTwitter"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m13.7 10.35 7.447-8.477h-1.764L12.914 9.23 7.752 1.873H1.796L9.604 13 1.796 21.89H3.56l6.827-7.773 5.453 7.773h5.956zm-2.416 2.749-.793-1.109-6.295-8.815h2.71l5.082 7.117.79 1.109 6.604 9.25h-2.71z"
    />
  </svg>
);
export default IconTwitter;
