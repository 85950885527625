/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUserJobPreferencesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserJobPreferencesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    jobWishesPreference?: {
      __typename?: 'JobWishesPreference';
      salaryExpectations?: number | null;
      minCareerLevel?: number | null;
      maxCareerLevel?: number | null;
      idealEmployers: Array<{ __typename?: 'Company'; id: string } | null>;
      industries: Array<{
        __typename?: 'Industry';
        id: string;
        segments: Array<{ __typename?: 'IndustrySegment'; id: string }>;
      } | null>;
      cities: Array<{ __typename?: 'City'; id: string } | null>;
      workingHours?: {
        __typename?: 'WorkingHoursPreference';
        fullTime: boolean;
        partTime: boolean;
      } | null;
    } | null;
    disciplinesPreference?: {
      __typename?: 'DisciplinesPreference';
      disciplineIds: Array<string | null>;
    } | null;
  } | null;
};

export const GetUserJobPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserJobPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobWishesPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idealEmployers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salaryExpectations' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'segments' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minCareerLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxCareerLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHours' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'partTime' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'disciplinesPreference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disciplineIds' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserJobPreferencesQuery,
  GetUserJobPreferencesQueryVariables
>;
