/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type JobPostingSkillFragment = {
  __typename?: 'OntologyLocalizedJobPostingSkill';
  label: string;
  isSoft: boolean;
};

export type GetJobMatchingSkillsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type GetJobMatchingSkillsQuery = {
  __typename?: 'Query';
  job?:
    | { __typename?: 'UnauthorizedJob' }
    | {
        __typename?: 'VisibleJob';
        id: string;
        userProfileMatches?: {
          __typename?: 'OntologyUserJobPostingProfileMatchResponse';
          skills?: {
            __typename?: 'JobPostingSkillMatches';
            matchedSkills?: Array<{
              __typename?: 'OntologyLocalizedJobPostingSkill';
              label: string;
              isSoft: boolean;
            } | null> | null;
            notMatchedSkills?: Array<{
              __typename?: 'OntologyLocalizedJobPostingSkill';
              label: string;
              isSoft: boolean;
            } | null> | null;
          } | null;
        } | null;
      }
    | null;
};

export const JobPostingSkillFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobPostingSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OntologyLocalizedJobPostingSkill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSoft' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobPostingSkillFragment, unknown>;
export const GetJobMatchingSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobMatchingSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VisibleJob' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfileMatches' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skills' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'matchedSkills',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'JobPostingSkill',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'notMatchedSkills',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'JobPostingSkill',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobPostingSkill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OntologyLocalizedJobPostingSkill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSoft' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobMatchingSkillsQuery,
  GetJobMatchingSkillsQueryVariables
>;
