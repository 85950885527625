import type { JobDetails } from '@xing-com/crate-jobs-helpers';

import { Metadata } from './metadata';
import { StructuredData } from './structured-data';

export type Props = {
  job: JobDetails;
};

export const JobMetadata: React.FC<Props> = ({ job }) => {
  return (
    <>
      <Metadata job={job} />
      <StructuredData job={job} />
    </>
  );
};
