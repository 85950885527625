import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useHost } from '@xing-com/crate-xinglet';
import type { TopBarProps } from '@xing-com/top-bar';

import { TopBarFallback } from './top-bar-fallback';

export { ActionSlot } from '@xing-com/top-bar';

type CrateTopBarProps = {
  hideLoggedOut?: boolean;
  subHeaderContent?: React.ReactNode;
  isSticky?: boolean;
  isLoggedOut?: boolean;
} & TopBarProps;

export const TopBar: React.FC<CrateTopBarProps> = ({
  hideLoggedOut,
  ...props
}) => {
  const { isLoggedOut } = useLoginState();
  const { XingletLoader } = useHost();

  return isLoggedOut && hideLoggedOut ? null : (
    <XingletLoader
      name="@xing-com/crate-layout-top-bar-xinglet"
      fallback={<TopBarFallback />}
      {...props}
    />
  );
};
