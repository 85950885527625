import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import type {
  JobSalary,
  JobRemoteOptionValue,
} from '@xing-com/crate-common-graphql-types';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { formatSalary } from '@xing-com/crate-jobs-helpers';
import type { JobHighlights } from '@xing-com/crate-jobs-helpers';
import { spaceL, spaceS, spaceXL } from '@xing-com/tokens';

import { Highlight } from './highlight';

const remoteOptionKey: Record<JobRemoteOptionValue, string> = {
  FULL_REMOTE: 'JOBS_REMOTE_TAG',
  PARTLY_REMOTE: 'JOBS_HYBRID_TAG',
  NON_REMOTE: 'JOBS_NON_REMOTE_TAG',
} as const;

const HighlightsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceXL} ${spaceS};
  margin-block: ${spaceL};
`;

type HighlightsProps = {
  highlights: JobHighlights;
  salary?: JobSalary;
};

export const Highlights: React.FC<HighlightsProps> = ({
  highlights,
  salary,
}) => {
  const intl = useIntl();
  const { isLoggedIn } = useLoginState();

  return (
    <HighlightsWrapper>
      {isLoggedIn && highlights.isMatchingSalary && salary ? (
        <Highlight variant="match">{formatSalary(intl, salary)}</Highlight>
      ) : null}
      {highlights.remoteOption ? (
        <Highlight>
          <FormattedMessage id={remoteOptionKey[highlights.remoteOption]} />
        </Highlight>
      ) : null}
      {isLoggedIn && highlights.isApplyWithoutDocuments ? (
        <Highlight>
          <FormattedMessage id="JOBS_HIGHLIGHT_INSTANT_XING_APPLY" />
        </Highlight>
      ) : null}
      {highlights.matchingJobAgeKey ? (
        <Highlight data-testid="matching-job-age-highlight">
          <FormattedMessage id={highlights.matchingJobAgeKey} />
        </Highlight>
      ) : null}
    </HighlightsWrapper>
  );
};
