import { FormattedMessage } from 'react-intl';

import { Hyperlink } from '@xing-com/hyperlink';

import { NoConsentMessage } from './no-consent-message';

type Props = {
  url: string;
};

export const VideoConsentPlaceholder: React.FC<Props> = ({ url }) => (
  <NoConsentMessage
    headline={
      <FormattedMessage id="JOBS_JOB_DETAILS_TEMPLATE_DATA_DESCRIPTION_VIDEO_NO_TRACKING_CONSENT_MESSAGE" />
    }
  >
    {/* @ts-expect-error FIXME: SC6 */}
    <Hyperlink external href={url} target="_blank" rel="noopener">
      <FormattedMessage id="JOBS_JOB_DETAILS_TEMPLATE_DATA_DESCRIPTION_VIDEO_NO_TRACKING_CONSENT_LINK" />
    </Hyperlink>
  </NoConsentMessage>
);
