import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  getSalaryFormatOptions,
  type SalaryEstimateValid,
} from '@xing-com/crate-jobs-helpers';
import { IconInfo } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';

import { ModuleTitle } from '../../module-title';
import { SalaryForecastBar } from '../salary-forecast-bar';
import {
  BarColorLegend,
  BarColorLegendContainer,
  BarColorLegendSample,
  BarColorLegendText,
  InfoButton,
  InfoCopy,
  InfoPopover,
  InfoPopoverCopy,
} from './salary-estimation.styles';

const getMedianOffset = (
  minimum: number,
  maximum: number,
  median: number
): number => ((median - minimum) * 100) / (maximum - minimum);

type Props = {
  salary: SalaryEstimateValid;
};

export const SalaryEstimation: React.FC<Props> = ({ salary }) => {
  const { formatNumber } = useIntl();
  const { maximum, minimum, median, currency } = salary;
  const formatOptions = getSalaryFormatOptions(currency);
  const hasMedian = typeof median === 'number';
  const medianValue = hasMedian
    ? formatNumber(median, formatOptions)
    : undefined;
  const leftRangeValue = formatNumber(minimum, formatOptions);
  const rightRangeValue = formatNumber(maximum, formatOptions);
  const barPosition = hasMedian
    ? getMedianOffset(minimum, maximum, median)
    : undefined;

  const popOver = usePopOver();

  return (
    <>
      <ModuleTitle
        titleKey="JOBS_JOB_DETAIL_SALARY_ESTIMATED_HEADLINE"
        noMargin
      />
      <InfoButton
        $hasMedian={hasMedian}
        size="small"
        innerRef={popOver.triggerRef}
        onClick={popOver.togglePopOver}
        icon={IconInfo}
      >
        <InfoCopy size="small" lineHeight="dense">
          <FormattedMessage id="JOBS_JOB_DETAIL_SALARY_ESTIMATED_INFO" />
        </InfoCopy>
      </InfoButton>
      <InfoPopover
        show={popOver.show}
        showCloseButton={true}
        onOutsideClick={popOver.handleHide}
        triggerRef={popOver.triggerRef}
      >
        <InfoPopoverCopy size="small">
          <FormattedMessage id="JOBS_JOB_DETAIL_SALARY_ESTIMATED_INFO_CONTENT" />
        </InfoPopoverCopy>
      </InfoPopover>

      <SalaryForecastBar
        headlineCopy={medianValue}
        legend={[leftRangeValue, rightRangeValue]}
        barPosition={barPosition}
        isFullRange={!hasMedian}
      />
      <BarColorLegendContainer>
        {hasMedian ? (
          <BarColorLegend>
            <BarColorLegendSample $isAverage />
            <BarColorLegendText size="small">
              <FormattedMessage id="JOBS_JOB_DETAIL_SALARY_ESTIMATED_AVERAGE_LEGEND" />
            </BarColorLegendText>
          </BarColorLegend>
        ) : null}
        <BarColorLegend>
          <BarColorLegendSample $isAverage={!hasMedian} />
          <BarColorLegendText size="small">
            <FormattedMessage id="JOBS_JOB_DETAIL_SALARY_ESTIMATED_RANGE_LEGEND" />
          </BarColorLegendText>
        </BarColorLegend>
      </BarColorLegendContainer>
    </>
  );
};
