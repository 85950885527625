/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUserNeffiScoreQueryVariables = Types.Exact<{
  consumer: Types.Scalars['String']['input'];
}>;

export type GetUserNeffiScoreQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    networkEfficiencyIndicator?: {
      __typename?: 'NetworkEfficiencyIndicator';
      score: number;
    } | null;
  } | null;
};

export const GetUserNeffiScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserNeffiScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consumer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networkEfficiencyIndicator' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'consumer' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserNeffiScoreQuery,
  GetUserNeffiScoreQueryVariables
>;
