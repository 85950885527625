import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type {
  City,
  Company,
  Industry,
  IndustrySegment,
} from '@xing-com/crate-common-graphql-types';
import type { GetUserJobPreferencesQuery } from '@xing-com/crate-jobs-graphql';
import { GetUserJobPreferencesDocument } from '@xing-com/crate-jobs-graphql';
import type { UserJobPreferencesData } from '@xing-com/crate-jobs-helpers';

export type UserJobPreferencesResponse = {
  loading: boolean;
  error?: ApolloError;
  data?: UserJobPreferencesData;
};

type PreferredIndustry = Pick<Industry, 'id'> & {
  segments: Array<Pick<IndustrySegment, 'id'>>;
};

const getCityIds = (preferredCities: Array<City | null>): Array<number> =>
  preferredCities
    .filter((city): city is City => city !== null)
    .map(({ id }) => parseInt(id, 10));

const getIndustryIds = (
  preferredIndustries: Array<PreferredIndustry | null>
): Array<number> =>
  preferredIndustries
    .filter((industry): industry is PreferredIndustry => industry !== null)
    .reduce<
      Array<number>
    >((acc, industry) => [...acc, parseInt(industry.id, 10), ...industry.segments.map(({ id }) => parseInt(id, 10))], []);

const getDisciplineIds = (
  preferredDisciplines: Array<string | null>
): Array<number> =>
  preferredDisciplines
    .filter((discipline): discipline is string => discipline !== null)
    .map((id) => parseInt(id, 10));

const getCompanyIds = (
  preferredDisciplines: Array<Pick<Company, 'id'> | null>
): Array<number> =>
  preferredDisciplines
    .filter((company): company is Company => company !== null)
    .map(({ id }) => parseInt(id, 10));

const getPreferencesData = (
  data: GetUserJobPreferencesQuery
): UserJobPreferencesData => {
  const jobWishesPreference = data.viewer?.jobWishesPreference;
  const preferredDisciplines =
    data.viewer?.disciplinesPreference?.disciplineIds ?? [];

  return {
    minCareerLevelId: jobWishesPreference?.minCareerLevel ?? undefined,
    maxCareerLevelId: jobWishesPreference?.maxCareerLevel ?? undefined,
    minSalary: jobWishesPreference?.salaryExpectations ?? undefined,
    partTime: jobWishesPreference?.workingHours?.partTime ?? false,
    fullTime: jobWishesPreference?.workingHours?.fullTime ?? false,
    preferredCityIds: getCityIds(jobWishesPreference?.cities ?? []),
    preferredIndustryIds: getIndustryIds(jobWishesPreference?.industries ?? []),
    preferredDisciplineIds: getDisciplineIds(preferredDisciplines),
    preferredCompanyIds: getCompanyIds(
      jobWishesPreference?.idealEmployers ?? []
    ),
  };
};

export const useUserJobPreferences = ({
  skip,
}: {
  skip: boolean;
}): UserJobPreferencesResponse => {
  const { loading, error, data } = useQuery(GetUserJobPreferencesDocument, {
    skip,
  });

  if (loading) return { loading, error: undefined, data: undefined };
  if (error) return { loading: false, error, data: undefined };
  if (data) {
    return {
      loading: false,
      error: undefined,
      data: getPreferencesData(data),
    };
  }

  return { loading, error, data: undefined };
};
