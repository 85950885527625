import type {
  JobDetailsContact,
  XingId,
} from '@xing-com/crate-common-graphql-types';
import { CompanyMembership } from '@xing-com/crate-common-graphql-types';
import type {
  ContactFragment,
  GetJobContactsQuery,
} from '@xing-com/crate-jobs-graphql';

export const FIRST_DEGREE_CURRENT = 'firstDegreeCurrentContacts';
export const FIRST_DEGREE_FORMER = 'firstDegreeFormerContacts';
export const SECOND_DEGREE = 'secondDegreeContacts';

export type Connections = NonNullable<
  Omit<NonNullable<GetJobContactsQuery['viewer']>, 'id' | '__typename'>
>;
type FirstDegreeCurrentContacts = NonNullable<
  Connections[typeof FIRST_DEGREE_CURRENT]
>['edges'];
type FirstDegreeFormerContacts = NonNullable<
  Connections[typeof FIRST_DEGREE_FORMER]
>['edges'];
export type SecondDegreeContacts = NonNullable<
  Connections[typeof SECOND_DEGREE]
>['edges'];

export type ConnectionsEdges = {
  [FIRST_DEGREE_CURRENT]: FirstDegreeCurrentContacts;
  [FIRST_DEGREE_FORMER]: FirstDegreeFormerContacts;
  [SECOND_DEGREE]: SecondDegreeContacts;
};

export type ConnectionType =
  | typeof FIRST_DEGREE_CURRENT
  | typeof FIRST_DEGREE_FORMER
  | typeof SECOND_DEGREE;

export type Contact = Omit<JobDetailsContact, 'trackingToken' | 'user'> & {
  user: XingId & ContactFragment;
};

export type ExtendedContactUser = ContactFragment &
  Pick<XingId, 'clickReasonProfileUrlFromEntity'> & {
    connectionDegree: number;
    url?: string;
  };

type ConnectedContact = Omit<JobDetailsContact, 'trackingToken' | 'user'> & {
  user: ExtendedContactUser;
};

export type ExtendedContact = Omit<
  JobDetailsContact,
  'trackingToken' | 'user'
> & {
  user: ExtendedContactUser;
  position: number;
  companyMembership?: CompanyMembership;
  connectedBy?: ConnectedContact[];
};

export const getMessengerContextId = (
  companyMembership?: CompanyMembership
): string =>
  `jobs_contact_${
    companyMembership === CompanyMembership.Previous ? 'former' : 'current'
  }_employee`;

export const decorateContact = (
  criteria: ConnectionType,
  contact: Contact
): ExtendedContact => {
  switch (criteria) {
    case FIRST_DEGREE_CURRENT:
      return {
        ...contact,
        user: { ...contact.user, connectionDegree: 1 },
        companyMembership: CompanyMembership.Current,
      };
    case FIRST_DEGREE_FORMER:
      return {
        ...contact,
        user: { ...contact.user, connectionDegree: 1 },
        companyMembership: CompanyMembership.Previous,
      };
    default:
      return { ...contact, user: { ...contact.user, connectionDegree: 2 } };
  }
};
