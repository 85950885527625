/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotCareerAssessment = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotCareerAssessment"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.702 58.167 4.696 6.834A2.833 2.833 0 0 1 7.53 4h40.316a2.833 2.833 0 0 1 2.833 2.834v.017l-.32 51.33a2.833 2.833 0 0 1-2.833 2.817L7.535 61a2.834 2.834 0 0 1-2.833-2.833"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="M47.845 3.001a3.833 3.833 0 0 1 3.832 3.836l-.014 2.252a3.835 3.835 0 0 1 3.009 3.55l.005.197-.298 48.352a3.83 3.83 0 0 1-3.635 3.803l-.197.005L13.535 65a3.834 3.834 0 0 1-3.742-3H7.535a3.83 3.83 0 0 1-3.832-3.832L3.697 6.834a3.84 3.84 0 0 1 3.63-3.827L7.53 3zm0 1.997H7.557l-.14.004a1.835 1.835 0 0 0-1.722 1.831L5.7 58.167C5.7 59.18 6.522 60 7.535 60L47.525 60a1.834 1.834 0 0 0 1.834-1.823l.32-51.328-.006-.165a1.835 1.835 0 0 0-1.828-1.685"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M27.196 11.75c8.679 0 15.799 7.04 16.5 16l-8.25.001c0-4.713-3.694-8.534-8.25-8.534-4.557 0-8.25 3.82-8.25 8.534h-8.25c.701-8.96 7.82-16 16.5-16"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="M21.696 34.751v1.998h-11V34.75zm7 5v1.998h-18V39.75zm-2 7v1.998h-16V46.75zm-1 5v1.998h-15V51.75zm8-17v1.998h-10V34.75zm1.15-19.385a2.5 2.5 0 0 1-1.531 4.65l-4.254 7.368a.999.999 0 0 1-1.783-.895l.053-.104 4.253-7.368a2.5 2.5 0 0 1 3.262-3.651"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m60.176 26.792-8.88-8.898-23.081 24.233-2.391 10.16a.36.36 0 0 0 .43.43l10.16-2.39z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="m50.858 17.273 1.436-1.437a7.035 7.035 0 0 1 9.949 9.949l-1.47 1.47c-1.003 1.004-10.93-8.969-9.916-9.982m-24.93 30.313 4.347 4.547-4.994 1.241z"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M54.447 21.246 30.699 44.994l2.386 2.386 23.748-23.748z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="m51.676 16.449 9.954 9.954-25.131 25.131-10.629 2.43c-.986.236-1.875-.654-1.64-1.613l2.31-10.766zM28.045 44.086l-1.654 7.71 7.62-1.743zm23.63-24.813-22.81 22.809 7.13 7.13 22.81-22.809z"
      />
    </svg>
  );
};
export default IllustrationSpotCareerAssessment;
