import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { PopUpWindowPrimitive } from './pop-up-window.primitive';
import * as Styled from './pop-up-window.styles';
import type { PopUpWindowProps } from './pop-up-window.types';

const {
  dimmerDuration,
  dimmerTransitionName,
  popUpWindowDuration,
  popUpWindowTransitionName,
} = Styled;

export const PopUpWindow = React.forwardRef<HTMLDivElement, PopUpWindowProps>(
  (
    {
      children,
      className,
      closeButtonProps,
      hide,
      isShown,
      noPadding,
      onDimmerClick,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const disableScrolling = (): void => {
      document.body.style.overflow = 'hidden';
    };
    const enableScrolling = (): void => {
      document.body.style.overflow = 'visible';
    };

    const onKeyDown = (event: KeyboardEvent): void => {
      // Close window by pressing ESC
      if (hide && event.key === 'Escape' && isShown) {
        isShown = false;
        hide();
      }
    };

    const onEnter = (): void => {
      disableScrolling();
      document.addEventListener('keydown', onKeyDown, false);
    };

    const onExited = (): void => {
      enableScrolling();
      document.removeEventListener('keydown', onKeyDown, false);
    };

    React.useEffect(() => {
      if (isShown) {
        onEnter();
      }

      return () => {
        onExited();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShown]);

    const DimmerTransition = (
      <CSSTransition
        appear={isShown}
        classNames={dimmerTransitionName}
        in={isShown}
        key="dimmer"
        mountOnEnter
        timeout={parseInt(dimmerDuration)}
        unmountOnExit
      >
        <Styled.Dimmer
          data-qa="pop-up-window-dimmer"
          data-testid="pop-up-window-dimmer"
          onClick={onDimmerClick}
        />
      </CSSTransition>
    );

    const PopUpWindowTransition = (
      <CSSTransition
        appear={isShown}
        classNames={popUpWindowTransitionName}
        in={isShown}
        key="popUpWindow"
        mountOnEnter
        timeout={parseInt(popUpWindowDuration)}
        unmountOnExit
      >
        <PopUpWindowPrimitive
          closeButtonProps={closeButtonProps}
          hide={hide}
          noPadding={noPadding}
          ref={forwardedRef}
        >
          {children}
        </PopUpWindowPrimitive>
      </CSSTransition>
    );

    return (
      <React.Fragment>
        <Portal>
          <Styled.Wrapper
            aria-labelledby="overlay content"
            aria-modal
            className={className}
            role="dialog"
            tabIndex={-1}
            {...props}
          >
            {PopUpWindowTransition}
          </Styled.Wrapper>
        </Portal>
        {DimmerTransition}
      </React.Fragment>
    );
  }
);

PopUpWindow.displayName = 'PopUpWindow';
