// this svg has been extracted manually from ./compass.svg
export function ProfileHandSvg(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg viewBox="0 0 172 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#1D2124" strokeWidth={2} transform="translate(1 1)">
        <path d="M17 8h155" />
        <circle fill="#1D2124" fillRule="nonzero" cx={8} cy={8} r={8} />
      </g>
    </svg>
  );
}
