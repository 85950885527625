/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetSimilarJobsQueryVariables = Types.Exact<{
  jobId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
  consumer: Types.Scalars['String']['input'];
}>;

export type GetSimilarJobsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    similarJobs?: {
      __typename?: 'JobRecommendationsSimilarJobs';
      collection: Array<{
        __typename?: 'JobRecommendationsSimilarJob';
        trackingToken: string;
        position: number;
        job?:
          | { __typename?: 'UnauthorizedJob' }
          | {
              __typename?: 'VisibleJob';
              id: string;
              slug: string;
              url: string;
              title: string;
              activatedAt?: string | null;
              refreshedAt: string;
              globalId: string;
              matchingHighlights?: {
                __typename?: 'JobMatchingHighlightsV2';
                token: string;
                highlight?: {
                  __typename?: 'JobMatchingHighlightsJobHighlightV2';
                  type: string;
                  localization: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  };
                  localizationA11y?: {
                    __typename?: 'JobMatchingHighlightsLocalizableV2';
                    localizationValue: string;
                  } | null;
                } | null;
                matchingFacts: Array<
                  | {
                      __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
                      type: string;
                      localization: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      };
                      localizationA11y?: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      } | null;
                    }
                  | {
                      __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
                      type: string;
                      value:
                        | {
                            __typename?: 'Salary';
                            currency?: Types.Currency | null;
                            amount?: number | null;
                          }
                        | {
                            __typename?: 'SalaryEstimate';
                            currency?: Types.Currency | null;
                            minimum?: number | null;
                            maximum?: number | null;
                            median?: number | null;
                          }
                        | {
                            __typename?: 'SalaryRange';
                            currency?: Types.Currency | null;
                            minimum?: number | null;
                            maximum?: number | null;
                          }
                        | { __typename?: 'SalaryUnauthorized' };
                      localization: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      };
                      localizationA11y?: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      } | null;
                    }
                  | {
                      __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
                      type: string;
                      localization: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      };
                      localizationA11y?: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      } | null;
                    }
                >;
                nonMatchingFacts: Array<
                  | {
                      __typename: 'JobMatchingHighlightsJobKeyfactIntV2';
                      type: string;
                      localization: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      };
                      localizationA11y?: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      } | null;
                    }
                  | {
                      __typename: 'JobMatchingHighlightsJobKeyfactSalaryV2';
                      type: string;
                      value:
                        | {
                            __typename?: 'Salary';
                            currency?: Types.Currency | null;
                            amount?: number | null;
                          }
                        | {
                            __typename?: 'SalaryEstimate';
                            currency?: Types.Currency | null;
                            minimum?: number | null;
                            maximum?: number | null;
                            median?: number | null;
                          }
                        | {
                            __typename?: 'SalaryRange';
                            currency?: Types.Currency | null;
                            minimum?: number | null;
                            maximum?: number | null;
                          }
                        | { __typename?: 'SalaryUnauthorized' };
                      localization: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      };
                      localizationA11y?: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      } | null;
                    }
                  | {
                      __typename: 'JobMatchingHighlightsJobKeyfactStringV2';
                      type: string;
                      localization: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      };
                      localizationA11y?: {
                        __typename?: 'JobMatchingHighlightsLocalizableV2';
                        localizationValue: string;
                      } | null;
                    }
                >;
              } | null;
              userInteractions?: {
                __typename?: 'JobUserInteractions';
                bookmark?: {
                  __typename?: 'JobBookmark';
                  state: Types.JobBookmarkState;
                } | null;
              } | null;
              location?: {
                __typename?: 'JobLocation';
                city?: string | null;
              } | null;
              employmentType?: {
                __typename?: 'EmploymentType';
                localizationValue: string;
              } | null;
              companyInfo: {
                __typename?: 'JobCompanyInfo';
                companyNameOverride: string;
                company?: {
                  __typename?: 'Company';
                  id: string;
                  logos?: {
                    __typename?: 'CompanyLogos';
                    x1?: string | null;
                  } | null;
                  kununuData?: {
                    __typename?: 'CompanyKununuData';
                    ratingAverage?: number | null;
                  } | null;
                } | null;
              };
              salary?:
                | {
                    __typename?: 'Salary';
                    currency?: Types.Currency | null;
                    amount?: number | null;
                  }
                | {
                    __typename?: 'SalaryEstimate';
                    currency?: Types.Currency | null;
                    minimum?: number | null;
                    maximum?: number | null;
                    median?: number | null;
                  }
                | {
                    __typename?: 'SalaryRange';
                    currency?: Types.Currency | null;
                    minimum?: number | null;
                    maximum?: number | null;
                  }
                | { __typename?: 'SalaryUnauthorized' }
                | null;
            }
          | null;
      }>;
    } | null;
  } | null;
};

export const GetSimilarJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSimilarJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consumer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'similarJobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'jobId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'limit' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'consumer' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SimilarJobsCollectionItem',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Salary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Salary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryRange' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryRange' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryEstimate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryEstimate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'median' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisibleJobCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameOverride' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'x1' },
                              name: { kind: 'Name', value: 'logo96px' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Salary' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryRange' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryEstimate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'VisibleJobCommon' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userInteractions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmark' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobKeyfactV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsJobKeyfactV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localizationA11y' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'JobMatchingHighlightsJobKeyfactSalaryV2',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Salary' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryRange' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalaryEstimate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobMatchingHighlightsV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highlight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationA11y' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonMatchingFacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobKeyfactV2' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimilarJobsCollectionItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobRecommendationsSimilarJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobTeaserVisibleJob' },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VisibleJob' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchingHighlights' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'context' },
                            value: { kind: 'EnumValue', value: 'postings' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'JobMatchingHighlightsV2',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSimilarJobsQuery, GetSimilarJobsQueryVariables>;
