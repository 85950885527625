import type { JobSalary } from '@xing-com/crate-common-graphql-types';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { JobFactsData, JobHighlights } from '@xing-com/crate-jobs-helpers';

import { Highlights } from './highlights';
import { JobFacts } from './job-facts';

type Props = {
  highlights: JobHighlights;
  jobFacts?: JobFactsData;
  salary?: JobSalary;
};
export const JobMetadata: React.FC<Props> = ({
  jobFacts,
  highlights,
  salary,
}) => {
  const { isLoggedIn } = useLoginState();
  return (
    <>
      <JobFacts
        jobFacts={jobFacts}
        salary={
          !isLoggedIn || !highlights.isMatchingSalary ? salary : undefined
        }
      />
      <Highlights highlights={highlights} salary={salary} />
    </>
  );
};
