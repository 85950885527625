import {
  JDP_SA_DEFAULT_QUERY,
  JDP_SA_SEARCH_QUERY,
} from '@xing-com/crate-jobs-constants';
import type { SearchAlertFilters } from '@xing-com/crate-jobs-helpers';

import { useNavigationState } from './use-navigation-state';

export const useSearchAlertParams = (): SearchAlertFilters | undefined => {
  const state = useNavigationState();
  return state && (state[JDP_SA_SEARCH_QUERY] || state[JDP_SA_DEFAULT_QUERY]);
};
