import styled from 'styled-components';

import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Headline, Hero } from '@xing-com/typography';

export const StyledHeadline = styled(Headline)`
  ${lineClamp(4)}

  @media ${mediaConfined} {
    display: none;
    flex-grow: 1;
  }
`;
export const StyledHero = styled(Hero)`
  ${lineClamp(3)}
  display: none;

  @media ${mediaConfined} {
    display: block;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
`;
