/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconInstagram"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.197 22h-.199a70 70 0 0 1-4.425-.121c-1.292-.08-2.47-.525-3.41-1.29-.906-.74-1.524-1.738-1.839-2.97-.273-1.071-.288-2.123-.301-3.141-.01-.73-.02-1.595-.023-2.475.002-.884.013-1.75.023-2.48.013-1.016.028-2.07.301-3.14.315-1.232.933-2.23 1.84-2.97.938-.764 2.116-1.21 3.409-1.29a70 70 0 0 1 4.428-.122 70 70 0 0 1 4.425.122c1.292.079 2.47.525 3.41 1.29.906.74 1.524 1.738 1.839 2.97.273 1.07.288 2.124.301 3.14.01.73.021 1.596.023 2.476v.004c-.002.88-.013 1.745-.023 2.475-.013 1.017-.028 2.07-.301 3.14-.315 1.233-.933 2.23-1.84 2.97-.939.766-2.117 1.212-3.409 1.29a69 69 0 0 1-4.229.123m-.199-1.562a68 68 0 0 0 4.334-.12c.977-.058 1.823-.375 2.517-.941.641-.523 1.083-1.244 1.313-2.144.227-.892.24-1.85.253-2.776.01-.726.02-1.585.022-2.456-.002-.871-.012-1.73-.022-2.456-.013-.926-.026-1.884-.253-2.776-.23-.9-.672-1.622-1.313-2.145-.694-.565-1.54-.882-2.517-.941a68 68 0 0 0-4.33-.12 68 68 0 0 0-4.334.12c-.977.059-1.823.376-2.517.94-.641.524-1.083 1.246-1.313 2.146-.227.892-.24 1.85-.253 2.776a216 216 0 0 0-.022 2.458c.002.868.012 1.728.022 2.454.013.926.026 1.884.253 2.776.23.9.672 1.62 1.313 2.144.694.566 1.54.883 2.517.942a68 68 0 0 0 4.33.119m-.037-3.554A4.89 4.89 0 0 1 7.078 12a4.89 4.89 0 0 1 4.882-4.883A4.89 4.89 0 0 1 16.844 12a4.89 4.89 0 0 1-4.883 4.883m0-8.203A3.325 3.325 0 0 0 8.64 12c0 1.83 1.489 3.32 3.32 3.32s3.32-1.49 3.32-3.32-1.49-3.32-3.32-3.32zm5.43-3.125a1.172 1.172 0 1 0-.069 2.342 1.172 1.172 0 0 0 .069-2.342"
    />
  </svg>
);
export default IconInstagram;
