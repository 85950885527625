import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Card } from '@xing-com/card';
import { Carousel } from '@xing-com/carousel';
import { CompanyMembership } from '@xing-com/crate-common-graphql-types';
import type { CompanyData } from '@xing-com/crate-jobs-helpers';
import {
  addContactTracking,
  getMessengerContextId,
  type ExtendedContact,
} from '@xing-com/crate-jobs-helpers';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { JobContact } from '@xing-com/crate-jobs-xinglets';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { GridContainer, GridItem } from '@xing-com/grid';
import { IconArrowRight } from '@xing-com/icons';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceL, spaceM } from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

type Props = {
  jobSlug: string;
  contacts: Array<ExtendedContact>;
  hasMoreContacts: boolean;
  company?: CompanyData;
};

const MobileCompanyContacts = styled.div`
  @media ${mediaConfined} {
    display: none;
  }
`;

const CompanyContacts = styled.div`
  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

export const CompanyContactsModule: React.FC<Props> = ({
  jobSlug,
  contacts,
  hasMoreContacts,
  company,
}) => {
  const { formatMessage } = useIntl();

  if (!company) return null;

  const title = formatMessage({
    id: 'JOBS_JOB_DETAILS_COMPANY_CONTACTS_MODULE_TITLE',
  });
  const subtitle = formatMessage({
    id: 'JOBS_JOB_DETAILS_COMPANY_CONTACTS_MODULE_SUBTITLE',
  });

  const toContacts = generatePath(ROUTES.jobContacts, {
    slug: jobSlug,
  });

  const decorateContactWithModuleTracking = addContactTracking(
    'jobs_profile_other_visit_contact_module'
  );

  const getHint = (companyMembership?: CompanyMembership): string =>
    formatMessage(
      {
        id: `JOBS_JOB_DETAILS_JOB_CONTACT_${
          companyMembership === CompanyMembership.Previous
            ? 'PREVIOUS'
            : 'CURRENT'
        }_COMPANY`,
      },
      { company: company.name }
    );

  const Contact = (contact: ExtendedContact): React.ReactNode =>
    contact.user?.connectionDegree === 1 ? (
      <JobContact
        connectionDegree={1}
        contact={decorateContactWithModuleTracking(contact)}
        messengerContextId={getMessengerContextId(contact?.companyMembership)}
        entryPoint="jobs_job_detail_contact"
        hint={getHint(contact.companyMembership)}
      />
    ) : (
      <JobContact
        connectionDegree={2}
        contact={decorateContactWithModuleTracking(contact)}
      />
    );

  return (
    <NoSSR>
      <MobileCompanyContacts data-testid="company-contacts-module-mobile">
        <Headline size="large">{title}</Headline>
        <BodyCopy size="medium">{subtitle}</BodyCopy>
        <Carousel
          itemSpacing={spaceM}
          itemWidth="45%"
          paddingBottom={spaceL}
          itemSnapPosition="left"
        >
          {contacts.map((contact) =>
            contact ? (
              <Card key={contact.user?.id} minHeight="100%">
                {Contact(contact)}
              </Card>
            ) : null
          )}
        </Carousel>
      </MobileCompanyContacts>
      <CompanyContacts data-testid="company-contacts-module-desktop">
        <Headline size="large">{`${title} ${subtitle}`}</Headline>
        <GridContainer>
          {contacts.map((contact) => (
            <GridItem
              key={contact.user?.id}
              span={contact.user?.connectionDegree === 1 ? 4 : 8}
            >
              {Contact(contact)}
            </GridItem>
          ))}
          {hasMoreContacts && (
            <GridItem span={12}>
              <TextButton
                // @ts-expect-error FIXME: SC6
                small="medium"
                icon={IconArrowRight}
                iconAfter
                to={toContacts}
                data-testid="company-contacts-module-show-more"
              >
                <FormattedMessage id="JOBS_JOB_DETAILS_COMPANY_CONTACTS_SHOW_ALL" />
              </TextButton>
            </GridItem>
          )}
        </GridContainer>
      </CompanyContacts>
    </NoSSR>
  );
};
