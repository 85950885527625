import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Card as CardComponent } from '@xing-com/card';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { IconArrowRight } from '@xing-com/icons';
import { IllustrationSpotCareerAssessment } from '@xing-com/illustrations';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { spaceL, spaceM, spaceXL, spaceXXL } from '@xing-com/tokens';
import { Meta as MetaComponent } from '@xing-com/typography';

import { ModuleTitle } from '../module-title';

const CULTURE_ASSESSMENT_URL =
  '/career-guidance/company-culture-preferences?sc_o=jobs_jobdetail_move_on_culture_assessment';

const Card = styled(CardComponent)`
  width: 100%;
  margin: ${spaceXL} 0 0 0;
  padding: ${spaceL} ${spaceXL};

  @media ${mediaConfined} {
    padding: ${spaceXL} ${spaceXXL};
    margin: ${spaceXXL} 0 0 0;
  }

  @media ${mediaWide} {
    padding: ${spaceXL} ${spaceXXL};
    margin: ${spaceXXL} 0 ${spaceL} 0;
  }

  @media print {
    display: none;
  }

  & > div {
    display: flex;
    flex-direction: row;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-left: ${spaceM};
`;

const Meta = styled(MetaComponent)`
  margin-bottom: ${spaceM};
`;

export const CultureAssessment: React.FC = () => {
  const { isLoggedIn } = useLoginState();

  return isLoggedIn ? (
    <Card href={CULTURE_ASSESSMENT_URL} linkProps={{ target: '_blank' }}>
      <IllustrationSpotCareerAssessment />
      <Content>
        <ModuleTitle
          titleKey="JOBS_JOB_DETAILS_COMPANY_CULTURE_ASSESSMENT_CTA_HEADER"
          size="large"
          noMargin
        />

        <Meta noMargin>
          <FormattedMessage id="JOBS_JOB_DETAILS_COMPANY_CULTURE_ASSESSMENT_CTA_META" />
        </Meta>
        <TextButton
          href={CULTURE_ASSESSMENT_URL}
          // @ts-expect-error FIXME: SC6
          target="_blank"
          icon={IconArrowRight}
          iconAfter
        >
          <FormattedMessage id="JOBS_JOB_DETAILS_COMPANY_CULTURE_ASSESSMENT_CTA_BODY_COPY" />
        </TextButton>
      </Content>
    </Card>
  ) : null;
};
