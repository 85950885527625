import styled, { css } from 'styled-components';

import type { DescriptionFragment } from '@xing-com/crate-jobs-graphql';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { spaceXXL } from '@xing-com/tokens';

import { useExternalContentConsent } from '../../hooks';
import { ModuleTitle } from '../module-title';
import { ExternalUrlDescription } from './external-url-description';
import { HtmlDescription } from './html-description';
import { LoadingPlaceholder } from './loading-placeholder';
import { PersonalwerkTracking } from './personalwerk-tracking';
import { TemplateDataDescription } from './template-data-description';

type DescriptionModuleProps = {
  description: DescriptionFragment;
  banner?: string;
  jobCode?: string | null;
  isThirdParty?: boolean;
};

type DescriptionProps = {
  description: DescriptionFragment;
  externalContentConsent: boolean;
  skipShowMore: boolean;
};

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceXXL};
`;

const DescriptionBanner = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

const BlurWrapper = styled.div<{ $isBlur: boolean }>`
  ${({ $isBlur }) =>
    $isBlur &&
    css`
      filter: blur(3px);
      pointer-events: none;
      user-select: none;
    `};
`;

const isPersonalwerkJob = (
  jobCode: string | null | undefined
): jobCode is string => {
  return typeof jobCode === 'string' && /personalwerk-\d+-\d+/.test(jobCode);
};

const Description: React.FC<DescriptionProps> = ({
  description,
  externalContentConsent,
  skipShowMore,
}) => {
  switch (description.__typename) {
    case 'HtmlDescription':
      return (
        <HtmlDescription
          description={description}
          skipShowMore={skipShowMore}
        />
      );
    case 'ExternalUrlDescription':
      return (
        <ExternalUrlDescription
          url={description.url}
          proxyUrl={description.proxyUrl}
          externalContentConsent={externalContentConsent}
        />
      );
    case 'TemplateData':
      return (
        <TemplateDataDescription
          description={description}
          externalContentConsent={externalContentConsent}
          skipShowMore={skipShowMore}
        />
      );
    default:
      return 'UNSUPPORTED';
  }
};

export const DescriptionModule: React.FC<DescriptionModuleProps> = ({
  description,
  banner,
  jobCode,
  isThirdParty = false,
}) => {
  const { loading: externalContentConsentLoading, hasConsent } =
    useExternalContentConsent();

  if (externalContentConsentLoading) {
    return <LoadingPlaceholder />;
  }

  return (
    <DescriptionWrapper>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_DESCRIPTION_HEADLINE" noMargin />
      {hasConsent && isPersonalwerkJob(jobCode) ? (
        <NoSSR>
          <PersonalwerkTracking jobCode={jobCode} />
        </NoSSR>
      ) : null}
      <BlurWrapper $isBlur={isThirdParty}>
        {banner ? (
          <DescriptionBanner src={banner} alt="" aria-hidden="true" />
        ) : null}
        <Description
          description={description}
          externalContentConsent={hasConsent ?? false}
          skipShowMore={isThirdParty}
        />
      </BlurWrapper>
    </DescriptionWrapper>
  );
};
