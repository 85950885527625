import type { JobAgeKey, JobHighlights } from './job-details-types';

const JobAgeHighlightMapping: Record<JobAgeKey, string> = {
  JOBS_HIGHLIGHT_EARLY_APPLICANT: 'job_tag_early_applicant',
  JOBS_HIGHLIGHT_ACTIVELY_RECRUITING: 'job_tag_actively_recruiting',
  JOBS_HIGHLIGHT_LAST_CHANCE_TO_APPLY: 'job_tag_last_chance_to_apply',
} as const;

const RemoteHighlightMapping: Record<string, string> = {
  PARTLY_REMOTE: 'job_tag_hybrid',
  FULL_REMOTE: 'job_tag_remote',
  NON_REMOTE: 'job_tag_on_site',
};

export const getHighlightsTrackingData = (
  jobHighlights: JobHighlights,
  isVerifiedByXing: boolean
): string => {
  const {
    isApplyWithoutDocuments,
    matchingJobAgeKey,
    isMatchingSalary,
    remoteOption,
  } = jobHighlights;

  const result = [
    isMatchingSalary && 'job_tag_salary',
    isApplyWithoutDocuments && 'job_tag_instant_apply',
    remoteOption && RemoteHighlightMapping[remoteOption],
    matchingJobAgeKey && JobAgeHighlightMapping[matchingJobAgeKey],
    isVerifiedByXing && 'job_tag_xing_verified',
  ].filter(Boolean); // Removes undefined values

  return result.length > 0 ? result.join(',') : 'job_tag_none';
};
