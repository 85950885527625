/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconSalary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconSalary"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18 8v12H2V8zm-4 2H6a2 2 0 0 1-1.85 1.995L4 12v4a2 2 0 0 1 1.995 1.85L6 18h8a2 2 0 0 1 1.85-1.994L16 16v-4a2 2 0 0 1-2-2m-4 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4m12-8v12h-2V5.999L6 6V4z"
    />
  </svg>
);
export default IconSalary;
