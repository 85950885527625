import type React from 'react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import type { ApplicationData } from '@xing-com/crate-jobs-helpers';
import { mediaConfined } from '@xing-com/layout-tokens';

import { useApplicationTypeHelpers } from '../../../../hooks';
import { useApplyButtonDefaultProps } from './use-apply-button-default-props';
import { useApplyButtonEmailApplyProps } from './use-apply-button-email-apply-props';
import { useApplyButtonMessageApplyProps } from './use-apply-button-message-apply-props';
import { useApplyButtonUrlApplyProps } from './use-apply-button-url-apply-props';
import { useApplyButtonXingApplyProps } from './use-apply-button-xing-apply-props';

type Props = {
  application: ApplicationData;
  isHeaderSticky?: boolean;
  variant?: 'primary' | 'secondary';
};

const Apply = styled(Button)<{ $isHeaderSticky: boolean }>`
  width: 100%;

  @media ${mediaConfined} {
    width: ${({ $isHeaderSticky }) =>
      $isHeaderSticky ? 'max-content' : '100%'};
  }
`;

export const ApplyButton: React.FC<Props> = ({
  application,
  isHeaderSticky = false,
  variant = 'primary',
}) => {
  const {
    type,
    applyUrl,
    applyEmail,
    contactUserId,
    code,
    jobId,
    prioritized,
    slug,
    title,
  } = application;

  const {
    isXingApplication,
    isEmailApplication,
    isMessageApplication,
    isUrlApplication,
  } = useApplicationTypeHelpers(type);

  const label = useMemo(() => {
    if (isXingApplication) return 'JOBS_JOB_DETAILS_XING_APPLY';
    if (isUrlApplication) return 'JOB_POSTING_APPLY_EXTERNAL_URL';
    return 'JOBS_JOB_DETAILS_APPLY';
  }, [isXingApplication, isUrlApplication]);

  const defaultProps = useApplyButtonDefaultProps(slug);
  const xingApplyProps = useApplyButtonXingApplyProps(slug, jobId);
  const messageApplyProps = useApplyButtonMessageApplyProps({
    contactUserId,
    jobId,
  });
  const urlApplyProps = useApplyButtonUrlApplyProps({
    jobId,
    slug,
    applyUrl,
    isPrioritizedJob: prioritized,
  });
  const emailApplyProps = useApplyButtonEmailApplyProps({
    code,
    applyEmail,
    title,
    jobId,
  });

  return (
    <Apply
      variant={variant}
      size="medium"
      $isHeaderSticky={isHeaderSticky}
      {...defaultProps}
      {...(isEmailApplication && emailApplyProps)}
      {...(isMessageApplication && messageApplyProps)}
      {...(isUrlApplication && urlApplyProps)}
      {...(isXingApplication && xingApplyProps)}
    >
      <FormattedMessage id={label} />
    </Apply>
  );
};
