import { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Make sure the iframe is as large as the page in order for scroll tracking to work
const ScriptContainer = styled.iframe`
  border: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
`;

type PersonalwerkTrackingProps = {
  jobCode: string;
};

type PersonalwerkTrackingParams = {
  page?: string;
  channel?: string;
};

const getPersonalwerkTrackingParams = (
  jobCode: string
): PersonalwerkTrackingParams => {
  const matchGroups = jobCode.match(
    /personalwerk-(?<page>\d+)-(?<channel>\d+)/
  )?.groups;

  return { page: matchGroups?.page, channel: matchGroups?.channel };
};

export const PersonalwerkTracking: React.FC<PersonalwerkTrackingProps> = ({
  jobCode,
}) => {
  const [title, setTitle] = useState('');
  const iframe = useRef<HTMLIFrameElement>(null);
  const { page, channel } = getPersonalwerkTrackingParams(jobCode);

  const forwardEventToIFrame = (event: Event): void => {
    const forwardedEvent = new Event(event.type, event);

    // Forward the target of click events only if the event happened in the JDP
    if (event.type === 'click') {
      if (
        event.target instanceof Node &&
        iframe.current?.parentNode?.contains(event.target)
      ) {
        Object.defineProperty(forwardedEvent, 'target', {
          writable: false,
          value: event.target,
        });
      } else {
        return;
      }
    }

    iframe.current?.contentDocument?.dispatchEvent(forwardedEvent);
  };

  // Forward all the relevant events into the iframe so that Personalwerk's
  // tracking script can detect them.
  useLayoutEffect(() => {
    if (iframe.current !== null) {
      iframe.current.style.top = `-${globalThis.window.innerHeight}px`;
      iframe.current.style.left = `-${globalThis.window.innerWidth}px`;
      iframe.current.style.width = `${globalThis.window.innerWidth}px`;
      iframe.current.style.height = `${globalThis.window.innerHeight}px`;

      document.addEventListener('scroll', forwardEventToIFrame);
      document.addEventListener('click', forwardEventToIFrame);
      document.addEventListener('visibilitychange', forwardEventToIFrame);
    }

    // We can only set the title in the client because window is not available
    // server-side.
    setTitle(globalThis.window.document.title);

    return () => {
      document.removeEventListener('scroll', forwardEventToIFrame);
      document.removeEventListener('click', forwardEventToIFrame);
      document.removeEventListener('visibilitychange', forwardEventToIFrame);
    };
  }, [iframe, title]);

  return (
    <ScriptContainer
      ref={iframe}
      src="about:blank"
      srcDoc={`
        <html>
          <head>
            <title>${title}</title>
            <script type="text/javascript" data-testid="tracking-script">
              var _pt=_pt||{};(function(){var u="//count.mediaintown.de/";_pt['PAGE']=${page};_pt['CHANNEL']=${channel};var d=document,g=d.createElement("script"),s=d.getElementsByTagName("script")[0];g.type="text/javascript";g.defer=true;g.async=true;g.src=u+"tracker.min.js";s.parentNode.insertBefore(g,s);})();
            </script>
          </head>
        </html>
      `}
      sandbox="allow-scripts allow-same-origin"
      referrerPolicy="strict-origin-when-cross-origin"
      data-testid="personalwerk-tracking"
    />
  );
};
