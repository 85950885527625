import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { useHost } from '@xing-com/crate-xinglet';
import { useExperiment } from '@xing-com/hub';

import DefaultImage from './assets/og-xing-logo.png';

type JobMetadata = {
  job: JobDetails;
};

export const Metadata: React.FC<JobMetadata> = ({ job }) => {
  const { formatMessage, formatDate } = useIntl();
  const { isLoggedOut } = useLoginState();
  const { getHostname } = useHost();
  const isRefreshExperimentActive = useExperiment('ABACUS-469') === 'B';

  const {
    activatedAt,
    careerLevel,
    company,
    facts: { employmentType },
    industry,
    keywords,
    location: { city },
    paid,
    refreshedAt,
    serviceOfferingGroup,
    title: jobTitle,
    url,
  } = job;

  const showRefreshedAt =
    paid && serviceOfferingGroup > 0 && isRefreshExperimentActive; //serviceOfferingGroup > 0 means it is a top paid job
  const date = showRefreshedAt ? refreshedAt : (activatedAt ?? '');

  const title = `${formatMessage(
    {
      id: isLoggedOut
        ? 'JOBS_JOB_DETAILS_SEO_TITLE_TAG_LOGGED_OUT'
        : 'JOBS_JOB_DETAILS_SEO_TITLE_TAG_LOGGED_IN',
    },
    { job_title: jobTitle, work_location: city }
  )} | ${formatMessage({ id: 'JOBS_JOB_DETAILS_TITLEBAR_SUFFIX' })}`;

  const metaTitle = `${jobTitle} | ${formatMessage({
    id: 'JOBS_JOB_DETAILS_TITLEBAR_SUFFIX',
  })}`;

  const metaDescription = formatMessage(
    { id: 'JOBS_JOB_DETAILS_SEO_META_DESCRIPTION_TAG_POSTING_DETAIL' },
    {
      function: jobTitle,
      company_name: company?.name,
      city,
      industry: industry?.name,
      job_type: employmentType?.name,
      level: careerLevel?.name,
      date: formatDate(date, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    }
  );

  const defaultPageTitle = formatMessage({
    id: 'JOBS_JOB_DETAILS_SE_DEFAULT_PAGETITLE',
  });
  const defaultPageTitleSuffix = formatMessage({
    id: 'JOBS_JOB_DETAILS_SE_DEFAULT_PAGETITLE_SUFFIX',
  });
  const defaultTitle = `${defaultPageTitle} | ${defaultPageTitleSuffix}`;
  const defaultOgTitle = title ?? `${defaultPageTitle}`;

  const basicMeta = [
    ...(metaDescription
      ? [{ name: 'description', content: metaDescription }]
      : []),
    ...(keywords && keywords.length > 0
      ? [{ name: 'keywords', content: keywords.toString() }]
      : []),
  ];

  const openGraphMeta = [
    { property: 'og:title', content: metaTitle ?? defaultOgTitle },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: 'XING' },
    {
      property: 'og:image',
      content: company?.logo ?? `${getHostname()}${DefaultImage}`,
    },
    ...(url ? [{ property: 'og:url', content: url }] : []),
    ...(metaDescription
      ? [{ property: 'og:description', content: metaDescription }]
      : []),
  ];

  const twitterCardMeta = [
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: '@XING_de' },
  ];

  const metaRobots = [{ name: 'robots', content: 'index, follow' }];

  const meta = [
    ...basicMeta,
    ...openGraphMeta,
    ...twitterCardMeta,
    ...metaRobots,
  ];

  return (
    <Helmet
      title={title ?? defaultTitle}
      meta={meta}
      link={[{ rel: 'canonical', href: url }]}
    />
  );
};
