import type { VisibleJob } from '@xing-com/crate-common-graphql-types';

type GetRevenueTypeContextDimension =
  | 'jobs_click_UNPAID'
  | 'jobs_click_PAID'
  | 'jobs_click_PAID_top_job'
  | 'jobs_click_PAID_1'
  | 'jobs_click_PAID_2'
  | 'jobs_click_PAID_3';

type VisibleJobParam = {
  prioritized: VisibleJob['prioritized'];
  serviceOfferingGroup: VisibleJob['serviceOfferingGroup'];
  topJob: VisibleJob['topJob'];
};

const isValidServiceOfferingGroup = (a: number): a is 1 | 2 | 3 => a !== 0;

export const getRevenueTypeContextDimension = <T extends VisibleJobParam>(
  job: T
): GetRevenueTypeContextDimension => {
  if (!job.prioritized) return 'jobs_click_UNPAID';

  if (isValidServiceOfferingGroup(job.serviceOfferingGroup)) {
    return `jobs_click_PAID_${job.serviceOfferingGroup}`;
  } else {
    return job.topJob ? 'jobs_click_PAID_top_job' : 'jobs_click_PAID';
  }
};
