import type { LazyQueryExecFunction } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

import type { GetUserNeffiScoreQuery } from '@xing-com/crate-jobs-graphql';
import { GetUserNeffiScoreDocument } from '@xing-com/crate-jobs-graphql';

type UseGetLazyNeffiScoreReturnValue = {
  getLazyNeffiScore: LazyQueryExecFunction<
    GetUserNeffiScoreQuery,
    {
      consumer: string;
    }
  >;
  normalizedNeffiScore: number;
  isLoading: boolean;
};

export const useGetLazyNeffiScore = (): UseGetLazyNeffiScoreReturnValue => {
  const [getJobApplicationType, { data, loading, error }] = useLazyQuery(
    GetUserNeffiScoreDocument,
    {
      variables: { consumer: 'loggedin.web.jobs.job_application.center' },
    }
  );

  const neffiScore = error
    ? 0
    : (data?.viewer?.networkEfficiencyIndicator?.score ?? 0);

  const normalizedNeffiScore = Math.round(neffiScore * 100);

  return {
    getLazyNeffiScore: getJobApplicationType,
    normalizedNeffiScore,
    isLoading: loading,
  };
};
