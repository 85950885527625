import styled from 'styled-components';

import { scale080, spaceS } from '@xing-com/tokens';
const ICON_SIZE = 18;

const StyledFact = styled.li`
  display: flex;
  font-size: ${scale080};
  & svg {
    margin-right: ${spaceS};
  }
`;

type Props = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  'data-testid': string;
  children: React.ReactNode;
};

export const JobFact: React.FC<Props> = ({
  Icon,
  children,
  'data-testid': dataTestId,
}) => (
  <StyledFact data-testid={dataTestId}>
    <Icon width={ICON_SIZE} height={ICON_SIZE} />
    {children}
  </StyledFact>
);
