import type { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { StatusBanner } from '@xing-com/banner';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceM, spaceXL } from '@xing-com/tokens';

type Props = PropsWithChildren<{
  headline: ReactNode;
}>;

const Banner = styled(StatusBanner)`
  @media ${mediaSmallOrTiny} {
    margin-bottom: ${spaceXL};
  }
`;

const Headline = styled.span`
  display: block;
  margin-bottom: ${spaceM};
`;

export const NoConsentMessage: React.FC<Props> = ({ headline, children }) => (
  <Banner variant="info" display="inline">
    <Headline>{headline}</Headline>
    {children}
  </Banner>
);
