import styled from 'styled-components';

import { spaceXXL } from '@xing-com/tokens';

type Props = {
  src: string;
  'data-testid'?: string;
};

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto ${spaceXXL} auto;
`;

export const Image: React.FC<Props> = (props) => (
  <StyledImage
    src={props.src}
    data-testid={props['data-testid']}
    alt=""
    aria-hidden="true"
  />
);
