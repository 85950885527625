import styled, { css } from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { SkeletonRectangle } from '@xing-com/skeleton';
import { scale410, spaceL, spaceXXL } from '@xing-com/tokens';

import { CompanyCard } from './company-card';
import { HRContactCard } from './hr-contact-card';

export const CompanyModuleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${spaceL};
`;

const companyCardStyle = css`
  flex-basis: 100%;
  margin-bottom: ${spaceXXL};

  @media ${mediaConfined} {
    flex: 1;
    margin-bottom: 0;
  }
`;

export const StyledCompanyCard = styled(CompanyCard)`
  ${companyCardStyle}
`;

export const StyledCompanyCardSkeleton = styled(SkeletonRectangle)`
  ${companyCardStyle}
`;

const hrContactCardStyle = css`
  flex-basis: 100%;

  @media ${mediaConfined} {
    flex-basis: ${scale410};
  }
`;

export const StyledHRContactCard = styled(HRContactCard)`
  ${hrContactCardStyle}
`;

export const StyledHRContactCardSkeleton = styled(SkeletonRectangle)`
  ${hrContactCardStyle}
`;
