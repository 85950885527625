import type { CompanyKununuCultureDimension } from '@xing-com/crate-common-graphql-types';

type CultureBinary = 'TRADITIONAL' | 'MODERN';
type Weight = 'LIGHT' | 'STRONG' | '';

type ProfileInfoData = {
  isBalanced: boolean;
  cultureBinary: CultureBinary;
  weight: Weight;
};

type IndustryInfoData = {
  isInline: boolean;
  isBalanced: boolean;
  cultureBinary: CultureBinary;
  weight: Weight;
  verb: 1 | 2 | 3 | 4;
};

const MAX_COMPASS_ANGLE_VALUE = 180;
const MAX_SCORE_RATING = 100;
const MIDDLE_SCORE_RATING = 50;
const MAX_BALANCED_VARIATION = 2;
const MAX_LIGHT_WEIGHT_VARIATION = 5;
const MIN_STRONG_WEIGHT_VARIATION = 20;
const MAX_IN_LINE_SCORES_DIFF = 5;
const MIN_NUMBER_OF_REVIEWS = 2;

export const hasCultureInfo = (
  cultureCompass?: CompanyKununuCultureDimension | null,
  totalCultureReviews?: number | null
): boolean => {
  return (
    !!cultureCompass &&
    !!totalCultureReviews &&
    totalCultureReviews >= MIN_NUMBER_OF_REVIEWS
  );
};
const getScoreFromCompassAngle = (angle: number | null | undefined): number => {
  if (!angle) {
    return 0;
  }
  return Math.round((angle / MAX_COMPASS_ANGLE_VALUE) * MAX_SCORE_RATING);
};

const isBalancedScore = (score: number): boolean => {
  return Math.abs(score - MIDDLE_SCORE_RATING) <= MAX_BALANCED_VARIATION;
};

const getCultureBinaryFromScore = (score: number): CultureBinary => {
  return score > MIDDLE_SCORE_RATING ? 'MODERN' : 'TRADITIONAL';
};

const getWeightFromScore = (score: number): Weight => {
  return Math.abs(score - MIDDLE_SCORE_RATING) <= MAX_LIGHT_WEIGHT_VARIATION
    ? 'LIGHT'
    : Math.abs(score - MIDDLE_SCORE_RATING) > MIN_STRONG_WEIGHT_VARIATION
      ? 'STRONG'
      : '';
};

export const getProfileInfoData = (
  profileCompassValue: number | null | undefined
): ProfileInfoData => {
  const profileScore = getScoreFromCompassAngle(profileCompassValue);
  return {
    isBalanced: isBalancedScore(profileScore),
    cultureBinary: getCultureBinaryFromScore(profileScore),
    weight: getWeightFromScore(profileScore),
  };
};

export const getIndustryInfoData = (
  cultureCompassData: CompanyKununuCultureDimension | null | undefined
): IndustryInfoData => {
  const profileScore = getScoreFromCompassAngle(cultureCompassData?.profile);
  const industryScore = getScoreFromCompassAngle(cultureCompassData?.industry);

  return {
    isInline: Math.abs(profileScore - industryScore) <= MAX_IN_LINE_SCORES_DIFF,
    isBalanced: isBalancedScore(industryScore),
    cultureBinary: getCultureBinaryFromScore(industryScore),
    weight: getWeightFromScore(industryScore),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    verb: (Math.floor(Math.random() * 4) + 1) as 1 | 2 | 3 | 4,
  };
};
