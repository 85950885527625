import type { InMemoryCache, TypePolicies } from '@apollo/client';
import { useApolloClient } from '@apollo/client';

const mergeTypes = [
  'CompanyKununuData',
  'CompanyLogos',
  'HtmlDescription',
  'JobCompanyInfo',
  'JobHiringContact',
  'JobLocation',
  'JobUserInteractions',
  'JobXingApplication',
  'JobMatchingHighlightsV2',
];

const mergeTypePolicies = mergeTypes.reduce(
  (acc: TypePolicies, currentType) => {
    acc[currentType] = { merge: true };

    return acc;
  },
  {}
);

export const useVisibleJobTypePolicies = (): void => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (useApolloClient().cache as InMemoryCache).policies.addTypePolicies(
    mergeTypePolicies
  );
};
