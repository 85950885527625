import type React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Card } from '@xing-com/card';
import type { JobSalary } from '@xing-com/crate-common-graphql-types';
import {
  isValidSalary,
  isValidSalaryEstimate,
  isValidSalaryRange,
} from '@xing-com/crate-jobs-helpers';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';

import { SalaryEstimation } from './salary-estimation';
import { SalaryExact } from './salary-exact';
import { SalaryRange } from './salary-range';

const SalaryContainer = styled(Card)`
  width: 100%;

  // remove all card styles on small screen
  @media ${mediaSmallOrTiny} {
    background-color: transparent;
    border: none;
    padding: 0;
  }
`;

type Props = {
  salary?: JobSalary | null;
};

export const SalaryModule: React.FC<Props> = ({ salary }) => {
  const SalaryContent = useMemo(() => {
    if (isValidSalary(salary)) {
      return <SalaryExact salary={salary} />;
    }
    if (isValidSalaryEstimate(salary)) {
      return <SalaryEstimation salary={salary} />;
    }
    if (isValidSalaryRange(salary)) {
      return <SalaryRange salary={salary} />;
    }

    return null;
  }, [salary]);

  return SalaryContent ? (
    <SalaryContainer data-testid="job-details-salary-card">
      {SalaryContent}
    </SalaryContainer>
  ) : null;
};
