import * as React from 'react';

import { List, ListItem } from '@xing-com/list';

import * as Styled from './radio-button-group.styles';
import type { RadioButtonGroupProps } from './radio-button-group.types';

export const RadioButtonGroup = React.forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>(
  (
    { children, className, label, orientation = 'vertical', ...props },
    forwardedRef
  ): JSX.Element => (
    <div ref={forwardedRef}>
      {label && <Styled.Label>{label}</Styled.Label>}
      <List
        className={className}
        data-xds="RadioButtonGroup"
        variant={orientation === 'horizontal' ? 'row' : undefined}
        {...props}
      >
        {React.Children.map(children, (child) => {
          const listItem = <ListItem>{child}</ListItem>;

          return {
            ...listItem,
            props: {
              ...listItem.props,
              children: {
                ...listItem.props.children,
                props: {
                  ...listItem.props.children.props,
                },
              },
            },
          };
        })}
      </List>
    </div>
  )
);

RadioButtonGroup.displayName = 'RadioButtonGroup';
