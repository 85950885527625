import { FormattedMessage } from 'react-intl';

import type { ActionBannerState } from './banner-context';
import * as S from './banner.styles';
import { BANNER_TIMEOUT } from './constants';

type SuccessBannerProps = {
  handleOnClose?: () => void;
  successState: ActionBannerState;
};

export const SuccessBanner: React.FC<SuccessBannerProps> = ({
  handleOnClose,
  successState,
}) => {
  const { message, action, actionMessage, visible } = successState;
  return message ? (
    <S.StatusBannerAutoHide
      display="fixed"
      show={visible}
      variant="success"
      timeout={BANNER_TIMEOUT}
      handleOnClose={handleOnClose}
    >
      <FormattedMessage
        id={message}
        values={{
          b: (chunks) => <strong>{chunks}</strong>,
        }}
        tagName="span"
      />
      {action && actionMessage ? (
        <S.BannerAction onClick={action}>
          <FormattedMessage id={actionMessage} />
        </S.BannerAction>
      ) : null}
    </S.StatusBannerAutoHide>
  ) : null;
};
