import type * as React from 'react';
import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { SkeletonHeadline } from '@xing-com/skeleton';
import { scale170 } from '@xing-com/tokens';

const StyledSkeletonHeadline = styled(SkeletonHeadline)`
  height: ${scale170};
  align-items: center;

  @media ${mediaConfined} {
    display: none;
  }

  // It's necessary to remove the margin bottom of the last
  // skeleton, otherwise display:flex with align-item: center doesn't
  // work properly, because the margin is part of the skeleton.
  > div {
    margin-bottom: 0;
  }
`;

export const TopBarFallback: React.VFC = () => {
  return (
    <StyledSkeletonHeadline
      amountRows={1}
      size="small"
      data-qa="layout-top-bar-fallback"
    />
  );
};
