import styled from 'styled-components';

import { Link } from '@xing-com/link';
import { xdlColorText } from '@xing-com/tokens';

export const StyledLink = styled(Link)`
  color: ${xdlColorText};

  &:hover {
    text-decoration-thickness: from-font;
  }
`;

type Props = React.PropsWithChildren<{
  link?: string;
  trackingParams?: string;
}>;

export const TrackingLink: React.FC<Props> = ({
  children,
  link,
  trackingParams,
}) => {
  const navigateToLink = (event: React.MouseEvent, href: string): void => {
    event.preventDefault();
    window.location.assign(`${href}?${trackingParams}`);
  };

  return link ? (
    <StyledLink
      to={link}
      onClick={(e: React.MouseEvent) => navigateToLink(e, link)}
    >
      {children}
    </StyledLink>
  ) : (
    <>{children}</>
  );
};
