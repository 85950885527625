import { useCallback } from 'react';

import { useLoginAppUrl } from '@xing-com/crate-xinglet';

export const useRedirectToLoginWithJobDestUrl = (): (() => void) => {
  const loginAppUrl = useLoginAppUrl();

  return useCallback(() => {
    window?.location.assign(
      `${loginAppUrl}?dest_url=${encodeURIComponent(window?.location.pathname)}`
    );
  }, [loginAppUrl]);
};
