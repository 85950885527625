import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getSalaryFormatOptions } from '@xing-com/crate-jobs-helpers';
import type { SalaryRangeValid } from '@xing-com/crate-jobs-helpers/src/salary';

import { ModuleTitle } from '../module-title';
import { SalaryForecastBar } from './salary-forecast-bar';
import { BodyCopy } from './salary-module.common.styles';

type Props = {
  salary: SalaryRangeValid;
};

export const SalaryRange: React.FC<Props> = ({ salary }) => {
  const { formatNumber } = useIntl();

  const formatOptions = getSalaryFormatOptions(salary.currency);
  const leftRangeValue = formatNumber(salary.minimum, formatOptions);
  const rightRangeValue = formatNumber(salary.maximum, formatOptions);

  return (
    <>
      <ModuleTitle titleKey="JOBS_JOB_DETAIL_SALARY_RANGE_HEADLINE" noMargin />
      <BodyCopy>
        <FormattedMessage id="JOBS_JOB_DETAIL_SALARY_RANGE_BODY" />
      </BodyCopy>
      <SalaryForecastBar
        isFullRange
        legend={[leftRangeValue, rightRangeValue]}
      />
    </>
  );
};
