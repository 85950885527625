import type { WindowLocation } from '@reach/router';
import { useLocation, useNavigate } from '@reach/router';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { SEARCH_ALERTS_MODAL_TYPES } from '@xing-com/crate-jobs-constants';
import {
  JDP_SA_DEFAULT_QUERY,
  SEARCH_ALERTS_MODAL_PARAM,
} from '@xing-com/crate-jobs-constants';
import type { SearchAlertFilters } from '@xing-com/crate-jobs-helpers';
import type { NavigationState } from '@xing-com/crate-jobs-hooks';

type ModalTypeKey = keyof typeof SEARCH_ALERTS_MODAL_TYPES;
type ModalType = (typeof SEARCH_ALERTS_MODAL_TYPES)[ModalTypeKey];

type Props = {
  type: ModalType;
  params?: SearchAlertFilters;
};
export const useSearchAlertModal = ({
  type,
  params,
}: Props): { openSearchAlertModal: () => void } => {
  const { state, search, pathname } =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    useLocation() as WindowLocation<NavigationState>;
  const navigate = useNavigate();
  const { track } =
    useTracking<InteractionTrackingEvent<{ PropTrackAction: string }>>();

  const openSearchAlertModal = (): void => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(SEARCH_ALERTS_MODAL_PARAM, type);
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_search_alert_create_open',
    });
    navigate(`${pathname}?${searchParams.toString().replaceAll('+', '%20')}`, {
      state: { ...state, [JDP_SA_DEFAULT_QUERY]: params },
      replace: true,
    });
  };
  return { openSearchAlertModal };
};
