import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SingleSelect } from '@xing-com/menu';

type Props = {
  localeKey: string;
  withStrongChunks?: boolean;
  'data-testid'?: string;
  icon?: React.ElementType;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

const StyledSingleSelect = styled(SingleSelect)`
  text-align: left;
`;

export const MenuItem: React.FC<Props> = ({
  icon,
  localeKey,
  withStrongChunks,
  'data-testid': dataTestId,
  onClick,
  className,
}) => (
  // @ts-expect-error FIXME: SC6
  <StyledSingleSelect
    icon={icon}
    onClick={onClick}
    data-testid={dataTestId}
    className={className}
  >
    <FormattedMessage
      id={localeKey}
      values={
        withStrongChunks
          ? { b: (chunks) => <strong>{chunks}</strong> }
          : undefined
      }
      tagName="span"
    />
  </StyledSingleSelect>
);
