import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import type { CompanyKununuCultureDimension } from '@xing-com/crate-common-graphql-types';
import { BodyCopy } from '@xing-com/typography';

import {
  getIndustryInfoData,
  getProfileInfoData,
  hasCultureInfo,
} from './culture-info-helpers';

export const InfoWrapper = styled.div`
  flex-grow: 1;
`;

type Props = {
  companyName: string;
  compass?: CompanyKununuCultureDimension | null;
  ratingCount?: number;
};

export const CultureInfo: React.FC<Props> = ({
  companyName,
  compass,
  ratingCount,
}) => {
  const { formatMessage } = useIntl();
  const compassContent = useMemo(() => {
    const introContent = formatMessage(
      { id: 'JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INTRO' },
      { totalCultureReviews: ratingCount, companyName }
    );
    const getProfileContent = function (): string {
      const profileInfoData = getProfileInfoData(compass?.profile);

      const { isBalanced, cultureBinary, weight } = profileInfoData;

      return isBalanced
        ? formatMessage({
            id: 'JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_BALANCED',
          })
        : formatMessage(
            {
              id: 'JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_BINARY',
            },
            {
              profileWeight: weight
                ? formatMessage({
                    id: `JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_WEIGHT_${weight}`,
                  })
                : '',
              cultureBinaryProfile: formatMessage({
                id: `JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_VALUE_${cultureBinary}`,
              }),
            }
          );
    };
    const getIndustryContent = function (): string {
      const industryInfoData = getIndustryInfoData(compass);

      const { isInline, isBalanced, cultureBinary, weight, verb } =
        industryInfoData;

      return isInline
        ? formatMessage({
            id: 'JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_IN_LINE',
          })
        : isBalanced
          ? formatMessage({
              id: 'JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_BALANCED',
            })
          : formatMessage(
              {
                id: 'JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_NOT_BALANCED',
              },
              {
                cultureBinaryIndustry: formatMessage({
                  id: `JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_VALUE_${cultureBinary}`,
                }),
                industryWeight: weight
                  ? formatMessage({
                      id: `JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_WEIGHT_${weight}`,
                    })
                  : '',
                verb: formatMessage({
                  id: `JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_VERB_${verb}`,
                }),
              }
            );
    };

    return `${introContent}${getProfileContent()}${getIndustryContent()}`;
  }, [companyName, compass, formatMessage, ratingCount]);

  if (!hasCultureInfo(compass, ratingCount)) {
    return null;
  }

  return (
    <InfoWrapper>
      <BodyCopy>{compassContent}</BodyCopy>
    </InfoWrapper>
  );
};
