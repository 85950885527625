/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTiktok"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.288 6.393a3.813 3.813 0 0 1-3.808-3.807.586.586 0 0 0-.587-.586h-3.14a.586.586 0 0 0-.586.586v12.867a2.24 2.24 0 0 1-2.24 2.237 2.24 2.24 0 0 1-2.24-2.238 2.24 2.24 0 0 1 2.24-2.239.586.586 0 0 0 .586-.585V9.489a.586.586 0 0 0-.586-.586c-3.613 0-6.552 2.939-6.552 6.549S6.315 22 9.927 22c3.613 0 6.552-2.937 6.552-6.548V9.757c1.173.624 2.481.95 3.81.946a.587.587 0 0 0 .586-.586V6.979a.586.586 0 0 0-.587-.586"
    />
  </svg>
);
export default IconTiktok;
